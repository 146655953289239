import React, { useState, useEffect,useContext } from "react";
import { fetchData } from "../../Api/Message";
import "./Chat.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { calculateTimeDifferenceChat,formatDate } from "../../utils/index";
import ChatWindow from "./ChatWindow";
import ChatStatic from "./ChatStatic";
import UserContext from '../AuthContext/ChatContext';
import Tippy from "@tippyjs/react";

const Chat = () => {
  const { setSelectedUserId1 } = useContext(UserContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedUserIdMatch = queryParams.get('user_id');
  const conversations = useSelector((state) => state.reducer.conversations);


  const selectedConversation = useSelector((state) => state.reducer.selectedConversation);
  const selectedUserId=useSelector((state)=>state.reducer.selectedUserId);
  const selectedUserType=useSelector((state)=>state.reducer.selectedUserType)
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const filteredConversations = conversations?.filter((conversation) => {
    // console.log("Mapping conversation:", conversation);
    // Filter based on user's first name, last name, or group name matching the search term
    const otherUserName = conversation?.other_user
      ? `${conversation.other_user.first_name ?? ""} ${
          conversation.other_user.last_name ?? ""
        }`
      : "";
    const groupName = conversation.group?.name ?? "";
    const fullNameAndGroupName = `${otherUserName} ${groupName}`.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return fullNameAndGroupName.includes(searchTermLower);
  });
 
  const handleResetSelectedConversation = (item) => {
    console.log("onclick",item);
    setSelectedUserId1(null)
    if (item) {
      
      // Reset the selected conversation directly without using a separate action
      dispatch({ type: 'RESET_SHOW_CONVERSATIONS', payload: [] });
      dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: item });
      dispatch({type:'SET_SELECTED_USERID', payload:item.user_id});
      dispatch({type:'SET_SELECTED_USERTYPE', payload:item.type_of});
      dispatch({type:'SET_PAGENO', payload:1});

    }  };

  // useEffect(() => {
  //   // Fetch data
  //   fetchData()
  //     .then((data) => {
  //       // console.log("After API Call - Conversations:", data.data.conversations);
  //       // Dispatch action to update conversations in the state
  //       dispatch({ type: 'SET_CONVERSATIONS', payload: data?.data?.conversations});
  
  //       // Select the first conversation
  //       if (data?.data.conversations?.length > 0) {
  //         dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: data?.data.conversations[0] });
  //         dispatch({type:'SET_SELECTED_USERID', payload:data?.data.conversations[0].user_id});
  //         dispatch({type:'SET_SELECTED_USERTYPE', payload:data?.data.conversations[0].type_of});
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // }, [dispatch,selectedUserIdMatch,selectedUserId]);
  const fetchDataAndUpdateState = () => {
    setLoading(true); // Set loading to true initially
    fetchData()
      .then((data) => {
        dispatch({ type: 'SET_CONVERSATIONS', payload: data?.data?.conversations });
        if (data?.data.conversations?.length > 0) {
          dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: data?.data.conversations[0] });
          dispatch({ type: 'SET_SELECTED_USERID', payload: data?.data.conversations[0].user_id });
          dispatch({ type: 'SET_SELECTED_USERTYPE', payload: data?.data.conversations[0].type_of });
        }
        setLoading(false); // Set loading to false after data fetching is complete
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false); // Also, handle loading state in case of error
      });
  };
  
  
  useEffect(() => {
    fetchDataAndUpdateState();
      }, [dispatch, selectedUserIdMatch]);
  return (
    <div className="container pt-2">
      <div className="row">
      <div className="col-lg-4 col-md-12 col-sm-12 ps-0 mobile-pl">
        <div className="row">
          <div className="col-lg-11 col-md-11 col-sm-11 col-10 ps-3">
          <div className="input-group mb-1 mt-1  ">
        <input
          type="text"
          className="form-control ps-5  rectanglesearch   cursor-point"
          placeholder="Search"
          name=""
          id="searchTodo"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoComplete="off"
        />
        <img src="https://beta.yesdone.com/build/assets/searchicon-534fbd0c.png" 
        alt=""
         className=" icon-search"
         height="15px" 
         width="15px"></img>
        {/* <div className="input-group-append searchtodo ">
          <span className="input-group-text greysearchimg   cursor-point">
            <img
              src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
              width="23px"
              alt=""
            />
          </span>
        </div> */}
      </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 col-2 mx-auto searchpic d-flex justify-content-center align-items-center ">
            <Link to="/chat-user">
          <img
              src="	https://beta.yesdone.com/build/assets/whiteplus-5b88000b.png"
              alt=""
              className=""
              height="18px"
              width="18px"
                />
                </Link>
          </div>
        </div>
    <div  className="scrollable-list">
      {filteredConversations && filteredConversations.map((conversation,index) => (
        <div key={3+index}
        onClick={() => handleResetSelectedConversation(conversation)}
       >
     {loading ? (
                  <div className="d-flex align-items-center justify-content-center my-2">
                    {/* <img src={LoadingImg} alt="" width="50px" /> */}
                    <ChatStatic />
                  </div>
                ) : (
                  <>
                    <div className="row py-2 bottom-border cursor-pointer">
            <div className="col-lg-2 col-md-2 col-sm-2 col-2 text-center ps-3">
              {conversation.type_of === "single" ? (
                <>
                  {/* Conditional rendering for profile image or first letter */}
                  {conversation.other_user &&
                  conversation.other_user.profile_image ? (
                    <img
                      src={conversation.other_user.profile_image}
                      className="rounded-circle image-profile" alt="Profile"
                      width="50px"
                      height="50px"
                    />
                  ) : (
                    <div className="profile mx-auto ">
                      {conversation.other_user &&
                      conversation.other_user.first_name
                        ? conversation.other_user.first_name
                            .charAt(0)
                            .toUpperCase()
                        : "N"}
                    </div>
                  )}
                </>
              ) : (
                // Render static icon for group
                <i class="fa fa-users fs-4 rounded-icon"></i>
              )}
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-8 chattext ">
            
                {conversation.type_of !== "group" && (
                  <div>
                    {conversation.other_user?.first_name ?? "null"}{" "}
                    {conversation.other_user?.last_name ?? "null"}
                   
                 </div>
                 )}
                 <span className=""> {conversation.group?.name} </span>
             
              {/* <div className="">
                {conversation.type_of !== "group" && (
                  <span className="text-muted">
                    @{conversation.other_user?.first_name ?? "null"}
                  </span>
                )}
              </div> */}
               <div className="textimg ">
          {/* Condition 1: If media is empty, render only the content */}
          {!conversation.last_message?.media ||
          conversation.last_message.media.length === 0 ? (
            <span className="your-paragraph">{conversation.last_message?.content}</span>
          ) : (
            <div>
              {conversation.last_message.media.some(
                (m) => m.type === "video"
              ) ? (
                <>
                  <i className="bi bi-play-btn-fill"></i> {/* Video icon */}
                  <span>Video</span>
                </>
              ) : conversation.last_message.media.some(
                  (m) => m.type === "audio"
                ) ? (
                <>
                  <i className="bi bi-mic-fill"></i> {/* Audio icon */}
                  <span>Audio</span>
                </>
              ) : (
                <>
                  <i className="bi bi-card-image"></i> {/* Image icon */}
                  <span>Image</span>
                </>
              )}
              {/* Don't display content here */}
            </div>
          )}
          </div>
            </div>
             {/* <div className="col-2">
              {conversation.unread_count > 0 && (
                <span>{conversation.unread_count}</span>
              )}
            </div>  */}
            <div className="col-lg-2 col-sm-2 col-2 ps-0 pt-1 flex-column  pe-3 d-flex  align-items-center chatday">
              {formatDate(conversation.last_message_time)}
              {conversation.unread_count > 0 && (
                <Tippy content="unreadcount">
                <span className="unread-count mt-1 d-flex justify-content-center align-items-center">{conversation.unread_count}</span>
                </Tippy>
              )}
            </div>
          {/* </div> */}
         
          </div>
                  </>
                )}
          
        </div>
      ))}
      </div>
    </div>
    <div className="col-lg-8 col-md-12 col-sm-12">
  <ChatWindow fetchDataAndUpdateState={fetchDataAndUpdateState}/>
    </div>
      </div>

    
    </div>
    
  );
};

export default Chat;
