import React from "react";
import Login from "../login/Login";
import { useAuth } from "../AuthContext/AuthContext";
import Post from "../post/Post";
const Product = () => {
  const { isLoggedIn } = useAuth();
  return (
    <div>
       {!isLoggedIn ? (
      <>
        <div className="row">
          <div className=" col-md-2 login-column"></div>
          <div className="col-12 col-md-6 above-center ">
            <Login />
          </div>
          <div className=" col-md-4"></div>
        </div>
      </>
    ) : <Post />}
    </div>
  );
};

export default Product;
