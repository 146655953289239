// import React, { useState } from "react";

// export default ({ file, onDelete }) => {
//     const [selectedImage, setSelectedImage] = useState(null);

//     const reader = new FileReader();
//     reader.onloadend = () => {
//         setSelectedImage(reader.result);
//     };
//     reader.readAsDataURL(file);

//     return (
//         <div
//             style={{
//                 position: 'relative',
//                 width: '80px',
//                 height: '80px',

//             }}
//         >
           
//             <img src={selectedImage} alt="Preview" style={{ width: '100%', height: '80%', objectFit: 'cover', paddingTop:"4px" }} />
//             <i className="fa fa-times text-dark small" aria-hidden="true"
//             onClick={onDelete} src="https://e7.pngegg.com/pngimages/211/405/png-clipart-computer-icons-close-button-trademark-logo.png"  style={{
//                 position: 'absolute',
//                 top: '5px',
//                 right: '5px',
//                 color: 'white',
//                 border: 'none',
//                 backgroundColor: 'white',
//                 cursor: 'pointer',
//             }}
            
//             ></i>
//         </div>
//     );
// };
import React, { useState, useEffect } from "react";

const ImagePreview = ({ file, onDelete }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (file instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (typeof file === "string") { // Check if file is a string (URL)
      setSelectedImage(file);
    } else {
      setSelectedImage(null);
    }
  }, [file]);

  if (!selectedImage) {
    return null; // Return null if selectedImage is not available
  }

  return (
    <div
      style={{
        position: "relative",
        width: "80px",
        height: "80px",
      }}
    >
      <img
        src={selectedImage}
        alt="Preview"
        style={{ width: "100%", height: "80%", objectFit: "cover", paddingTop: "4px" }}
      />
      <i
        className="fa fa-times text-dark small"
        aria-hidden="true"
        onClick={onDelete}
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          cursor: "pointer",
          padding: "5px",
        }}
      ></i>
    </div>
  );
};

export default ImagePreview;