import API_BASE_URL from '../Config';

export const fetchUserData = async () => {
  try {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in local storage');
      return null;
    }

    const response = await fetch(`${API_BASE_URL}/api/v1/user`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data.data);
      localStorage.setItem('user_id', data.data.id);
     localStorage.setItem("first_name",data.data.first_name);
      return data; 
    } else {
      console.error('Failed to fetch user data:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};
