import React from 'react';
import '../todos/Todo.css';

const ChatStatic = () => {
  // const repeat = 3; // Define the number of repetitions here
  // const repeatedComponents = [];

  // for (let i = 0; i < repeat; i++) {
  //   repeatedComponents.push(
  //     <div key={i} className=' my-3'>
  //       <div className='row'>
  //         <div className='chatp-static'></div>
  //         <div className='chatt-static ms-2 my-auto'></div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    // <>
    //   {repeatedComponents}
    // </>
    <div className='container my-3'>
       <div className='row'>
       <div className='chatp-static'></div>
       <div className='chatt-static ms-2 my-auto'></div>
      </div>
    </div>
  );
};

export default ChatStatic;