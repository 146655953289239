import React, { useEffect, useState,useRef } from "react";
import { useMiniTodoData } from "../AuthContext/minitodos";
import API_BASE_URL from "../../Config";
import { calculateTimeDifference } from "../../utils/index";
import { Link, useParams, useSearchParams } from "react-router-dom";
import "./miniDone.css";
import { fetchPostData } from "../../Api/MiniTodo";
import Popup from "../../popup/Popup";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingImg from "../../images/loading.webp";
import { Modal, Button } from "react-bootstrap";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import ToastContainer from '../toast/ToastContainer';
const MiniDone = ({ fetchData,toastRef,Post_id }) => {
  const {count,setCount, miniTodoData, setMiniTodoData } = useMiniTodoData();
  const [pageNo, setPageNo] = useState(2);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasMore, setHasMore] = useState(true);
  const [showNotDoneModal, setShowNotDoneModal] = useState(false);
  const [notDoneId, setNotDoneId] = useState(null);
  const [bookMarkModal, setBookMarkModal] = useState(false);
  const [bookMarkModalId, setBookMarkModalId] = useState(null);
  const todos = miniTodoData.todos;
  const [loading, setLoading] = useState(false); 
  const [showDoneModalId, setShowDoneModalId] = useState(null);// State to track if data is currently being loaded
  // const toastRef = useRef();
  console.log("toastRef",toastRef);
  const handleModalBookmark = (id) => {
    setBookMarkModal(true);
    setBookMarkModalId(id);
  };
  const handleCloseBookMark = () => {
    setBookMarkModal(false);
    setBookMarkModalId(null);
  };

  const handleClickNotDone = (id) => {
    setNotDoneId(id);
    setShowNotDoneModal(true);
  };
  const handleCloseNotDoneModal = () => {
    setShowNotDoneModal(false);
    setNotDoneId(null);
  };
  console.log("miniDoneData", miniTodoData);
  const activeTab = searchParams.get("status");

  const { id } = useParams();
  console.log("miniDonecomponent", id, activeTab);
  //   const fetchRecords = async () => {
  //     try {
  //       const resp = await fetchPostData(
  //         id,activeTab,pageNo

  //       );
  // console.log("response",resp);
  //       // const updatedPosts = resp?.data.posts?.map((post) => ({
  //       //   ...post,
  //       //   created_at: formatRelativeTime(new Date(post.created_at)),
  //       // }));
  //       // setTodoData({
  //       //   todoCount: resp?.data.still_working?.count || 0,
  //       //   approvedCount: resp?.data.approved?.count || 0,
  //       //   deleteCount: resp?.data.deleted?.count || 0,
  //       //   posts: [...posts, ...updatedPosts]
  //       // });
  //       // setHasMore(resp?.data?.pagination?.current_page <= resp?.data?.pagination?.last_page);
  //       setMiniTodoData()
  //       setPageNo(resp?.data?.pagination?.current_page + 1);
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }

  const fetchRecords = async () => {
    try {
      setLoading(true);
      const newData = await fetchPostData(id, activeTab, pageNo);
      console.log("peginationInDone", newData.data.pagination);
      setMiniTodoData((prevData) => ({
        todos: [...prevData.todos, ...newData.data.todos],
      }));
      setHasMore(
        newData?.data?.pagination?.current_page <=
          newData?.data?.pagination?.last_page
      );
      setPageNo(newData?.data?.pagination?.current_page + 1);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // useEffect(()=>{
  // fetchRecords()
  // },[])

  console.log("mini todo", todos);

  const handleNotDoneClick = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/comments/${notDoneId}`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const postData = {
          status: "still_working",
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });
        console.log("doneresponse",response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setShowNotDoneModal(false);
        setNotDoneId(null);
        setShowDoneModalId(null)
        console.log("restored successfully");
        toastRef.current.showToast("Comment Status Updated Successfully")
        console.log("current toast",toastRef.current);
        setMiniTodoData(prevData => {
          const idToFilter = notDoneId;
        
         
          const filteredTodos = prevData.todos.filter(todo => todo.id !== idToFilter);
        
          return {
            todos: filteredTodos
          };
        });
        setCount(prevTodoData => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount + 1,
          doneCount: prevTodoData.doneCount -1,
          
        }))
        
      }
    } catch (error) {
      console.error("Error updating todo:", error);
    }
  };
  const handleBookmark = async (bookmark) => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await fetch(
        `${API_BASE_URL}/api/v1/todos/${bookMarkModalId}/bookmarks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify({
            isBookMarked: !bookmark,
          }),
        }
      );

      if (response.ok) {
        // Update the local state immediately after the API call
        setMiniTodoData((prevData) => {
          const updatedTodos = prevData.todos.map((todo) =>
            todo.id === bookMarkModalId
              ? { ...todo, is_bookmarked: !bookmark }
              : todo
          );
          return { ...prevData, todos: updatedTodos };
        });

        console.log("Bookmark success");
        fetchData();
      }
    } catch (error) {
      console.error("Error while updating bookmark status:", error);
      // Handle error if needed
    }
  };
  return (
    <>
     <ToastContainer ref={toastRef} timeout={4000}/>
      <InfiniteScroll
        dataLength={todos.length}
        next={() => fetchRecords()}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        // Necessary to prevent issues with child elements positioning
      >
        
        <div className="scrollableDone" id="scrollableDiv">
          {todos.map((todo, index) => (
            <>
             <React.Fragment key={todo.id + todo.name || index}>
              {todo.mini_todos.length > 0 ? (
                <>
                <div className="container">
                  <div className="row pt-1 d-flex bottom-border update-position1">
                    <div className="col-sm-1 col-2 col-md-1 d-flex justify-content-between">
                      <span className="text-black posttime pe-2 ">
                        {index + 1}.
                      </span>
                      <span className=" ">
                        <Tippy content="checkbox">
                        <input
                          className="form-check-input custm-checkbox mini-check cursor-point  "
                          type="checkbox"
                          name="checkboxes"
                          // checked={true}
                          checked={true}
                          // onChange={() => handleNotDoneClick(todo.id)}
                          onChange={() => handleClickNotDone(todo.id)} // Set to true for initial check
                        />
                        </Tippy>
                      </span>
                     </div>
                      <div className="col-sm-9 col-8 col-md-9 padding-done ps-2">
                        {todo.type_of === "task" || todo.type_of === "text" ? (
                          <>
                          {/* <div className="comment-position"> */}
                            <span className="text-dark fw-medium complete posttime">
                            <img className="hidden me-2 img-visible" src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png" alt="Hidden Images"  width="25px" height="19px"/>
                              {todo.comment}
                            </span>
                            <p className="post-time mb-0 text-black ps-2">
                              {calculateTimeDifference(todo.updated_at)}
                            </p>
                            {/* </div> */}
                          </>
                        ) : todo.type_of === "task_image" ||
                          todo.type_of === "task_video" ||
                          todo.type_of === "task_youtube" ? (
                          <>
                            {(() => {
                              const parts = todo.comment.split("|&|");
                              const isVideo = todo.type_of === "task_video";
                              const [mediaUrl, text] =
                                parts.length === 2
                                  ? parts
                                  : [todo.comment, todo.comment];

                              // Your code specific to "task_image", "task_video", or "task_youtube"
                              return (
                                <>
                                  <span className="d-flex align-items-center text-dar fw-medium ">
                                    {/* <img
                             data-bs-toggle="modal"
                             data-bs-target={`#exampleModal1${todo.id}`}
                              src={`https://yesdone.com/build/assets/${
                                isVideo
                                  ? "youtube1-bc2b8a44.png"
                                  : "dummyimg-68fadc9e.png"
                              }`}
                              alt="Comment Media"
                              width="25px"
                            /> */}

                                    <Popup url={mediaUrl} isVideo={isVideo} text={text} />
                                    <span className="posttime  complete mb-0 ps-1 text-black">
                                      {text}
                                    </span>
                                  </span>
                                  <p className="post-time mb-0 update-position text-black ps-2">
                                    {calculateTimeDifference(todo.updated_at)}
                                  </p>
                                  {/* <div
                            className="modal fade"
                            id={`exampleModal1${todo.id}`}
                            tabIndex="-1"
                            aria-labelledby={`exampleModalLabel1${todo.id}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                               
                                <h5 className="modal-title ps-5 fw-bold text-black">
                                  {text}
                                </h5>
                                <div className="modal-body">
                                  {isVideo ? (
                                    // If it's a video, show an embedded video player
                                    <video controls width="100%" height="auto">
                                      <source src={mediaUrl} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    // If it's an image, show the image
                                    <img
                                      src={mediaUrl}
                                      alt=""
                                      width="100%"
                                      height="auto"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                                </>
                              );
                            })()}
                          </>
                        ) : null}
                      </div>
                    {/* </div> */}

                    <div className="col-sm-2 col-2 colmd-2 padding-done text-center ">
                      <Link to={`/posts/${Post_id}/${todo.id}/todo-comments`}>
                        <span className="pe-3">
                          <Tippy content="minicomments">
                          {todo.replies_count ? (
                            <img
                              src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                              alt="Green Message"
                              width="13px"
                              height="13px"
                              className="cursor-point"
                            />
                          ) : (
                            <img
                              src="https://beta.yesdone.com/build/assets/greymessage-7c7383fd.png"
                              alt="emptypen"
                              width="13px"
                              height="13px"
                              className=""
                            />
                          )}
                          </Tippy>
                        </span>
                      </Link>
                      <span
                        className="ps-2"
                        onClick={() =>
                          handleModalBookmark(todo.id, todo.is_bookmarked)
                        }
                      >
                        <Tippy  content={todo.is_bookmarked ? "Bookmark Checked" : "Bookmark"}>
                        {todo.is_bookmarked ? (
                          <img
                            src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                            alt="emptypen"
                            width="11px"
                            height="14px"
                            className=""
                          />
                        ) : (
                          <img
                            src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                            alt="emptypen"
                            width="11px"
                            height="14px"
                            className=""
                          />
                        )}
                        </Tippy>
                      </span>
                    </div>
                  </div>
                  </div>
                </>
              ) : (
                <>
                <div className="container">
                  <div className="row pt-1 d-flex bottom-border update-position1 ">
                    <div className="d-flex justify-content-between col-sm-1 col-2 col-md-1 padding-d">
                      <span className="text-black pe-1 posttime">
                        {index + 1}.
                      </span>
                      <span className="ps-1">
                        <Tippy content="checkbox">
                        <input
                          className="form-check-input mini-check  custm-checkbox cursor-point "
                          type="checkbox"
                          name="checkboxes"
                          // checked={true}
                          checked={true}
                          // onChange={() => handleNotDoneClick(todo.id)}
                          onChange={() => handleClickNotDone(todo.id)} // Set to true for initial check
                        />
                        </Tippy>
                      </span>
                      </div>
                      <div className="col-sm-9 col-8 col-md-9 padding-done ps-2">
                        {todo.type_of === "task" || todo.type_of === "text" ? (
                          <>
                          <div className="comment-position">
                            <span className="text-dark text-break d-flex fw-medium complete posttime">
                            <img className="hidden me-2 img-visible" src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png" alt="Hidden Images"  width="25px" height="19px"/>
                              {todo.comment}
                            </span>
                            <p className="post-time mb-0 text-black  ps-2">
                              {calculateTimeDifference(todo.updated_at)}
                            </p>
                            </div>
                          </>
                        ) : todo.type_of === "task_image" ||
                          todo.type_of === "task_video" ||
                          todo.type_of === "task_youtube" ? (
                          <>
                            {(() => {
                              const parts = todo.comment.split("|&|");
                              const isVideo = todo.type_of === "task_video";
                              const [mediaUrl, text] =
                                parts.length === 2
                                  ? parts
                                  : [todo.comment, todo.comment];

                              // Your code specific to "task_image", "task_video", or "task_youtube"
                              return (
                                <>
                                  <span className="d-flex align-items-center text-dar fw-medium ">
                                    {/* <img
                              data-bs-toggle="modal"
                              data-bs-target={`#exampleModal1${todo.id}`}
                              src={`https://yesdone.com/build/assets/${
                                isVideo
                                  ? "youtube1-bc2b8a44.png"
                                  : "dummyimg-68fadc9e.png"
                              }`}
                              alt="Comment Media"
                              width="25px"
                            /> */}
                                    <Popup url={mediaUrl} isVideo={isVideo} text={text} />
                                    <span className="posttime mb-0 ps-1 complete text-black">
                                      {text}
                                    </span>
                                  </span>
                                  <p className="post-time update-position mb-0 text-black ps-2">
                                    {calculateTimeDifference(todo.updated_at)}
                                  </p>

                                  {/* <div
                            className="modal fade"
                            id={`exampleModal1${todo.id}`}
                            tabIndex="-1"
                            aria-labelledby={`exampleModalLabel1${todo.id}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <h5 className="modal-title ps-5 fw-bold text-black">
                                  {text}
                                </h5>
                                <div className="modal-body">
                                  {isVideo ? (
                                    // If it's a video, show an embedded video player
                                    <video controls width="100%" height="auto">
                                      <source src={mediaUrl} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    // If it's an image, show the image
                                    <img
                                      src={mediaUrl}
                                      alt=""
                                      width="100%"
                                      height="auto"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                                </>
                              );
                            })()}
                          </>
                        ) : null}
                      </div>
                    {/* </div> */}
                    <div className="col-sm-2 col-2 col-md-2 padding-done  text-center">
                      <Link to={`/posts/${Post_id}/${todo.id}/todo-comments`}>
                        <span className="pe-3">
                          <Tippy content="minicomments">
                          {todo.replies_count ? (
                            <img
                              src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                              alt="Green Message"
                              width="13px"
                              height="13px"
                              className="cursor-point"
                            />
                          ) : (
                            <img
                              src="https://beta.yesdone.com/build/assets/greymessage-7c7383fd.png"
                              alt="emptypen"
                              width="13px"
                              height="13px"
                              className=""
                            />
                          )}
                          </Tippy>
                        </span>
                      </Link>
                      <span
                        className="ps-2"
                        onClick={() =>
                          handleModalBookmark(todo.id, todo.is_bookmarked)
                        }
                      >
                        <Tippy  content={todo.is_bookmarked ? "Bookmark Checked" : "Bookmark"}>
                        {todo.is_bookmarked ? (
                          <img
                            src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                            alt="emptypen"
                            width="11px"
                            height="14px"
                            className=""
                          />
                        ) : (
                          <img
                            src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                            alt="emptypen"
                            width="11px"
                            height="14px"
                            className=""
                          />
                        )}
                        </Tippy>
                      </span>
                    </div>
                  </div>
                  </div>
                </>
              )}
            </React.Fragment>
         

            </>
           
            
          ))}
             
          {loading ? (
            <p className="text-center text-muted">
              <img src={LoadingImg} width="35px" height="35px" />
            </p>
          ) : null}
        </div>
      </InfiniteScroll>
      <Modal
        show={bookMarkModal}
        onHide={handleCloseBookMark}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center  d-flex align-items-center modal-icon text-danger"
          ></i>
        </Modal.Header>
        <Modal.Body className="">
        Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleBookmark}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseBookMark}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNotDoneModal}
        onHide={handleCloseNotDoneModal}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center  d-flex align-items-center text-danger modal-icon"
          ></i>
        </Modal.Header>
        <Modal.Body className="">
        Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleNotDoneClick}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseNotDoneModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MiniDone;