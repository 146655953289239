// api.js
import axios from 'axios';
import API_BASE_URL from '../Config';

export const fetchFilterData = async (setFilterData, setLoading) => {
  try {
    const storedToken = localStorage.getItem('token');

    if (!storedToken) {
      // setError('Authorization token is missing');
      setLoading(false);
      return null; // Return null if token is missing
    }

    const response = await axios.get(`${API_BASE_URL}/api/v1/filters/list?type=doneapp_user`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });

    if (response.data && response.data.status === 200) {
      const filterData = response.data.data;

      // Filter out entries with status 'inactive'
      const activeFilterData = filterData.filter(item => item.status !== 'inactive');

      setFilterData(activeFilterData);

      // Store filtered data in local storage
      localStorage.setItem('filterData', JSON.stringify(activeFilterData));
      
      console.log("users-list", activeFilterData);
      
      return activeFilterData; // Return fetched filter data
    } else {
      // setError('Failed to fetch filter data');
      return null; // Return null if API response is not as expected
    }
  } catch (error) {
    // setError('Error fetching filter data');
    return null; // Return null in case of error
  } finally {
    setLoading(false);
  }
};
