// TodoApi.js
import API_BASE_URL from '../Config';

// export const fetchTodoRecords = async (selectedItemId, activeTab, pageNo, selectedItemType) => {
//   try {
//     const storedToken = localStorage.getItem("token");

//     if (selectedItemId && storedToken) {
//       let apiUrl = `${API_BASE_URL}/api/v1/posts?status=${activeTab}&per_page=20&page=${pageNo}`;

//       if (selectedItemType === "user") {
//         apiUrl = `${apiUrl}&user_id=${selectedItemId}&type=to-do`;
//       } else if (selectedItemType === "group") {
//         apiUrl = `${apiUrl}&group_id=${selectedItemId}&type=to-do`;
//       }

//       console.log(apiUrl);

//       const headers = {
//         Authorization: `Bearer ${storedToken}`,
//       };

//       const response = await fetch(apiUrl, { headers });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const resp = await response.json();
//       console.log("todorecord",resp);
//       return resp;
//     }
//   } catch (error) {
//     console.error("Error:", error);
//     throw error;
//   }
// };
// export const fetchTodoRecords = async (selectedItemId, activeTab, pageNo, selectedItemType, sortBy, sortOrder,signal) => {
//   try {
//     console.log("signal",signal);
//     const storedToken = localStorage.getItem("token");

//     if (!selectedItemId || !storedToken) {
//       throw new Error("Missing required parameters");
//     }

//     let apiUrl = `${API_BASE_URL}/api/v1/posts?status=${activeTab}&per_page=20&page=${pageNo}`;

//     if (selectedItemType === "user") {
//       apiUrl += `&user_id=${selectedItemId}&type=to-do`;
//     } else if (selectedItemType === "group") {
//       apiUrl += `&group_id=${selectedItemId}&type=to-do`;
//     }

//     const sortParam = sortBy ? `&sort_by[${sortBy}]=${sortOrder === 'desc' ? 'desc' : 'asc'}` : '';
//     apiUrl += sortParam;
    
//     const headers = {
//       Authorization: `Bearer ${storedToken}`,
//       // signal: signal
//     };

//     console.log("API URL:", apiUrl);

//     const response = await fetch(apiUrl, { headers,signal });

//     if (!response.ok) {
//       const errorMessage = await response.text(); // Get detailed error message from response
//       throw new Error(`Network response was not ok: ${errorMessage}`);
//     }

//     return await response.json();
//   } catch (error) {
//     console.error("Error:", error);
//     throw error;
//   }
// };

export const fetchTodoRecords = async (
  selectedItemId,
  activeTab,
  pageNo,
  selectedItemType,
  sortBy = 'commission_due_date', // Default to sorting by commission_due_date
  sortOrder = 'desc', // Default to descending order
  signal
) => {
  try {
    console.log("Function called with:", {
      selectedItemId,
      activeTab,
      pageNo,
      selectedItemType,
      sortBy,
      sortOrder,
    });

    const storedToken = localStorage.getItem("token");

    if (!selectedItemId || !storedToken) {
      throw new Error("Missing required parameters");
    }

    let apiUrl = `${API_BASE_URL}/api/v1/posts?status=${activeTab}&per_page=20&page=${pageNo}`;

    if (selectedItemType === "user") {
      apiUrl += `&user_id=${selectedItemId}&type=to-do`;
    } else if (selectedItemType === "group") {
      apiUrl += `&group_id=${selectedItemId}&type=to-do`;
    }

    // Force sort by commission_due_date if not explicitly provided
    const sortParam = `&sort_by[${sortBy || 'commission_due_date'}]=${sortOrder}`;
    apiUrl += sortParam;

    const headers = {
      Authorization: `Bearer ${storedToken}`,
    };

    console.log("API URL:", apiUrl); // Log the API URL to verify the sort parameter

    const response = await fetch(apiUrl, { headers, signal });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Network response was not ok: ${errorMessage}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};







export const formatRelativeTime = (date) => {
  const now = new Date();
  const diffInMinutes = Math.floor((now - date) / (1000 * 60));
  if (diffInMinutes < 60) {
    return "Just Now";
  } else if (diffInMinutes < 1440) {
    const hours = Math.floor(diffInMinutes / 60);
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInMinutes / 1440);
    return days === 1 ? "1 day ago" : `${days} days ago`;
  }
};
