// import React, { useEffect, useState } from "react";
// import Tippy from "@tippyjs/react";
// import { useParams } from "react-router-dom";
// import { fetchPostData } from "../../Api/MiniTodo";
// import { Tabs, Tab } from "react-bootstrap";
// import LoadingImg from "../../images/loading.webp";
// import { useNavigate, useSearchParams } from "react-router-dom"; // Import the fetchPostData function
// import { genNewSearchParamString } from "../../utils";
// import API_BASE_URL from "../../Config";
// import { useMiniTodoData } from "../AuthContext/minitodos";
// import DateAndSort from "../datesort/DateAndSort";
// import "./minipost.css";
// import MiniDelete from "./MiniDelete";
// import MiniDone from "./MiniDone";
// import MiniTodo from "./MiniTodo";
// import ImagePreview from "../../utils/image-preview";
// import { compressImage, videoThumbnail, uploadFileToS3, getTodoReqParams } from '../../utils/index';
// import { useTodo } from "../AuthContext/TodoContext";
// import { Link } from "react-router-dom";
// const MiniPost = () => {
//   // Extract id and status from URL params
//   const { setMiniTodoData } = useMiniTodoData();
//   const [todos, setTodos] = useState([]);
//   const [file, setFile] = useState(null);
//   const [thumbnail, setThumbnail] = useState(null);
//   const { id, postid } = useParams();
//   const [data, setData] = useState({
//     post: { notes: "" }, // Default value for post
//     pending_count: 0, // Default value for pending_count
//     done_count: 0, // Default value for done_count
//     deleted_count: 0, // Default value for deleted_count
//   });
//   const userId = localStorage.getItem("user_id");
//   const {
//     selectedItemId,
//     selectedItemName,
//     selectedItemType,
//     firstTodoName,
//     firstId,
//     firstType,
//   } = useTodo();
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   let [searchParams, setSearchParams] = useSearchParams();
//   const [localIsSaved, setLocalIsSaved] = useState(data?.post?.is_saved);
//   const activeTab=searchParams.get("status")

//   const status = searchParams.get("status");
//   const handleFileChange = async (event) => {
//     setThumbnail(null);
//     const file = event.target.files[0];
//     if (file) {
//       if (file.type.startsWith("image")) {
//         const cfile = await compressImage(file);
//         setFile(cfile);
//       } else {
//         setFile(file);
//         const thumbnail = await videoThumbnail(file);
//         const cfile = await compressImage(thumbnail);
//         setThumbnail(cfile);
//       }
//     }
//   };
//   const handleFileDelete = () => {
//     setFile(null);
//     setThumbnail(null);
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const storedToken = localStorage.getItem("token")
//       let bodyData;
//       let title = event.target.title.value;

//       // Check if the user has uploaded an image or video
//       if (file) {
//         const imgVideoUrl = await uploadFileToS3(file);
//         const fileType = file.type.startsWith("image") ? "image" : "video";
//         bodyData = {
//           post_id: id,
//           user_id: userId,
//           type_of: `task_${fileType}`, // Set type_of to task_image for images and task_video for videos
//           comment: `${imgVideoUrl}|&|${title || ""}`,
//           status: "still_working",
//         };
//       } else if (thumbnail) {
//         const imgVideoUrl = await uploadFileToS3(thumbnail);
//         bodyData = {
//           post_id: id,
//           user_id: selectedItemId,
//           type_of: "task_video", // Set type_of to task_video for videos
//           comment: `${imgVideoUrl}|&|${title || ""}`,
//           status: "still_working",
//         };
//       // } else if (isValidYouTubeUrl(comment)) {
//       //   // Check if the comment is a valid YouTube URL
//       //   bodyData = {
//       //     post_id: id,
//       //     user_id: selectedItemId,
//       //     type_of: "task_youtube", // Set type_of to task_youtube for YouTube URLs
//       //     comment:
//       //     status: "still_working",
//       //   };
//       } else {
//         // Default case: text only
//         bodyData = {
//           post_id: id,
//           user_id: selectedItemId,
//           type_of: "task", // Set type_of to task for text comments
//           comment: title,
//           status: "still_working",
//         };
//       }

//       const response = await fetch(`${API_BASE_URL}/api/v1/comments`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${storedToken}`,
//         },
//         body: JSON.stringify(bodyData),
//       });

//       if (response.ok) {
//         // Handle success
//         console.log("Comment added successfully");
//         // Optionally, you can clear the comment input field here
//         // setComment("");
//         setFile(null);
//     setThumbnail(null);

//         // Fetch data again after a successful API call
//         await fetchData();
//       } else {
//         // Handle errors
//         console.error("Failed to add comment");
//       }
//     } catch (error) {
//       console.error("Error occurred:", error);
//     }
//   };
//   // State to store data fetched from the API

//   // Fetch data from the API when component mounts
//   const handleSelect = (key) =>
//     navigate(genNewSearchParamString(searchParams, "status", key));
//   // useEffect(() => {
//   //   // Call the fetchPostData function
//   //   fetchPostData(id, status)
//   //     .then((data) => {
//   //       console.log("Fetched data:", data); // Log fetched data to console
//   //       setTodos(data.data.todos);
//   //       setMiniTodoData(data.data);
//   //       setData(data?.data);
//   //       console.log(data.data.todos);
//   //       console.log(data?.data?.pending_count);
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error fetching data:", error);
//   //     })
//   //     .finally(() => {
//   //       setLoading(false); // Set loading to false after data is fetched (whether successfully or with error)
//   //     });
//   // }, [id, status]); // Re-fetch data when id, status, or storedToken changes
//   // Define the fetchData function
// const fetchData = async () => {
//   try {
//     // Call the fetchPostData function
//     setLoading(true);
//     const data = await fetchPostData(id, status,1);
//     console.log("Fetched data:", data); // Log fetched data to console
//     setTodos(data.data.todos);
//     setMiniTodoData(data.data);
//     setData(data?.data);
//     console.log(data.data.todos);
//     console.log(data?.data?.pending_count);
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   } finally {
//     setLoading(false); // Set loading to false after data is fetched (whether successfully or with error)
//   }
// };

// // Use the fetchData function inside the useEffect hook
// useEffect(() => {
//   fetchData();
// }, [id, status]);

//   const handleBookmark = async () => {
//     try {
//       const storedToken = localStorage.getItem("token");

//       if (storedToken) {
//         const apiUrl = `${API_BASE_URL}/api/v1/posts/${id}/action`;
//         const headers = {
//           Authorization: `Bearer ${storedToken}`,
//           "Content-Type": "application/json",
//         };

//         const postData = {
//           type_of: "save",
//           url: "",
//         };

//         const response = await fetch(apiUrl, {
//           method: "POST",
//           headers,
//           body: JSON.stringify(postData),
//         });

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         setLocalIsSaved(!localIsSaved);
//         console.log("Bookmark action successful");

//         // Handle the response or any other logic as needed
//       } else {
//         console.error("Token not available");
//         // Handle the case where the token is not available
//       }
//     } catch (error) {
//       console.error("Error in handleBookMark:", error);
//       // Handle the error, show an alert, or perform any other necessary actions
//     }
//   };

//   useEffect(() => {
//     setLocalIsSaved(data?.post?.is_saved);
//   }, [data?.post?.is_saved]);
//   return (
//     <>
//       <div className="pt-3 ps-5 home-todo row">
//         <div className="col-3 text-end p-0">
//         <span>Home</span>
//         {" > "}
//         <Link to="/main-todo">
//         <span>To-do</span>
//         </Link>
//         {" > "}
//         {selectedItemName ? (
//           <>{` ${selectedItemName}`}</>
//         ) : (
//           <span>{firstTodoName}{firstId}{firstType}</span>
//         )}
//          {" > "}
//         </div>
//         <div className="col-7">
//         <span className="text-break mininotes p-0 text-start">{data?.post?.notes}</span>
//         </div>

//       </div>
//       <div className="container mt-2">
//         <div className="row">
//           <div className="col-9">
//           <Tabs
//         defaultActiveKey="still_working"
//         activeKey={activeTab}
//         id="tab-example"
//         className="todonav text-muted mb-1 tab-border"
//         onSelect={handleSelect}
//       >
//         <Tab
//           eventKey="still_working"
//           title={`To do (${data.pending_count})`}
//           className="text-success navlink"
//         >
//           {loading ? (
//             <div className="d-flex align-items-center justify-content-center my-5">
//               <img src={LoadingImg} alt="" width="50px" />
//             </div>
//           ) : (
//             <>
//               <div className="input-group mb-1 mt-3  todosearch ">
//                 <input
//                   type="text"
//                   className="form-control ps-4  rounded-start-pill cursor-point"
//                   placeholder="Search"
//                   name=""
//                   id="searchTodo"
//                   // value={searchQuery}
//                   // onChange={handleSearch}
//                 />
//                 <div className="input-group-append">
//                   <span className="input-group-text greysearchimg cursor-point">
//                     <img
//                       src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
//                       width="23px"
//                       alt=""
//                       className="cursor-point"
//                     />
//                   </span>
//                 </div>
//               </div>
//               <div className="container text-center pb-3">
//                       <div className="row">
//                         <div className="col-sm-1 p-0 d-flex align-items-center justify-content-center">
//                           <input
//                       className="form-check-input custm-checkbox cursor-pointer"
//                       type="checkbox"
//                       value=""
//                       id="defaultCheck1"
//                     />
//                         </div>

//                         <div className="col-sm-9 d-flex align-items-center  p-0">
//                         <p className="p-0 notes px-1 pt-1 m-0 fw-medium  text-break text-start">
//                       {data?.post?.notes}
//                     </p>
//                         </div>
//                         <div className="col-sm-2 d-flex align-items-center justify-content-end">
//                         <span
//                       className="pe-2 "
//                       onClick={() => {
//                         navigate(`/posts/${id}/comments`);
//                       }}
//                     >
//                       {data?.post?.total_comments > 0 ? (
//                         <img
//                           src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
//                           alt="Green Message"
//                           width="12px"
//                           height="12px"
//                           className="chat cursor-point"
//                         />
//                       ) : (
//                         <img
//                           src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
//                           alt="Grey Message"
//                           width="12px"
//                           height="12px"
//                           className="cursor-point"
//                         />
//                       )}
//                     </span>
//                     <span onClick={handleBookmark}>
//                       {localIsSaved ? (
//                         <span className="pe-2 cursor-point">
//                           <img
//                             src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
//                             alt="Green Message"
//                             width="11px"
//                             height="14px"
//                             className="cursor-point"
//                           />
//                         </span>
//                       ) : (
//                         <span className="ps-2 cursor-point">
//                           <img
//                             src="https://yesdone.com/build/assets/emptypen-94551c55.png"
//                             alt="Green Message"
//                             width="11px"
//                             height="14px"
//                             className="cursor-point"
//                           />
//                         </span>
//                       )}
//                     </span>
//                         </div>
//                       </div>
//                     </div>
//               {data.pending_count === 0 ? (
//                 <>
//                   <p className="text-center fw-bold text-black heighttodo pt-5">
//                     It seems like there are no tasks pending.
//                     <span className="d-block">
//                       Check back later for new tasks.
//                     </span>
//                   </p>
//                 </>
//               ) : (

//                 activeTab === "still_working" ?
//                   <MiniTodo fetchData={fetchData} id={id}/>: <p>still_working</p>

//               )}
//                   <div className="addtaskpart py-0 mb-5 mt-3">
//               <form
//                 action=""
//                 id="AddTask"
//                 onSubmit={handleSubmit}
//               >
//                 <div
//                   className="taskborder pt-0 px-0 pb-1"
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                   }}
//                 >
//                   {/* <a href="#" className="taskbtn">
//                     <img
//                       src="https://yesdone.com/build/assets/greyplus-2f2d8fa2.png"
//                       className=""
//                       alt=""
//                     />
//                   </a> */}
//                   <textarea
//                     type="text"
//                     className="form-control add-input px-0 shadow-none  border-0"
//                     autoComplete="off"
//                     required
//                     name="title"
//                     placeholder="+   Name of Task"
//                     style={{ height: "25px" , resize: "none" }}
//                   />
//                 </div>
//                 <div className="videpart">
//                   <label className="custom-file-upload">
//                     <input
//                       type="file"
//                       className="custom-file-input"
//                       id="mediaFile"
//                       name="files"
//                       accept="image/*, video/*"
//                       onChange={handleFileChange}
//                     />
//                     <img
//                       src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
//                       alt=""
//                       width="22px"
//                       height="18px"
//                       className="cursor-point"
//                     />
//                   </label>
//                   <textarea
//                     type="text"
//                     className="form-control add-input shadow-none border-0"
//                     autoComplete="off"
//                     name="video_url"
//                     placeholder="Youtube Video"
//                     style={{resize: "none" }}
//                   />
//                   <button
//                     type="submit"
//                     className="submitclass btn"
//                   >
//                     <img
//                       src="https://yesdone.com/build/assets/send-99cff475.png"
//                       alt="send img"
//                       width="35px"
//                       height="35px"
//                       className="cursor-point"
//                     />
//                   </button>
//                 </div>
//                 {(thumbnail || file) && (
//                   <ImagePreview
//                     file={thumbnail || file}
//                     onDelete={handleFileDelete}
//                   />
//                 )}
//               </form>
//             </div>
//             </>
//           )}
//         </Tab>
//         <Tab
//           eventKey="done"
//           title={`Done (${data.done_count})`}
//           className="text-success navlink"
//         >
//           {loading ? (
//             <div className="d-flex align-items-center justify-content-center my-5">
//               <img src={LoadingImg} alt="" width="50px" />
//             </div>
//           ) : (
//             <>
//                <div className="input-group mb-1 mt-3  todosearch ">
//                 <input
//                   type="text"
//                   className="form-control ps-4  rounded-start-pill cursor-point"
//                   placeholder="Search"
//                   name=""
//                   id="searchTodo"
//                   // value={searchQuery}
//                   // onChange={handleSearch}
//                 />
//                 <div className="input-group-append">
//                   <span className="input-group-text greysearchimg cursor-point">
//                     <img
//                       src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
//                       width="23px"
//                       alt=""
//                       className="cursor-point"
//                     />
//                   </span>
//                 </div>
//               </div>
//               <div className="container text-center">
//                       <div className="row">
//                         <div className="col-sm-1 p-0 d-flex align-items-center justify-content-center">
//                           <input
//                       className="form-check-input custm-checkbox cursor-pointer"
//                       type="checkbox"
//                       value=""
//                       id="defaultCheck1"
//                     />
//                         </div>

//                         <div className="col-sm-9 d-flex align-items-center  p-0">
//                         <p className="p-0 notes px-1 pt-1 m-0 fw-medium  text-break text-start">
//                       {data?.post?.notes}
//                     </p>
//                         </div>
//                         <div className="col-sm-2 d-flex align-items-center justify-content-end">
//                         <span
//                       className="pe-2 "
//                       onClick={() => {
//                         navigate(`/posts/${id}/comments`);
//                       }}
//                     >
//                       {data?.post?.total_comments > 0 ? (
//                         <img
//                           src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
//                           alt="Green Message"
//                           width="12px"
//                           height="12px"
//                           className="chat cursor-point"
//                         />
//                       ) : (
//                         <img
//                           src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
//                           alt="Grey Message"
//                           width="12px"
//                           height="12px"
//                           className="cursor-point"
//                         />
//                       )}
//                     </span>
//                     <span onClick={handleBookmark}>
//                       {localIsSaved ? (
//                         <span className="pe-2 cursor-point">
//                           <img
//                             src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
//                             alt="Green Message"
//                             width="11px"
//                             height="14px"
//                             className="cursor-point"
//                           />
//                         </span>
//                       ) : (
//                         <span className="ps-2 cursor-point">
//                           <img
//                             src="https://yesdone.com/build/assets/emptypen-94551c55.png"
//                             alt="Green Message"
//                             width="11px"
//                             height="14px"
//                             className="cursor-point"
//                           />
//                         </span>
//                       )}
//                     </span>
//                         </div>
//                       </div>
//                     </div>
//               {data.done_count === 0 ? (
//                 <p className="text-center fw-bold text-black heighttodo pt-5">
//                   It seems like there are no tasks pending.
//                   <span className="d-block">
//                     Check back later for new tasks.
//                   </span>
//                 </p>
//               ) : (
//                 activeTab === "done" ?

//                   <MiniDone fetchData={fetchData}/> : <p>done</p>

//               )}
//             </>
//           )}
//         </Tab>
//         <Tab
//           eventKey="deleted"
//           title={`Deleted (${data.deleted_count})`}
//           className="text-success navlink"
//         >
//           {loading ? (
//             <div className="d-flex align-items-center justify-content-center my-5">
//               <img src={LoadingImg} alt="" width="50px" />
//             </div>
//           ) : (
//             <>
//                <div className="input-group mb-1 mt-3  todosearch ">
//                 <input
//                   type="text"
//                   className="form-control ps-4  rounded-start-pill cursor-point"
//                   placeholder="Search"
//                   name=""
//                   id="searchTodo"
//                   // value={searchQuery}
//                   // onChange={handleSearch}
//                 />
//                 <div className="input-group-append">
//                   <span className="input-group-text greysearchimg cursor-point">
//                     <img
//                       src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
//                       width="23px"
//                       alt=""
//                       className="cursor-point"
//                     />
//                   </span>
//                 </div>
//               </div>
//               <div className="container text-center">
//                       <div className="row">
//                         <div className="col-sm-1 p-0 d-flex align-items-center justify-content-center">
//                           <input
//                       className="form-check-input custm-checkbox cursor-pointer"
//                       type="checkbox"
//                       value=""
//                       id="defaultCheck1"
//                     />
//                         </div>

//                         <div className="col-sm-9 d-flex align-items-center  p-0">
//                         <p className="p-0 notes px-1 pt-1 m-0 fw-medium  text-break text-start">
//                       {data?.post?.notes}
//                     </p>
//                         </div>
//                         <div className="col-sm-2 d-flex align-items-center justify-content-end">
//                         <span
//                       className="pe-2 "
//                       onClick={() => {
//                         navigate(`/posts/${id}/comments`);
//                       }}
//                     >
//                       {data?.post?.total_comments > 0 ? (
//                         <img
//                           src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
//                           alt="Green Message"
//                           width="12px"
//                           height="12px"
//                           className="chat cursor-point"
//                         />
//                       ) : (
//                         <img
//                           src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
//                           alt="Grey Message"
//                           width="12px"
//                           height="12px"
//                           className="cursor-point"
//                         />
//                       )}
//                     </span>
//                     <span onClick={handleBookmark}>
//                       {localIsSaved ? (
//                         <span className="pe-2 cursor-point">
//                           <img
//                             src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
//                             alt="Green Message"
//                             width="11px"
//                             height="14px"
//                             className="cursor-point"
//                           />
//                         </span>
//                       ) : (
//                         <span className="ps-2 cursor-point">
//                           <img
//                             src="https://yesdone.com/build/assets/emptypen-94551c55.png"
//                             alt="Green Message"
//                             width="11px"
//                             height="14px"
//                             className="cursor-point"
//                           />
//                         </span>
//                       )}
//                     </span>
//                         </div>
//                       </div>
//                     </div>
//               {data.deleted_count === 0 ? (
//                 <p className="text-center fw-bold text-black heighttodo pt-5">
//                   It seems like there are no tasks pending.
//                   <span className="d-block">
//                     Check back later for new tasks.
//                   </span>
//                 </p>
//               ) : (

//                  activeTab === "deleted" ?
//                   <MiniDelete fetchData={fetchData}/>:<p>deleted</p>
//               )}
//             </>
//           )}
//         </Tab>
//       </Tabs>
//           </div>
//           <div className="col-3">
//            <DateAndSort/>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MiniPost;
import React, { useEffect, useState, useRef, useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { fetchPostData } from "../../Api/MiniTodo";
import { Tabs, Tab } from "react-bootstrap";
import LoadingImg from "../../images/loading.webp";
import { useNavigate, useSearchParams } from "react-router-dom"; // Import the fetchPostData function
import { genNewSearchParamString } from "../../utils";
import API_BASE_URL from "../../Config";
import { useMiniTodoData } from "../AuthContext/minitodos";
import DateAndSort from "../datesort/DateAndSort";
import "./minipost.css";
import MiniDelete from "./MiniDelete";
import MiniDone from "./MiniDone";
import axios from "axios";
import MiniTodo from "./MiniTodo";
import ImagePreview from "../../utils/image-preview";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
} from "../../utils/index";
import { useTodo } from "../AuthContext/TodoContext";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import StaticBox from "./StaticBox";
import ToastContainer from "../toast/ToastContainer";
import UserContext from "../AuthContext/ChatContext";
const MiniPost = () => {
  // Extract id and status from URL params
  const { miniTodoData, setMiniTodoData, count, setCount } = useMiniTodoData();
  const toastRef = useRef();
  const [todos, setTodos] = useState([]);
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  
  const { id, postid } = useParams();
  const [data, setData] = useState({
    post: { notes: "" }, // Default value for post
    pending_count: 0, // Default value for pending_count
    done_count: 0, // Default value for done_count
    deleted_count: 0, // Default value for deleted_count
  });
  const [title1, setTitle1] = useState("");
  const selectedType = localStorage.getItem("selectedType");
  const selectedItemId1 = localStorage.getItem("selectedItemId");
  const firstId1 = localStorage.getItem("firstId");
  const firstType1 = localStorage.getItem("firstType");
  const { groupName } = useContext(UserContext);
  const userId = localStorage.getItem("user_id");
  const {
    selectedItemId,
    selectedItemName,
    selectedItemType,
    firstTodoName,
    firstId,
    firstType,
  } = useTodo();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [localIsSaved, setLocalIsSaved] = useState(data?.post?.is_saved);
  const activeTab = searchParams.get("status");

  const status = searchParams.get("status");
  const [sortOrder, setSortOrder] = useState('desc'); // Default sorting order
  const [sortBy, setSortBy] = useState('post_comments.due_date');
  const [showCalander, setShowCalander] = useState(false);
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 7);
    return date;
  });
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = selectedDate.toLocaleDateString("en-US", options);
  const [isIconUp, setIsIconUp] = useState(false);

  const handleIconClick = async () => {
    const sort = !isIconUp ? "asc" : "desc";
    setIsIconUp((prevState) => !prevState);
    try {
      // Call the fetchPostData function

      setLoading(true);
      const data = await fetchPostData(id, status, 1, sort);
      console.log("Fetched data:", data); // Log fetched data to console
      setTodos(data.data.todos);
      setCount({
        todoCount: data.data.pending_count || 0,
        doneCount: data.data.done_count || 0,
        deleteCount: data.data.deleted_count || 0,
      });
      setMiniTodoData(data.data);
      setData(data?.data);
      console.log(data.data.todos);
      console.log(data?.data?.pending_count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched (whether successfully or with error)
    }
  };

  const dateField = "post_comments.due_date";
  const notes = "post_comments.comment";
  const createdat = "post_comments.created_at";
  const Updatedat = "post_comments.updated_at";
  
 // Updated fetchData function to accept sorting parameters


// Updated handleSortChange function to handle new sorting inputs
// const handleSortChange = async (newSortBy, newSortOrder) => {
//   try {
//     // Determine the correct sortBy parameter for the API
//     let sortByParam;
//     switch (newSortBy) {
//       case "createdat":
//         sortByParam = "post_comments.created_at"; // Sort by post creation date
//         break;
//       case "updatedat":
//         sortByParam = "post_comments.updated_at"; // Sort by comment creation date
//         break;
//       case "notes":
//         sortByParam = "post_comments.comment"; // Sort by name/notes
//         break;
//       case "dateField":
//         sortByParam = "post_comments.due_date"; // Default sort by due_date
//         break;
//       default:
//         sortByParam = "post_comments.due_date"; // Fallback in case of unexpected sortBy values
//     }

//     // Clear the todos state to remove previous data before fetching new sorted data
//     setTodos([]);
//     setMiniTodoData({ todos: [] });
//     setSortBy(newSortBy);
//     setSortOrder(newSortOrder);
//     setLoading(true);

//     // Fetch sorted data from API with updated sort parameters
//     const data = await fetchPostData(id, status, 1, sortByParam, newSortOrder);

//     // Update the state with new sorted data
//     setTodos(data.data.todos);
//     setMiniTodoData({ todos: data.data.todos });

//     // Update counts or other state values as needed
//     setCount({
//       todoCount: data.data.pending_count || 0,
//       doneCount: data.data.done_count || 0,
//       deleteCount: data.data.deleted_count || 0,
//     });

//     setData(data?.data);
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   } finally {
//     setLoading(false);
//   }
// };
const handleSortChange = async (newSortBy, newSortOrder) => {
  try {
    let sortByParam;
    switch (newSortBy) {
      case createdat:
        sortByParam = "post_comments.created_at";
        break;
      case Updatedat:
        sortByParam = "post_comments.updated_at";
        break;
      case notes:
        sortByParam = "post_comments.comment";
        break;
      case dateField:
        sortByParam = "post_comments.due_date";
        break;
      default:
        sortByParam = "post_comments.due_date";
    }

    setTodos([]);
    setMiniTodoData({ todos: [] });
    setSortBy(sortByParam);
    setSortOrder(newSortOrder);
    setLoading(true);

    const data = await fetchPostData(id, status, 1, sortByParam, newSortOrder);
    setTodos(data.data.todos);
    setMiniTodoData({ todos: data.data.todos });
    setCount({
      todoCount: data.data.pending_count || 0,
      doneCount: data.data.done_count || 0,
      deleteCount: data.data.deleted_count || 0,
    });
    setData(data?.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};
  
  

  const handleShowCalander = () => {
    setShowCalander(!showCalander);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getInitialDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date;
  };
  const handleTextChange = (e) => {
    setTitle1(e.target.value);
  };
  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };
  const formatDateToLocalISO = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().split("T")[0];
  };
  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };
  console.log(
    "groupname,selectedname,firstTodoName",
    groupName,
    selectedItemName,
    firstTodoName
  );
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;
      let title = event.target.title.value;
      const formattedSelectedDate = formatDateToLocalISO(selectedDate);
      const currentDate = new Date(); // current date
      const sevenDays = new Date(currentDate);
      sevenDays.setDate(currentDate.getDate() + 7);
      const formattedDate =
        formattedSelectedDate || sevenDays.toISOString().split("T")[0];
      // Check if the user has uploaded an image or video
      if (file) {
        const imgVideoUrl = await uploadFileToS3(file);
        const fileType = file.type.startsWith("image") ? "image" : "video";
        bodyData = {
          post_id: id,
          user_id: userId,
          type_of: `task_${fileType}`, // Set type_of to task_image for images and task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
          status: "still_working",
          due_date: formattedDate,
        };
      } else if (thumbnail) {
        const imgVideoUrl = await uploadFileToS3(thumbnail);
        bodyData = {
          post_id: id,
          user_id: userId,
          type_of: "task_video", // Set type_of to task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
          status: "still_working",
          due_date: formattedDate,
        };
        // } else if (isValidYouTubeUrl(comment)) {
        //   // Check if the comment is a valid YouTube URL
        //   bodyData = {
        //     post_id: id,
        //     user_id: selectedItemId,
        //     type_of: "task_youtube", // Set type_of to task_youtube for YouTube URLs
        //     comment:
        //     status: "still_working",
        //   };
      } else {
        // Default case: text only
        bodyData = {
          post_id: id,
          user_id: userId,
          type_of: "task", // Set type_of to task for text comments
          comment: title,
          status: "still_working",
          due_date: formattedDate,
        };
      }

      const response = await fetch(`${API_BASE_URL}/api/v1/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        // Handle success
        console.log("minitodoData", bodyData);

        const newComment = await response.json();
        console.log("Comment added successfully", newComment.data);
        const newData = newComment.data;

        // Create a new array with the added object at the beginning
        const newPosts = [newData, ...miniTodoData.todos];

        // Update todoCount and posts in the state
        setMiniTodoData((prevTodoData) => ({
          ...prevTodoData,

          todos: newPosts,
        }));
        setCount((prevTodoData) => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount + 1,
        }));
        // Optionally, you can clear the comment input field here
        // setComment("");
        setShowCalander(false); // Hide the calendar
        setSelectedDate(getInitialDate());
        toastRef.current.showToast("Post Added Successfully!");
        setFile(null);
        setThumbnail(null);
        setTitle1("");

        // Fetch data again after a successful API call
        // await fetchData();
      } else {
        // Handle errors
        console.error("Failed to add comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  // State to store data fetched from the API

  // Fetch data from the API when component mounts
  const handleSelect = (key) =>
    navigate(genNewSearchParamString(searchParams, "status", key));
  // useEffect(() => {
  //   // Call the fetchPostData function
  //   fetchPostData(id, status)
  //     .then((data) => {
  //       console.log("Fetched data:", data); // Log fetched data to console
  //       setTodos(data.data.todos);
  //       setMiniTodoData(data.data);
  //       setData(data?.data);
  //       console.log(data.data.todos);
  //       console.log(data?.data?.pending_count);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     })
  //     .finally(() => {
  //       setLoading(false); // Set loading to false after data is fetched (whether successfully or with error)
  //     });
  // }, [id, status]); // Re-fetch data when id, status, or storedToken changes
  // Define the fetchData function
  const fetchData = async (sortBy = "post_comments.due_date", sortOrder = "desc") => {
    try {
      setLoading(true);
      // Clear the state before fetching new data
      setTodos([]);
      setMiniTodoData({ todos: [] });
  
      // Fetch fresh data with sorting parameters
      const data = await fetchPostData(id, status, 1, sortBy, sortOrder);
  
      // Update state with fetched data
      setTodos(data.data.todos);
      setMiniTodoData({ todos: data.data.todos });
  
      setCount({
        todoCount: data.data.pending_count || 0,
        doneCount: data.data.done_count || 0,
        deleteCount: data.data.deleted_count || 0,
      });
  
      setData(data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  // Use the fetchData function inside the useEffect hook
  useEffect(() => {
    fetchData();
  }, [id, status]);

  const handleBookmark = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${id}/action`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };

        const postData = {
          type_of: "save",
          url: "",
        };

        const response = await fetch(apiUrl, {
          method: "POST",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        setLocalIsSaved(!localIsSaved);
        console.log("Bookmark action successful");

        // Handle the response or any other logic as needed
      } else {
        console.error("Token not available");
        // Handle the case where the token is not available
      }
    } catch (error) {
      console.error("Error in handleBookMark:", error);
      // Handle the error, show an alert, or perform any other necessary actions
    }
  };

  useEffect(() => {
    setLocalIsSaved(data?.post?.is_saved);
  }, [data?.post?.is_saved]);

  const idToUse = selectedItemId1 ? selectedItemId1 : firstId1;
  const typeToUse = selectedType ? selectedType : firstType1;

  const to =
    "/todo-list" +
    genNewSearchParamString(
      searchParams,
      typeToUse === "user" ? "user_id" : "group_id",
      idToUse
    );

  return (
    <>
      <div className="container">
        <div className="pt-1 ps-3  row">
          <div className=" col-md-12 col-lg-8 ps-4 padding-done text-start ">
            <span className="todos-home" onClick={() => navigate("/posts/new")}>
              Home {" > "}
            </span>

            <Link to="/main-todo">
              <span className="todos-home">To-do {" > "}</span>
            </Link>

            {groupName ? (
              <Link to={to}>
                <span className="todos-home">{groupName}</span>
              </Link>
            ) : (
              <>
                {selectedItemName ? (
                  <Link to={to}>
                    <span className="todos-home">{selectedItemName}</span>
                  </Link>
                ) : (
                  <span className="todos-home">
                    <Link to={to}>{firstTodoName} </Link>
                  </span>
                )}
              </>
            )}

            <span className="todos-home">{" > "}</span>
            <span className="text-break todos-home p-0 text-start">
              {data?.post?.notes}
            </span>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-9 ">
            <div className="row">
              <div className="position-parent">
                <div className="col-10">
                  <ToastContainer ref={toastRef} timeout={4000} />
                  <Tabs
                    defaultActiveKey="still_working"
                    activeKey={activeTab}
                    id="tab-example"
                    className="todonav text-muted mb-1 tab-border"
                    onSelect={handleSelect}
                  >
                    <Tab
                      eventKey="still_working"
                      title={`To do (${count.todoCount})`}
                      className="text-success navlink"
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          {/* <img src={LoadingImg} alt="" width="50px" /> */}
                          <StaticBox />
                        </div>
                      ) : (
                        <>
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              // value={searchQuery}
                              // onChange={handleSearch}
                            />

                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container text-center">
                            <div className="row pb-2">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.todoCount === 0 ? (
                            <>
                              <p className="text-center fw-bold text-black heighttodo pt-5">
                                It seems like there are no tasks pending.
                                <span className="d-block">
                                  Check back later for new tasks.
                                </span>
                              </p>
                            </>
                          ) : activeTab === "still_working" ? (
                            <MiniTodo
                              fetchData={fetchData}
                              id={id}
                              sortOrder={sortOrder}
                              sortBy={sortBy}
                              isIconUp={isIconUp}

                            />
                          ) : (
                            <p>still_working</p>
                          )}
                          <div className="addtaskpart py-0 mb-5 mt-3">
                            <form
                              action=""
                              id="AddTask"
                              onSubmit={handleSubmit}
                            >
                              <div
                                className="taskborder pt-0 px-0 pb-1 d-flex align-items-center"
                                // style={{
                                //   display: "flex",
                                //   alignItems: "center",
                                // }}
                              >
                                {/* <a href="#" className="taskbtn">
                    <img
                      src="https://yesdone.com/build/assets/greyplus-2f2d8fa2.png"
                      className=""
                      alt=""
                    />
                  </a> */}
                                <textarea
                                  type="text"
                                  className="form-control add-input px-0 shadow-none  border-0 text-resize"
                                  autoComplete="off"
                                  required
                                  name="title"
                                  onChange={(e) => handleTextChange(e)}
                                  value={title1}
                                  placeholder="+   Name of Task"
                                  // style={{ height: "25px" , resize: "none" }}
                                />
                                {/* Due Date and Calendar */}
                                <div className="position-relative d-flex align-items-center ms-2">
                                  <button
                                    type="button"
                                    className="border-0 text-start calander bg-transparent"
                                    onClick={handleShowCalander}
                                    style={{ position: "relative", zIndex: 2 }}
                                  >
                                    <span className="d-flex justify-content-between align-items-center">
                                      <span className="me-2 custom-fontsize">
                                        Due date :
                                      </span>
                                      <span className="custom-fontsize">
                                        {formattedDate}
                                      </span>
                                    </span>
                                  </button>
                                  {showCalander ? (
                                    <div
                                      className="position-absolute mt-2 shadow bg-white"
                                      style={{
                                        zIndex: 999,
                                        top: "",
                                        bottom: "30px",
                                        right: "0px",
                                      }}
                                    >
                                      <Calendar
                                        onChange={handleDateChange}
                                        value={selectedDate}
                                        // minDate={currentDate}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="d-flex">
                                <label className="custom-file-upload">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="mediaFile"
                                    name="files"
                                    accept="image/*, video/*"
                                    onChange={handleFileChange}
                                  />
                                  <img
                                    src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                    alt=""
                                    width="22px"
                                    height="18px"
                                    className="cursor-point"
                                  />
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control add-input shadow-none border-0 text-resize"
                                  autoComplete="off"
                                  name="video_url"
                                  placeholder="Youtube Video"
                                />
                                <button
                                  type="submit"
                                  className="submitclass btn ms-auto"
                                >
                                  <img
                                    src="https://yesdone.com/build/assets/send-99cff475.png"
                                    alt="send img"
                                    width="35px"
                                    height="35px"
                                    className="cursor-point"
                                  />
                                </button>
                              </div>
                              {(thumbnail || file) && (
                                <ImagePreview
                                  file={thumbnail || file}
                                  onDelete={handleFileDelete}
                                />
                              )}
                            </form>
                          </div>
                          <ToastContainer ref={toastRef} timeout={4000} />
                        </>
                      )}
                    </Tab>
                    <Tab
                      eventKey="done"
                      title={`Done (${count.doneCount})`}
                      className="text-success navlink"
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <StaticBox />
                        </div>
                      ) : (
                        <>
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              // value={searchQuery}
                              // onChange={handleSearch}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container  text-center">
                            <div className="row pb-1">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.doneCount === 0 ? (
                            <p className="text-center fw-bold text-black heighttodo pt-5">
                              It seems like there are no tasks pending.
                              <span className="d-block">
                                Check back later for new tasks.
                              </span>
                            </p>
                          ) : activeTab === "done" ? (
                            <MiniDone
                              fetchData={fetchData}
                              Post_id={id}
                              toastRef={toastRef}
                            />
                          ) : (
                            <p>done</p>
                          )}
                        </>
                      )}
                    </Tab>
                    <Tab
                      eventKey="deleted"
                      title={`Deleted (${count.deleteCount})`}
                      className="text-success navlink"
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <StaticBox />
                        </div>
                      ) : (
                        <>
                          <div className="input-group mb-1 mt-1  todosearch ">
                            <input
                              type="text"
                              className="form-control ps-4  rounded-start-pill cursor-point shadow-none"
                              placeholder="Search"
                              name=""
                              id="searchTodo"
                              // value={searchQuery}
                              // onChange={handleSearch}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text greysearchimg cursor-point">
                                <img
                                  src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                                  width="23px"
                                  alt=""
                                  className="cursor-point"
                                />
                              </span>
                            </div>
                          </div>
                          <div className="container  text-center">
                            <div className="row pb-2">
                              <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-center">
                                <Tippy content="checkbox">
                                  <input
                                    className="form-check-input custm-checkbox cursor-pointer"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                </Tippy>
                              </div>

                              <div className="col-sm-9 col-9 d-flex align-items-center  p-0">
                                <p className=" notes px-1 pt-1 m-0 fw-medium  text-break text-start">
                                  {data?.post?.notes}
                                </p>
                              </div>
                              <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                                <span
                                  className="pe-2 "
                                  onClick={() => {
                                    navigate(`/posts/${id}/comments`);
                                  }}
                                >
                                  <Tippy content="comments">
                                    {data?.post?.total_comments > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="12px"
                                        height="12px"
                                        className="chat cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="Grey Message"
                                        width="12px"
                                        height="12px"
                                        className="cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                                <span onClick={handleBookmark}>
                                  <Tippy
                                    content={
                                      localIsSaved
                                        ? "bookmark checked"
                                        : "bookmark"
                                    }
                                  >
                                    {localIsSaved ? (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    ) : (
                                      <span className="ps-2 cursor-point">
                                        <img
                                          src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                          alt="Green Message"
                                          width="11px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      </span>
                                    )}
                                  </Tippy>
                                </span>
                              </div>
                            </div>
                          </div>
                          {count.deleteCount === 0 ? (
                            <p className="text-center fw-bold text-black heighttodo pt-5">
                              It seems like there are no tasks pending.
                              <span className="d-block">
                                Check back later for new tasks.
                              </span>
                            </p>
                          ) : activeTab === "deleted" ? (
                            <MiniDelete fetchData={fetchData} />
                          ) : (
                            <p>deleted</p>
                          )}
                        </>
                      )}
                    </Tab>
                  </Tabs>
                </div>
                {activeTab === "still_working" && (
                  <>
                    <div className="position-child">
                      <span>Due Date</span>
                      <button
                        type="button"
                        className="btn p-0 ms-2"
                        onClick={handleIconClick}
                        style={{ background: "transparent", border: "none" }}
                      >
                        {isIconUp ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-3  ">
            <DateAndSort
              handleSortChange={handleSortChange}
              dateField={dateField}
              alphabetical={notes}
              createdat={createdat}
              Updatedat={Updatedat}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniPost;
