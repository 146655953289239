import React from 'react';

import AppRoutes from './AppRoutes';


const App = () => {
  return (
 
      <AppRoutes />
    
  );
};

export default App;