import React, { useEffect, useRef, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserContext from "../AuthContext/ChatContext";
import "./Chat.css";
import axios from "axios";
import API_BASE_URL from "../../Config";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchPostData } from "../../Api/MiniTodo";
import LoadingImg from "../../images/loading.webp";
import ImagePreview from "../../utils/image-preview";
import { Link } from "react-router-dom";
import ChatWindowStatic from "./ChatWindowStatic";

import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
} from "../../utils/index";
import { Modal, Button } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import { useTodo } from "../AuthContext/TodoContext";

const ChatWindow = ({ fetchDataAndUpdateState }) => {
  const { selectedUserId1, setGroupName,groupName } = useContext(UserContext);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [selectedItemId2, setSelectedItemId2] = useState(null); // State to store the selected item ID
  const [showModal, setShowModal] = useState(false);
  const [EditMessage, setEditMessage] = useState(false); // Change "false" to false
  const [EditMessageId, setEditMessageId] = useState(null); // Change "null" to null
  const [editedMessage, setEditedMessage] = useState("");
  // const [pageNo, setPageNo] = useState(1);
  const [loader, setLoader] = useState(false);
  const {
    selectedItemId,
    selectedItemName,
    setSelectedItemName,
    selectedItemType,
    firstTodoName,
    firstId,
    firstType,
    setFirstId,
  } = useTodo();

  console.log("user_id1", selectedUserId1);

  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state) => state.reducer.selectedConversation
  );
  const selectedUserId = useSelector((state) => state.reducer.selectedUserId);
  const selectedUserType = useSelector(
    (state) => state.reducer.selectedUserType
  );
  const showConversations = useSelector(
    (state) => state.reducer.showConversations
  );
  const hasMore = useSelector((state) => state.reducer.hasMore);
  const pageNo = useSelector((state) => state.reducer.pageNo);
  console.log("showConversations", showConversations);
  const loading = useSelector((state) => state.reducer.loading);
  //   console.log("selected userid,type",selectedUserId,selectedUserType);
  const mainUser = localStorage.getItem("user_id");
  const chatContainerRef = useRef(null);
  // console.log("type of ", typeof mainUser);

  const fetchAdditionalData = async (page_no = null) => {
    const pg_no = page_no || pageNo;

    console.log("pageNo", pg_no);
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const storedToken = localStorage.getItem("token");
      let apiUrlPath = "";

      if (selectedUserId1) {
        apiUrlPath = `${API_BASE_URL}/api/v1/messages/${selectedUserId1.id}?type_of=single&page=${pg_no}`;
      } else {
        apiUrlPath = `${API_BASE_URL}/api/v1/messages/${selectedUserId}?type_of=${selectedUserType}&page=${pg_no}`;
      }

      console.log("apiUrlPath", apiUrlPath);

      // Make initial API call to get the pagination information
      const initialResponse = await fetch(apiUrlPath, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!initialResponse.ok) {
        if (initialResponse.status === 404) {
          // Update state to indicate no conversations found
          dispatch({ type: "SET_SHOW_CONVERSATIONS", payload: [] });
        } else {
          throw new Error("Network response was not ok");
        }
      } else {
        const initialResult = await initialResponse.json();
        console.log(initialResult.data);
        // setHasMore(
        //   initialResult?.data?.pagination?.current_page <=
        //   initialResult?.data?.pagination?.last_page
        // );
        dispatch({
          type: "SET_HASMORE",
          payload:
            initialResult?.data?.pagination?.current_page <=
            initialResult?.data?.pagination?.last_page,
        });

        dispatch({
          type: "SET_PAGENO",
          payload: initialResult?.data?.pagination?.current_page + 1,
        });
        dispatch({
          type: "SET_SHOW_CONVERSATIONS",
          payload: initialResult.data.conversation,
        });
        console.log(initialResult.data);
      }
      console.log("pagen umber,", pageNo);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };
  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };
  const handleChatSubmit = async (e) => {
    dispatch({ type: "RESET_SHOW_CONVERSATIONS", payload: [] });
    e.preventDefault();
    console.log("selectedUserType", selectedUserType);
    try {
      const storedToken = localStorage.getItem("token");

      // Initialize requestBody with default values
      let requestBody = {
        content: message,
        media: [],
      };

      // Check if file exists (assuming file is obtained from somewhere)
      if (file) {
        const imgVideoUrl = await uploadFileToS3(file);
        const fileType = file.type.startsWith("image") ? "image" : "video";

        // Update requestBody to include file data
        requestBody.media.push({
          type: fileType,
          url: imgVideoUrl,
        });
      }

      if (selectedUserId1) {
        requestBody.receiver_id = selectedUserId1.id;
      } else if (selectedUserId && selectedUserType === "single") {
        requestBody.receiver_id = selectedUserId;
      } else if (selectedUserType === "group") {
        requestBody.group_id = selectedUserId;
      }

      console.log("requestBody", requestBody);

      // Make an API call to post the message
      const response = await fetch(`${API_BASE_URL}/api/v1/messages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setMessage("");
      setThumbnail(null);
      setFile(null);
      fetchAdditionalData(1);
      fetchDataAndUpdateState();
    } catch (error) {
      console.error("Error posting message:", error);
      // Handle error, if needed
    }
  };
  const handleEditChat = (id, content) => {
    setEditMessage(true);
    setEditMessageId(id);
    // Set the initial value of editedMessage when editing starts
    setEditedMessage(content);
  };

  const handleEditMessageClose = () => {
    setEditMessage(false);
  };
  const handleCommentUpdate = async (id) => {
    try {
      dispatch({ type: "RESET_SHOW_CONVERSATIONS", payload: [] });
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/messages/${id}`,
        {
          content: editedMessage,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        // Change to response.status
        console.log("Message updated successfully");
        fetchAdditionalData(1);
        fetchDataAndUpdateState();
      } else {
        console.error("Failed to update message");
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
    setEditMessage(false);
    setEditedMessage("");
  };

  const handleDelete = async () => {
    try {
      dispatch({ type: "RESET_SHOW_CONVERSATIONS", payload: [] });
      // Fetch the authorization token from localStorage
      const token = localStorage.getItem("token");

      // Make the DELETE request
      if (token && selectedItemId2) {
        const response = await axios.delete(
          `${API_BASE_URL}/api/v1/messages/${selectedItemId2}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Log response after request
        console.log("Delete successful:", response);

        // Close modal after successful delete
        handleCloseModal();

        // Fetch additional data after successful delete (optional)
        fetchAdditionalData(1);
        fetchDataAndUpdateState();
        // Handle success, if needed
      } else {
        // Handle invalid token or id
        console.error("Invalid token or id");
      }
    } catch (error) {
      console.error("Error deleting:", error);
      // Handle error, if needed
    }
  };

  const handleOpenModal = (todoId) => {
    setSelectedItemId2(todoId); // Set the selected todo ID
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setSelectedItemId2(null); // Reset the selected item ID
    setShowModal(false); // Close the modal
  };
  useEffect(() => {
    setLoader(true);
    if ((selectedUserId && selectedUserType) || selectedUserId1) {
      fetchAdditionalData();
    }
    setLoader(false);
  }, [selectedUserId, selectedUserId1, selectedUserType]);

  useEffect(() => {
    // Scroll to the bottom when showConversation is loaded
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [showConversations]);
  function handleInputChange(event) {
    const textarea = event.target;
    const contentHeight = textarea.scrollHeight;
    const computedStyle = getComputedStyle(textarea);
    const lineHeight = parseInt(computedStyle.lineHeight);
    const minHeight = parseInt(computedStyle.minHeight);
    if (textarea.value.trim() === "") {
      textarea.style.height = `${minHeight}px`;
    } else if (contentHeight > lineHeight) {
      textarea.style.height = "auto";
      const maxHeight = lineHeight * 5;
      textarea.style.height = `${Math.min(contentHeight, maxHeight)}px`;
    } else {
      textarea.style.height = `${minHeight}px`;
    }
  }
  console.log("groupname,selectedname,",groupName,selectedItemName,firstTodoName);
  const handleMiniClick = (event) => {
    event.stopPropagation(); // Stop the event from bubbling up to the <Link>
    
};
  return (
    <>
      {selectedUserId1 ? (
        <div className="d-flex align-items-center">
          {" "}
          {selectedUserId1 && selectedUserId1.profile_image ? (
            <img
              src={selectedUserId1.profile_image}
              className="rounded-circle"
              alt="Profile"
              width="60px"
              height="60px"
            />
          ) : (
            <div className="profile">
              {selectedUserId1 && selectedUserId1.first_name
                ? selectedUserId1.first_name.charAt(0).toUpperCase()
                : "N"}
            </div>
          )}
          <span className="ps-3">
            {selectedUserId1.first_name} {selectedUserId1.last_name}
          </span>
        </div>
      ) : (
        <>
          {selectedConversation && (
            <div className="d-flex align-items-center pb-3 top-padding">
              <div className="col-1 text-center">
                {selectedConversation.type_of === "single" ? (
                  <>
                    {/* Conditional rendering for profile image or first letter */}

                    {selectedConversation.other_user &&
                    selectedConversation.other_user.profile_image ? (
                      <img
                        src={selectedConversation.other_user.profile_image}
                        className="rounded-circle"
                        alt="Profile"
                        width="50px"
                        height="50px"
                      />
                    ) : (
                      <div className="profile">
                        {selectedConversation.other_user &&
                        selectedConversation.other_user.first_name
                          ? selectedConversation.other_user.first_name
                              .charAt(0)
                              .toUpperCase()
                          : "N"}
                      </div>
                    )}
                  </>
                ) : (
                  // Render static icon for group
                  <i className="bi bi-people-fill  rounded-icon"></i>
                )}
              </div>
              <div className="col-md-6 d-flex fs-5">
                <div className="d-flex align-items-center">
                  {selectedConversation.type_of !== "group" && (
                    <span className="name-color">
                      {selectedConversation.other_user?.first_name ?? "null"}{" "}
                      {selectedConversation.other_user?.last_name ?? "null"}
                    </span>
                  )}
                  <span className="text-muted  name-color">
                    {selectedConversation.group?.name}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* {loader && <img height={45} width={45} src={LoadingImg} />} */}
      <div>
        <div
          id="scrollableDiv"
          className="scrollableMainDelete "
          style={{
            height: 500,
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {showConversations && showConversations.length > 0 ? (
            <InfiniteScroll
              className="over-infi d-flex flex-column-reverse"
              dataLength={showConversations.length}
              next={() => fetchAdditionalData()}
              // style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
            >
              {showConversations
                .slice()

                .map((item, index) => (
                  <div key={index + 34} className="mb-2 pt-4 commonBorderTop">
                    {item.media.map((media, index) => (
                      <div
                        key={index}
                        className={
                          String(mainUser) === String(item.sender.id)
                            ? "text-end pb-2"
                            : "text-start pb-2"
                        }
                      >
                        {media.type === "image" ? (
                          <img
                            src={media.url}
                            width="300px"
                            height="300px"
                            alt={item.id}
                          />
                        ) : media.type === "video" ? (
                          <video width="300px" height="300px" controls>
                            <source src={media.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    {EditMessage && EditMessageId === item.id ? (
                      <div className="col-12 col-md-5 d-flex align-items-center edit-form form-edit">
                        <textarea
                          type="text"
                          className="form-control"
                          value={editedMessage} // Use editedMessage instead of item.content
                          onChange={(e) => setEditedMessage(e.target.value)} // Simplified onChange
                        ></textarea>
                        <span className="d-flex my-3">
                          <button
                            className="btn d-flex align-items-center justify-content-center mx-3"
                            onClick={() => handleCommentUpdate(item.id)} // Pass item.id directly
                          >
                            <i className="bi bi-check-lg"></i>
                          </button>
                          <button
                            className="btn btn-secondary d-flex align-items-center justify-content-center"
                            onClick={handleEditMessageClose}
                          >
                            <i className="bi bi-x"></i>
                          </button>
                        </span>
                      </div>
                    ) : (
                      <>
                        <div
                          className={` ${
                            String(mainUser) === String(item.sender.id)
                              ? item.content && item.content.length > 35
                                ? "blueBackgroundLong "
                                : "blueBackgroundShort "
                              : item.content && item.content.length > 35
                              ? "greenBackgroundLong d-flex justify-content-between"
                              : "greenBackgroundShort d-flex justify-content-between"
                          }`}
                        >
                          <>
                            <div className="position-relative d-flex">
                              <span className="col-10 d-flex align-items-center">
                                {" "}
                              <pre  className="pre-comments"> {item.content}</pre> 
                              </span>
                              <span className="col-2 text-end d-flex cursor-pointer">
                                {String(mainUser) ===
                                  String(item.sender.id) && (
                                  <>
                                    <Tippy content="edit">
                                      <i
                                        className="bi bi-pencil-fill pe-1"
                                        onClick={() =>
                                          handleEditChat(item.id, item.content)
                                        }
                                      ></i>
                                    </Tippy>
                                    <Tippy content="delete">
                                      <i
                                        className="bi bi-x-lg pe-4 "
                                        onClick={() => handleOpenModal(item.id)}
                                      ></i>
                                    </Tippy>
                                  </>
                                )}
                              </span>
                            </div>
                          </>
                        </div>
                        <div
                          className={`pt-2 ${
                            String(mainUser) === String(item.sender.id)
                              ? "blueBackground1"
                              : "greenBackground1"
                          }`}
                        >
                          <span className="pe-1">
                            {item.sender && item.sender.profile_image ? (
                              <img
                                src={item.sender.profile_image}
                                alt=""
                                className="profile1"
                              />
                            ) : (
                              item.sender && (
                                <div className="profiles1">
                                  {item.sender && item.sender.first_name
                                    ? item.sender.first_name
                                        .charAt(0)
                                        .toUpperCase()
                                    : ""}
                                </div>
                              )
                            )}
                          </span>

                          <span className="first-name pe-1">by</span>
                          <span className="pe-2 first-name">
                            {item.sender.first_name} {item.sender.last_name}
                          </span>
                          <span className="first-name">
                            {/* {calculateTimeDifference(item.updated_at)} */}
                          </span>

                          {item.group && (
                            <>
                              {item.post ? (
                                <>
                                  <span className="d-block text-muted home-font1 text-wrap">
                                    Home {`>`}
                                    <Link to="/main-todo">
                                      <span>To-do</span>
                                    </Link>
                                    {`>`}
                                    {item.group && (
                                      <>
                                        <Link
                                          to={`/todo-list?status=still_working&group_id=${item.group.id}`}
                                          onClick={() =>
                                            setGroupName(item.group.name)
                                          }
                                        >
                                          <span>{item.group.name}</span>
                                        </Link>
                                        {`>`}
                                      </>
                                    )}
                                    <Link
                                      to={`/todo-list/${item.post.id}?status=still_working`}
                                    >
                                      <span onClick={() =>
                                          setGroupName(item.group.name)
                                        }>
                                        {item.post.notes}
                                      </span>
                                    </Link>
                                  </span>
                                </>
                              ) : (
                                <span className="d-block text-muted home-font1">
                                  Home {`>`}
                                  <Link to="/main-todo">
                                    <span>To-do</span>
                                  </Link>
                                  {`>`}
                                  {item.group && (
                                    <>
                                      <Link
                                        to={`/todo-list?status=still_working&group_id=${item.group.id}`}
                                        onClick={() =>
                                          setGroupName(item.group.name)
                                        }
                                      >
                                        <span>{item.group.name}</span>
                                      </Link>
                                      {`>`}
                                    </>
                                  )}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        {/* </div> */}
                        <div
                          className={` ${
                            String(mainUser) === String(item.sender.id)
                              ? "blueBackground2"
                              : "greenBackground2"
                          }`}
                        ></div>
                      </>
                    )}
                  </div>
                ))}
              {loading && (
                <p className="text-center text-muted">
                  <ChatWindowStatic />
                </p>
              )}
            </InfiniteScroll>
          ) : showConversations && showConversations.length < 0 ? (
            <p className="d-flex align-items-center justify-content-center">
              No conversations found start a chat
            </p>
          ) : (
            <p className="text-center text-muted">
              <ChatWindowStatic />
            </p>
          )}
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            className="d-flex align-items-center justify-content-center"
            backdrop="static" // Add backdrop="static" to prevent closing modal on clicking outside
            backdropClassName="modal-backdrop"
          >
            <Modal.Header className="fs-1 fw-bold d-flex justify-content-center text-center border-0">
              <i
                className="bi bi-exclamation-circle text-center text-danger modal-icon"
                // style={{
                //   fontSize: "85px",
                //   color: "#f8bb86 !important",
                // }}
              ></i>
            </Modal.Header>
            <Modal.Body className="">
              Are You Sure You Want to Delete this?
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
              <Button variant="primary" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="primary" onClick={handleCloseModal}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <form
            className="submit_form ps-lg-5 ps-4 pe-4 py-3"
            id="AddMessage"
            method="post"
            encType="multipart/form-data"
            autoComplete="off"
            onSubmit={handleChatSubmit}
          >
            <input type="hidden" name="_token" autoComplete="off" />
            <input type="hidden" name="receiver_id" />
            <input type="hidden" name="group_id" />
            <div className="input-group ">
              <label
                htmlFor="mediaFile"
                title="Upload image or video"
                className="cursor-pointer pt-1"
                // style={{ cursor: "pointer" }}
              >
                {(thumbnail || file) && (
                  <ImagePreview
                    file={thumbnail || file}
                    onDelete={handleFileDelete}
                  />
                )}
                <img
                  src="https://beta.yesdone.com/build/assets/dummyimg-68fadc9e.png"
                  className="dummyIcon"
                  alt=""
                  height="30px"
                />
                <input
                  type="file"
                  className="custom-file-input display-input"
                  id="mediaFile"
                  name="files"
                  accept="image/*, video/*"
                  onChange={handleFileChange}
                  // style={{ display: "none" }} // hide the input field visually
                />
              </label>

              <textarea
                type="text"
                className="form-control border-0 re-size shadow-none"
                required
                name="title"
                autocomplete="off"
                placeholder="Enter your message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  handleInputChange(e);
                }}
              />
              <button type="submit" className="btn p-0">
                <img
                  src="https://beta.yesdone.com/build/assets/send-99cff475.png"
                  alt=""
                  height="30px"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
// import React, { useEffect, useRef, useContext, useState, useCallback } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import UserContext from "../AuthContext/ChatContext";
// import "./Chat.css";
// import axios from "axios";

// const ChatWindow = ({ fetchDataAndUpdateState }) => {
//   const { selectedUserId1 } = useContext(UserContext);
//   const [message, setMessage] = useState("");

//   const dispatch = useDispatch();
//   const selectedConversation = useSelector(
//     (state) => state.reducer.selectedConversation
//   );
//   const selectedUserId = useSelector((state) => state.reducer.selectedUserId);
//   const selectedUserType = useSelector(
//     (state) => state.reducer.selectedUserType
//   );
//   const showConversations = useSelector(
//     (state) => state.reducer.showConversations
//   );
//   const loading = useSelector((state) => state.reducer.loading);
//   const mainUser = localStorage.getItem("user_id");
//   const chatContainerRef = useRef(null);
//   const fetchAdditionalData = useCallback(async (page = 1) => {
//     dispatch({ type: "SET_LOADING", payload: true });
//     try {
//       const storedToken = localStorage.getItem("token");
//       let apiUrlPath = "";

//       if (selectedUserId1) {
//         apiUrlPath = `https://beta.yesdone.com/api/v1/messages/${selectedUserId1.id}?type_of=single&page=${page}`;
//       } else {
//         apiUrlPath = `https://beta.yesdone.com/api/v1/messages/${selectedUserId}?type_of=${selectedUserType}&page=${page}`;
//       }

//       const response = await fetch(apiUrlPath, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         if (response.status === 404) {
//           // Clearing the loading state only if no conversations are found
//           dispatch({ type: "SET_SHOW_CONVERSATIONS", payload: [] });
//         } else {
//           throw new Error("Network response was not ok");
//         }
//       } else {
//         const result = await response.json();
//         const newConversations = result.data.conversation;

//         // If it's page one, clear existing conversations before appending new ones
//         if (page === 1) {
//           dispatch({
//             type: "SET_SHOW_CONVERSATIONS",
//             payload: newConversations,
//           });
//         } else {
//           // Append the new conversations to the existing ones
//           const existingConversations = [...showConversations];
//           existingConversations.push(...newConversations);

//           dispatch({
//             type: "SET_SHOW_CONVERSATIONS",
//             payload: existingConversations,
//           });
//         }
//       }

//       dispatch({ type: "SET_LOADING", payload: false });
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }, [dispatch, selectedUserId, selectedUserId1, selectedUserType, showConversations]);

//   const handleChatSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const storedToken = localStorage.getItem("token");

//       const requestBody = {
//         content: message,
//         media: [],
//       };

//       if (selectedUserId1) {
//         requestBody.receiver_id = selectedUserId1.id;
//       } else if (selectedUserId && selectedUserType === "single") {
//         requestBody.receiver_id = selectedUserId;
//       } else if (selectedUserType === "group") {
//         requestBody.group_id = selectedUserId;
//       }

//       const response = await fetch(`https://beta.yesdone.com/api/v1/messages`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestBody),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       setMessage("");
//       fetchDataAndUpdateState();
//       fetchAdditionalData(1);
//     } catch (error) {
//       console.error("Error posting message:", error);
//     }
//   }

//   const handleDelete = async (id) => {
//     try {
//       const token = localStorage.getItem("token");

//       await axios.delete(
//         `https://beta.yesdone.com/api/v1/messages/${id}`,
//         {
//           headers: {
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       fetchAdditionalData(1);
//       fetchDataAndUpdateState();
//     } catch (error) {
//       console.error("Error deleting:", error);
//     }
//   };

//   useEffect(() => {
//     const chatContainer = chatContainerRef.current;
//     const handleScroll = () => {
//       if (chatContainer.scrollTop === 0 && !loading) {
//         fetchAdditionalData(2);
//       }
//     };
//     chatContainer.addEventListener("scroll", handleScroll);
//     return () => {
//       chatContainer.removeEventListener("scroll", handleScroll);
//     };
//   }, [loading,fetchAdditionalData,]);

//   useEffect(() => {
//     if (chatContainerRef.current && Array.isArray(showConversations)) {
//       chatContainerRef.current.scrollTop =
//         chatContainerRef.current.scrollHeight;
//     }
//   }, [showConversations]);

//   // Effect to fetch initial data when component mounts or selected user changes
//   useEffect(() => {
//     // Clear the showConversations state when selectedUserId, selectedUserId1, or selectedUserType changes
//     dispatch({ type: "SET_SHOW_CONVERSATIONS", payload: [] });

//     // Fetch additional data if a selected user exists
//     if ((selectedUserId && selectedUserType) || selectedUserId1) {
//       fetchAdditionalData(1);
//     }
//   }, [dispatch, selectedUserId, selectedUserId1, selectedUserType]);

//   return (
//     <>
//       {selectedUserId1 ? (
//         <div className="d-flex align-items-center">
//           {" "}
//           {selectedUserId1 && selectedUserId1.profile_image ? (
//             <img
//               src={selectedUserId1.profile_image}
//               className="rounded-circle"
//               alt="Profile"
//               width="60px"
//               height="60px"
//             />
//           ) : (
//             <div className="profile">
//               {selectedUserId1 && selectedUserId1.first_name
//                 ? selectedUserId1.first_name.charAt(0).toUpperCase()
//                 : "N"}
//             </div>
//           )}
//           <span className="ps-3">
//             {selectedUserId1.first_name} {selectedUserId1.last_name}
//           </span>
//         </div>
//       ) : (
//         <>
//           {selectedConversation && (
//             <div className="d-flex align-items-center">
//               <div className="col-1">
//                 {selectedConversation.type_of === "single" ? (
//                   <>
//                     {/* Conditional rendering for profile image or first letter */}

//                     {selectedConversation.other_user &&
//                     selectedConversation.other_user.profile_image ? (
//                       <img
//                         src={selectedConversation.other_user.profile_image}
//                         className="rounded-circle"
//                         alt="Profile"
//                         width="60px"
//                         height="60px"
//                       />
//                     ) : (
//                       <div className="profile">
//                         {selectedConversation.other_user &&
//                         selectedConversation.other_user.first_name
//                           ? selectedConversation.other_user.first_name
//                               .charAt(0)
//                               .toUpperCase()
//                           : "N"}
//                       </div>
//                     )}
//                   </>
//                 ) : (
//                   // Render static icon for group
//                   <i className="bi bi-people-fill  rounded-icon"></i>
//                 )}
//               </div>
//               <div className="col-6 d-flex">
//                 <div>
//                   {selectedConversation.type_of !== "group" && (
//                     <span>
//                       {selectedConversation.other_user?.first_name ?? "null"}{" "}
//                       {selectedConversation.other_user?.last_name ?? "null"}
//                     </span>
//                   )}
//                   <span className="text-muted fs-4">
//                     {selectedConversation.group?.name}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </>
//       )}
//       <div>
//         <div className="scrollable-list" ref={chatContainerRef}>
//           {loading ? (
//             <p>Loading...</p>
//           ) : showConversations && showConversations.length > 0 ? (
//             <>
//               {showConversations
//                 .slice()
//                 .reverse()
//                 .map((item, index) => (
//                   <div key={index+34} className="containers commonBorderTop">
//                     <div
//                       className={` ${
//                         String(mainUser) === String(item.sender.id)
//                           ? item.content && item.content.length > 35
//                             ? "blueBackgroundLong d-flex justify-content-between"
//                             : "blueBackgroundShort d-flex justify-content-between"
//                           : item.content && item.content.length > 35
//                           ? "greenBackgroundLong d-flex justify-content-between"
//                           : "greenBackgroundShort d-flex justify-content-between"
//                       }`}
//                     >
//                       <>
//                         <span className="col-9"> {item.content}</span>
//                         <span className="col-3 text-end">
//                           {String(mainUser) === String(item.sender.id) && (
//                             <>
//                               <i
//                                 className="bi bi-x-lg "
//                                  onClick={() => handleDelete(item.id)}
//                               ></i>
//                             </>
//                           )}
//                         </span>
//                       </>
//                     </div>
//                     <div
//                       className={`info-container ${
//                         String(mainUser) === String(item.sender.id)
//                           ? "blueBackground1"
//                           : "greenBackground1"
//                       }`}
//                     >
//                       <span className="pe-2">
//                         {item.sender && item.sender.profile_image ? (
//                           <img
//                             src={item.sender.profile_image}
//                             alt=""
//                             className="profile1"
//                           />
//                         ) : (
//                           item.sender && (
//                             <div className="profiles1">
//                               {item.sender && item.sender.first_name
//                                 ? item.sender.first_name.charAt(0).toUpperCase()
//                                 : ""}
//                             </div>
//                           )
//                         )}
//                       </span>

//                       <span className="first-name">by</span>
//                       <span className="pe-2 first-name">
//                         {item.sender.first_name} {item.sender.last_name}
//                       </span>
//                       <span className="first-name">
//                         {/* {calculateTimeDifference(item.updated_at)} */}
//                       </span>

//                       {item.group && (
//                         <>
//                           {item.post ? (
//                             <>
//                               <span className="d-block text-muted">
//                                 Home {`>`} Todo {`>`}
//                                 {item.group && (
//                                   <>
//                                     {item.group.name}
//                                     {`>`}
//                                   </>
//                                 )}
//                                 {item.post.notes}
//                               </span>
//                             </>
//                           ) : (
//                             <span className="d-block text-muted">
//                               Home {`>`} Todo {`>`}
//                               {item.group && <>{item.group.name}</>}
//                             </span>
//                           )}
//                         </>
//                       )}
//                     </div>

//                     <div
//                       className={`info-container ${
//                         String(mainUser) === String(item.sender.id)
//                           ? "blueBackground2"
//                           : "greenBackground2"
//                       }`}
//                     >
//                       {item.media.map((media, index) => (
//                         <div key={index+index} className="media-container">
//                           {media.type === "image" ? (
//                             <img
//                               src={media.url}
//                               width="300px"
//                               height="300px"
//                               alt={item.id}
//                             />
//                           ) : media.type === "video" ? (
//                             <video width="300px" height="300px" controls>
//                               <source src={media.url} type="video/mp4" />
//                               Your browser does not support the video tag.
//                             </video>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 ))}
//             </>
//           ) : (
//             // Render a message when no conversations are found
//             <p>No conversations found.</p>
//           )}
//         </div>
//         <div>
//         <form
//       className="submit_form ps-lg-5 ps-4 pe-4 py-3"
//       id="AddMessage"
//       method="post"
//       encType="multipart/form-data"
//       autoComplete="off"
//       onSubmit={handleChatSubmit}
//     >
//       <input type="hidden" name="_token"  autoComplete="off" />
//       <input type="hidden" name="receiver_id" />
//       <input type="hidden" name="group_id" />
//       <div className="input-group align-items-center">
//         <div className="image_view"></div>
//         <label htmlFor="galleryFile" title="upload image" style={{ cursor: 'pointer' }}>
//           <img src="https://beta.yesdone.com/build/assets/dummyimg-68fadc9e.png" className="dummyIcon" alt="" height="30px" />
//           <input type="file" id="galleryFile" name="files" style={{ display: 'none' }} accept="video/*,image/*" />
//         </label>
//         <input type="hidden" name="type" value="" id="typeTask" />
//         <input type="hidden" name="url" value="" id="imageTask" />
//         <input
//           type="text"
//           className="form-control border-0"
//           name="content"
//           placeholder="Type a Message"
//           value={message}
//           onChange={(e)=>setMessage(e.target.value)}
//         />
//         <button type="submit" className="btn p-0">
//           <img src="https://beta.yesdone.com/build/assets/send-99cff475.png" alt="" height="30px" />
//         </button>
//       </div>
//     </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChatWindow;
