// FilterDataContext.js
import { faL } from '@fortawesome/free-solid-svg-icons';
import React, { createContext, useContext, useState } from 'react';

const FilterDataContext = createContext();

export const useFilterData = () => useContext(FilterDataContext);

export const FilterDataProvider = ({ children }) => {
  const [filterData, setFilterData] = useState([]);
  const [loading,setLoading]=useState(true);

  return (
    <FilterDataContext.Provider value={{ filterData, setFilterData,loading,setLoading }}>
      {children}
    </FilterDataContext.Provider>
  );
};
