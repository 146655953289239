import React, { createContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [selectedUserId1, setSelectedUserId1] = useState(null);
  const [groupName, setGroupName] = useState('');

  return (
    <UserContext.Provider value={{ selectedUserId1, setSelectedUserId1,groupName, setGroupName }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
