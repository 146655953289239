import React, { useState, useEffect,useContext } from "react";
import { fetchEmployeeList } from "../../Api/Employ";
import { Link} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingImg from "../../images/loading.webp";
import "./Chat.css";
import { fetchData } from "../../Api/Message";
import UserContext from '../AuthContext/ChatContext';

const Users = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { setSelectedUserId1 } = useContext(UserContext);

  const handleClick = (userId) => {
    setSelectedUserId1(userId);
  };


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const employees = await fetchEmployeeList();
      setData(employees);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  // Function to filter the data based on search term
  const filteredData = data.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="container">
        <h3 className="text-center">Employees List</h3>
        <div className="row">
          <Link to="/chat" className="col-lg-2 col-md-3">
            <i className="bi bi-chevron-left text-black fs-5"></i>
          </Link>
          <div className="col-8">
            <div className="input-group mb-1 mt-1">
              <input
                type="text"
                className="form-control ps-5 rectanglesearch cursor-point"
                placeholder="Search"
                name=""
                id="searchTodo"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="off"
              />
              <img
                src="https://beta.yesdone.com/build/assets/searchicon-534fbd0c.png"
                alt=""
                className="icon-search "
                height="15px"
                width="15px"
              ></img>
            </div>

            <div className="scrollables" id="scrollableDiv">
              {loading ? (
                <p className="text-center text-muted">
                  <img src={LoadingImg} width="35px" height="35px" />
                </p>
              ) : (
                <>
                  {filteredData.map((item, index) => (
                    <Link to={`/chat?user_id=${item.id}`} key={index} onClick={() => handleClick(item)}>
                      <div className="d-flex align-items-center border-bottom p-2">
                        <div className="profiles text-center pt-1">
                          {item.profile_image ? (
                            <img
                              src={item.profile_image}
                              alt={item.first_name}
                              width="50px"
                              height="50px"
                              className="profile"
                              onError={(e) => {
                                e.target.style.display = "none";
                                e.target.nextSibling.style.display = "inline";
                              }}
                            />
                          ) : (
                            <span className="default-avatar">
                              {item.first_name
                                ? item.first_name.charAt(0).toUpperCase()
                                : ""}
                            </span>
                          )}
                          <i
                            className="bi bi-person  display-input"
                            // style={{ display: "none" }}
                          ></i>
                        </div>

                        <div className="ps-3 text-muted">
                          {item.first_name} {item.last_name}
                        </div>
                      </div>
                    </Link>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
