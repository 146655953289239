import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "../sidebar/SideBar.css";
import "../../App.css";
import { useAuth } from "../AuthContext/AuthContext";
import { fetchFilterData } from "../../Api/UsersList";
import { useTodo } from "../AuthContext/TodoContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { genNewSearchParamString } from "../../utils/index";
const MainTodoList = () => {
    const [filterData, setFilterData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const userData = localStorage.getItem("user_id");
    let [searchParams, setSearchParams] = useSearchParams();
  
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const {
      setSelectedItem,
      setSelectedName,
      setSelectedType,
      todos,
      setFirstTodoName,
      setFirstId,
      setFirstType,
    } = useTodo();
  
    //required for params
    //  function handleFilterChange(key,value){
    //      setSearchParams(prevParams=>{
    //       if(value === null){
    //         prevParams.delete(key)}
  
    //       else {
    //         prevParams.set(key,value)
    //       }
    //       return prevParams;
    //      })
  
    //  }
  
    useEffect(() => {
      // Convert userData to a number
      const userId = Number(localStorage.getItem("user_id"));
  
      // Filter out the todos matching userData
      const filteredTodos = filterData.filter(
        (item) => Number(item.id) === userId
      );
  
      // Sort the filtered todos based on your sorting logic
      const sortedTodos = filteredTodos.slice().sort((a, b) => {
        // Sort users first, then groups alphabetically
        if (a.type_of === "user" && b.type_of === "group") {
          return -1;
        } else if (a.type_of === "group" && b.type_of === "user") {
          return 1;
        } else {
          // Sort alphabetically
          return a.name.localeCompare(b.name);
        }
      });
  
      if (sortedTodos.length > 0) {
        // If sortedTodos is not empty, set the name of the first todo
        const firstTodoName = sortedTodos[0].name;
        setFirstTodoName(firstTodoName);
        const firstId = sortedTodos[0].id;
        // setFirstId(firstId);
        const firstType = sortedTodos[0].type_of;
        // setFirstType(firstType);
        navigate(
          genNewSearchParamString(
            searchParams,
            firstType === "user" ? "user_id" : "group_id",
            firstId
          )
        );
      }
    }, [filterData, setFirstTodoName, setFirstId, setFirstType]);
    useEffect(() => {
      fetchFilterData(setFilterData, setError, setLoading);
    }, []);
    return (
        <div className="container-fluid scrollable1">
        <div className="row sidebar">
          <div className="col-lg-12 col-md-12 col-lg-12">
          <div className="pt-3 ps-5 todos-home">
        <span>Home</span>
        {" > "}
         <span>To-do</span>
         </div>
            {/* beforelogin if want to show any thing u can write code here */}
            {isLoggedIn && isLoggedIn ? (
              <>
                <div>
                  <div
                    
                  >
                    <div >
                      
                      <div
                        
                      >
                        <div className=" ">
                          <ul className="list-inline override ps-3">
                            {filterData
                              .slice()
                              .sort((a, b) => {
                                // Place the item with the brightness icon at the top
                                if (Number(a.id) === Number(userData)) {
                                  return -1;
                                } else if (Number(b.id) === Number(userData)) {
                                  return 1;
                                } else {
                                  // Sort users first, then groups alphabetically
                                  if (
                                    a.type_of === "user" &&
                                    b.type_of === "group"
                                  ) {
                                    return -1;
                                  } else if (
                                    a.type_of === "group" &&
                                    b.type_of === "user"
                                  ) {
                                    return 1;
                                  } else {
                                    // Sort alphabetically
                                    return a.name.localeCompare(b.name);
                                  }
                                }
                              })
                              .map((item) => (
                                <li
                                  key={`${item.id}${item.name}`}
                                  className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                                  onClick={() => {
                                    console.log(item.id);
                                    setSelectedItem(item.name, item.id);
                                    navigate(
                                      "/todo-list" +
                                        genNewSearchParamString(
                                          searchParams,
                                          item.type_of === "user"
                                            ? "user_id"
                                            : "group_id",
                                          item.id
                                        )
                                    );
                                  }}
                                >
                                  <div className="text-success custom-fontsize">
                                    {item.type_of === "user" ? (
                                      <i
                                        className={`bi ${
                                          Number(item.id) === Number(userData)
                                            ? "bi-brightness-high-fill text-success"
                                            : "bi-person-fill"
                                        } pe-2 mt-2`}
                                      ></i>
                                    ) : (
                                      <i className="fa fa-users pe-2"></i>
                                    )}
                                  </div>
                                  <div className="text-blue ps-2">
                                    <span>{item.name}</span>
                                    <span>-</span>
                                    <span>{item.count}</span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div></div>
          </div>
        </div>
      </div>
  )
}

export default MainTodoList