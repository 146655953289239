import React from 'react'

const EmployeesStatic = () => {
    const numberOfTimes = 15;
  return (
    <div className='container'>
    {Array.from({ length: numberOfTimes }).map((_, index) => (
    <div className='row pb-2 pt-2'>
<div className='col-6 d-flex flex-row'>
<div className='check-static me-1'></div>
   <div className='profilestatic1 mt-1'></div>
   <div className='check-static1 ms-4'></div>
</div>
<div className='col-6  d-flex flex-row'>
<div className='check-static me-1'></div>
   <div className='profilestatic1 mt-1'></div>
   <div className='check-static1 ms-4'></div>
</div>
   
 </div>
  ))}
  </div>
  )
}

export default EmployeesStatic