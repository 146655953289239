import API_BASE_URL from '../Config';

async function fetchEmployeeList() {
  try {
    const storedToken = localStorage.getItem("token"); // Retrieve token from localStorage
    const response = await fetch(`${API_BASE_URL}/api/v1/users?is_employee=1`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${storedToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized: Token expired or invalid');
      } else {
        throw new Error('Network response was not ok');
      }
    }

    const result = await response.json();
    console.log(result);
    return result.data;
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export { fetchEmployeeList };
