import React from 'react'
import "../todos/Todo.css"
const PostStatic = () => {
  const numberOfTimes = 6;
  const  numberOfTime = 10;
  return (
    <div className='container'>
      {/* <div className='row d-flex felx-column'> */}
      <div className='post1 mb-1'></div>
        <div className='post4 mb-1'>
        <div className='static-post' ></div>
        {Array.from({ length: numberOfTimes }).map((_, index) => (
    <div className='row  pb-1 pt-2'>
<div className='col-6 d-flex flex-row'>
<div className='check-static me-1'></div>
   <div className='profilestatic1 mt-1'></div>
   <div className='check-static1 ms-4'></div>
</div>
<div className='col-6  d-flex flex-row'>
<div className='check-static me-1'></div>
   <div className='profilestatic1 mt-1'></div>
   <div className='check-static1 ms-4'></div>
</div>
   
 </div>
  ))}</div>
   
      {/* </div> */}
      <div className='post5 mb-1'>
        <div className='static-post' ></div>
        {Array.from({ length: numberOfTime }).map((_, index) => (
    <div className='row  pb-1 pt-2'>
<div className='col-6 d-flex flex-row'>
<div className='check-static me-1'></div>
   <div className='profilestatic1 mt-1'></div>
   <div className='check-static1 ms-4'></div>
</div>
<div className='col-6  d-flex flex-row'>
<div className='check-static me-1'></div>
   <div className='profilestatic1 mt-1'></div>
   <div className='check-static1 ms-4'></div>
</div>
   
 </div>
  ))}</div>
     
    </div>
  )
}

export default PostStatic