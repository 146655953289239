import React from 'react'
import "../todos/Todo.css"
const GroupListStatic = () => {
  const numberOfTimes = 10;
  return (
    <div>
    {Array.from({ length: numberOfTimes }).map((_, index) => (
      <div key={index} className='container border-bottom pb-3'>
        <div className='groupstatic mt-2 mb-2'></div>
        <div className='groupstatic1'></div>
      </div>
    ))}
  </div>

  )
}

export default GroupListStatic