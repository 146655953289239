import React, { createContext, useContext, useState } from "react";

const TodoContext = createContext();

export const TodoProvider = ({ children }) => {
  const [selectedItemId, setSelectedItemId] = useState(
    localStorage.getItem("selectedItemId") || null
  );
  const [selectedItemName, setSelectedItemName] = useState(
    localStorage.getItem("selectedItemName") || null
  );
   //const [selectedItemType, setSelectedItemType] = useState(null);
  const [firstTodoName, setFirstTodoName] = useState(null);
   const [firstId, setFirstId] = useState(null);
   const [firstType, setFirstType] = useState(null);
 const [sortByNotes, setSortByNotes] = useState(
  localStorage.getItem("sortByNotes") || null
 );
   const setSelectedItem = (itemName,itemId,type,notes) => {
    setSelectedItemId(itemId);
     setSelectedItemName(itemName);
     setSortByNotes(notes)
  //   setSelectedItemType(itemType);
  localStorage.setItem("selectedItemId", itemId);
  localStorage.setItem("selectedItemName", itemName);
  localStorage.setItem("sortByNotes", notes);
  localStorage.setItem("selectedType",type)
     };

  return (
    <TodoContext.Provider
      value={{
        selectedItemId,
         setSelectedItem,
         selectedItemName,
         setSelectedItemName,
         setSortByNotes,
         sortByNotes,
        // selectedItemType,
        // todos,
        firstTodoName,
        setFirstTodoName,
        // id,
         firstId,
         setFirstId,
        // type,
         firstType,
         setFirstType,
      }}
    >
      {children}
    </TodoContext.Provider>
  );
};

export const useTodo = () => {
  return useContext(TodoContext);
};