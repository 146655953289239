import React from "react";

const ImagePreviews = ({ file, thumbnailUrl, onDelete }) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {file && file.type.startsWith("image") && (
        <>
          <img
            src={URL.createObjectURL(file)}
            alt="Preview"
            style={{ width: "45px", height: "45px" }} // Set width and height inline styles
          />
          <i
            className="fa fa-times text-dark small"
            aria-hidden="true"
            onClick={onDelete}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "red",
              border: "none",
              backgroundColor: "white",
              cursor: "pointer",
            }}
          ></i>
        </>
      )}
      {file && file.type.startsWith("video") && thumbnailUrl && (
        <>
          <img
            src={thumbnailUrl}
            alt="Thumbnail"
            style={{ width: "45px", height: "45px" }} // Set width and height inline styles
          />
          <i
            className="fa fa-times text-dark small"
            aria-hidden="true"
            onClick={onDelete}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "red",
              border: "none",
              backgroundColor: "white",
              cursor: "pointer",
            }}
          ></i>
        </>
      )}
    </div>
  );
};

export default ImagePreviews;
