import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImage from '../../images/app_logo-02d8f0cb.png';
import LoadingImg from "../../images/loading.webp";
import './Login.css';
import { loginUser } from '../../Api/Authentication';
import { useAuth } from "../AuthContext/AuthContext";
import { fetchUserData } from "../../Api/User";
import { fetchData } from "../../Api/Message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import { useFilterData } from '../AuthContext/SidebarContext';
import { fetchFilterData } from "../../Api/UsersList";
function Login() {
  const { filterData, setFilterData,loading,setLoading } = useFilterData();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
 
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const responseData = await loginUser(email, password);

      if (responseData.status === 200 && responseData?.data && responseData.data.access_token) {
        const token = responseData.data.access_token;
        localStorage.setItem('token', token);
        
        const response = await fetchUserData();
        if (response && response.data && response.data.profile_image) {
          localStorage.setItem("profile_Details",JSON.stringify(response.data))
         const profileImage = response.data.profile_image;
          localStorage.setItem("profile", profileImage);
          // console.log("User data:", response);
        } else {
          console.error("Failed to fetch user data");
        }
         const response1 = await fetchData();

        if (response1 && response1.data ) {
          console.log("conversation data:", response1.data.conversations);
          const conversations =  response1.data.conversations;
          const totalUnreadCount = conversations.reduce((total, conversation) => {
            return total + conversation.unread_count;
          }, 0); 
          localStorage.setItem("notification_count",totalUnreadCount)
        }
        login();
        fetchFilterData(setFilterData,setLoading);
        // navigate('/todo-list?status=still_working&project_type=to-do');
        navigate('/todo-list');
          // window.location.reload()
      } else {
        setLoginError('These credentials do not match our records.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('An error occurred during login.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && email && password) {
        event.preventDefault();
        handleLogin();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [email, password,handleLogin]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className='row'>
          <div className='col-12 login'>
            <div className="card login-card  " >
              <img src={loginImage} className="card-img-top cardimage mx-auto py-5" width="170px" alt="yesdonelogo" />
              <div className="card-body text-center px-5">
                <h5 className="card-title fw-medium fs-6 text-start">Email Address</h5>
                <form onSubmit={handleSubmit}>
                  <input
                    type='email'
                    placeholder='Email Address'
                    className='form-card '
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {loginError && <div className="text-danger fs-6 mb-3">{loginError}</div>}
                  <input
                    type='password'
                    placeholder='Password'
                    className='form-card my-2'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {isLoading ? (
                    <button className='btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2' disabled>
                      <img src={LoadingImg} width="20px" height="20px" alt="Loading" />
                    </button>
                  ) : (
                    <button type='submit' className='btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2'>
                      Log in
                    </button>
                  )}
                   <Link to="/password/reset">
                  <button className='btn btn-link text-decoration-none text-dark login-password'>Forgot Your Password?</button>
                  </Link>
                  <ToastContainer
                    position="top-center"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;
