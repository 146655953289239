import React,{useState} from 'react'
import { useAuth } from "../AuthContext/AuthContext";
const ChangePassWord = () => {
    const { isLoggedIn, login, logout } = useAuth();
   
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError]=useState(false)
  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if new password matches the confirm password
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password don't match!");
      return;
    }
    // Here you can proceed with any further logic, like sending the data to a server
    console.log('Form submitted:', { oldPassword, newPassword, confirmPassword });
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError("")
  };
  const togglePasswordVisibility = (type) => {
    switch (type) {
      case 'old':
        setShowOldPassword(!showOldPassword);
        break;
      case 'new':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirm':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };
  return (
   <>
   


<section>
      <div className="container-fluid">
        <div className="container-fluid py-6">
          <div className="col-md-6 offset-md-3 pt-4">
            <h3 className="text-center">Change Password</h3>
            <form className="form" onSubmit={handleSubmit}>
              <input type="hidden" name="_token" value="LJUNXDAOUwZ6ZYWJq9bD6UqBqCSOlWeJ3qLVn372" autoComplete="off" />
              <div className="card-body pt-3">
                <div className="mb-3">
                  <label htmlFor="current_password" className="form-label">Current Password</label>
                  <div className="input-group">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className="form-control"
                      id="current_password"
                      name="current_password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      
                      required
                    />
                    <span
                      onClick={() => togglePasswordVisibility('old')} className="eye-position"
                    >
                      <i className={showOldPassword ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="new_password" className="form-label">New Password</label>
                  <div className="input-group">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="form-control"
                      id="new_password"
                      name="new_password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <span
                      onClick={() => togglePasswordVisibility('new')} className="eye-position"
                    >
                      <i className={showNewPassword ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="new_password_confirmation" className="form-label">Confirm New Password</label>
                  <div className="input-group">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control"
                      id="new_password_confirmation"
                      name="new_password_confirmation"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <span
                      onClick={() => togglePasswordVisibility('confirm')} className="eye-position"
                    >
                      <i className={showConfirmPassword ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                    </span>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary text-center">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
   </>
  )
}

export default ChangePassWord