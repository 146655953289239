import React from 'react'
import Header from "../header/Header";
import SideBar from '../sidebar/SideBar';
import { Outlet } from 'react-router-dom';
const Layout = () => {
  

  return (
    <div>
     <Header/>
     <div className="row">
        <div className='col-lg-2 col-md-3 col-sm-3 side-bar'> 
        <SideBar/>
        </div>
        <div className='col-lg-10 col-md-9 col-sm-9'>
      <Outlet/>
        </div>
      </div>
    </div>
  )
}

export default Layout 
// import React, { useState, useEffect } from 'react';
// import Header from '../header/Header';
// import SideBar from '../sidebar/SideBar';
// import { Outlet } from 'react-router-dom';

// const Layout = () => {
//   const [showSidebar, setShowSidebar] = useState(true); // Initially show the sidebar

//   useEffect(() => {
//     const handleResize = () => {
//       // Hide sidebar on mobile screens (less than 768px width)
//       setShowSidebar(window.innerWidth >= 768);
//     };

//     // Add event listener for window resize
//     window.addEventListener('resize', handleResize);

//     // Remove event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []); // Empty dependency array to run effect only once

//   const toggleSidebar = () => {
//     if (window.innerWidth < 768) {
//       setShowSidebar(!showSidebar);
//     }
//   };
  

//   return (
//     <div>
//       <Header toggleSidebar={toggleSidebar} /> {/* Pass the toggle function */}
//       <div className="row">
//         <div className={`col-lg-2 col-md-3 col-sm-3 ${showSidebar ? 'show' : 'hide'}`}>
//           {showSidebar && <SideBar />}
//         </div>
//         <div className='col-lg-10 col-md-9 col-sm-9'>
//           <Outlet />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Layout;



