import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { calculateTimeDifference, formatRelativeTime } from "../../utils/index";
import { calculateTimeDifferenceChat1,formatDate } from "../../utils/index";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTodoData } from "../AuthContext/TodoDataContext";
import axios from "axios"; // Import axios for making HTTP requests
import "./MiniComments.css";
import Tippy from "@tippyjs/react";
import API_BASE_URL from "../../Config.js";
import { Modal, Button } from "react-bootstrap";
import ImagePreview from "../../utils/image-preview";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingImg from "../../images/loading.webp";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
} from "../../utils/index";
// import { Link } from "react-router-dom";
import MinicommentsStatic from "./MinicommentsStatic.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatWindowStatic from "../chat/ChatWindowStatic.jsx";
import { useAuth } from "../AuthContext/AuthContext";
import UserContext from "../AuthContext/ChatContext";
import { useTodo } from "../AuthContext/TodoContext";
import { useSearchParams } from "react-router-dom"; // Import the fetchPostData function
import { genNewSearchParamString } from "../../utils";
const MiniComments = () => {
  const { postValue, todoid } = useParams();
  const [date, setDate] = useState("");
  const { todoData } = useTodoData();
  const postId = localStorage.getItem("postId");
  const [replies, setReplies] = useState([]);
  const [todos, setTodos] = useState([]);
  const [post, setPost] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [miniComment, setMiniComment] = useState("");
  const [open, setOpen] = useState(false);
  const [isTodo, setIsTodo] = useState(false);
  const [miniEdit, setMiniEdit] = useState(null);
  const [miniTodoId, setMiniTodoId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const [showAddTaskPartId, setShowAddTaskPartId] = useState(false);
  const [delmodalopen, setDelModalOpen] = useState(false);
  const [delMiniTodoParams, setDelMiniTodoParams] = useState(null);
  const [copytextId, setCopyTextId] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const storedToken = localStorage.getItem("token");
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [test, setTest] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(2);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [commentValue, setCommentValue] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const { isLoggedIn, login, logout } = useAuth();
  const [lastPage, setLastPage] = useState(false);
  const { groupName } = useContext(UserContext);
  const selectedType = localStorage.getItem("selectedType");
  const selectedItemId1 = localStorage.getItem("selectedItemId");
  const firstId1 = localStorage.getItem("firstId");
  const firstType1 = localStorage.getItem("firstType");
  const [commentType, setCommentType] = useState("");
  const [todoStatus, setTodoStatus] = useState("");
  const [previousTodoId, setPreviousTodoId] = useState(null);
  const [nextTodoId, setNextTodoId] = useState(null);
  const [showDoneModalId, setShowDoneModalId] = useState(null);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [checkdoneId, setCheckDoneId] = useState(null);
  const toastRef = useRef();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedItemId,
    selectedItemName,
    selectedItemType,
    firstTodoName,
    firstId,
    firstType,
  } = useTodo();
  const idToUse = selectedItemId1 ? selectedItemId1 : firstId1;
  const typeToUse = selectedType ? selectedType : firstType1;

  const to =
    "/todo-list" +
    genNewSearchParamString(
      searchParams,
      typeToUse === "user" ? "user_id" : "group_id",
      idToUse
    );

  const playVideo = (videoId) => {
    if (currentVideo && currentVideo !== videoId) {
      // Pause the currently playing video if another video is clicked
      const currentVideoElement = document.getElementById(currentVideo);
      if (currentVideoElement) {
        currentVideoElement.pause();
      }
    }
    setCurrentVideo(videoId);
  };
  const handlePlay = (event) => {
    const videoId = event.target?.id;
    playVideo(videoId);
  };
  const userId = localStorage.getItem("user_id");
  console.log("userid", userId);
  const opendeletemodal = (postId, todoid, replyId) => {
    setDelModalOpen(true);
    setDelMiniTodoParams({ postId, todoid, replyId });
  };
  const closedeletemodal = () => {
    setDelModalOpen(false);
    setMenuOpen(false);
  };

  const MAX_WORDS = 30; // Define the maximum number of words

  // Function to count words
  const countWords = (str) => {
    return str.split(/\s+/).length;
  };

  // Determine whether to add a class based on the number of words
  const shouldWrap = (comment) => {
    return countWords(comment) > MAX_WORDS;
  };

  const handletextcopy = (text, commentId) => {
    // setCopyComment(false);
    setCopyTextId(commentId);
    navigator.clipboard.writeText(text);
    setIsCopied(true);

    // Create a temporary element and append a text node with the selected text
    const tempElement = document.createElement("div");
    const textNode = document.createTextNode(text);
    tempElement.appendChild(textNode);

    // Create a range for the selected text
    const range = document.createRange();
    range.selectNodeContents(tempElement);

    // Remove existing selections
    window.getSelection().removeAllRanges();

    // Add the new range to the selection
    window.getSelection().addRange(range);
  };
  const handleTextClick = () => {
    // Reset the clicked comment immediately when clicking on the selected text
    setCopyTextId(null);
  };
  // const postsData=todoData.posts;
  const postsData = todoData.posts.find(
    (todo) => Number(todo?.id) === Number(todoid)
  );
  // console.log("postData", postsData);
  console.log("postData", currentPage);
  const handleMenuOpen = (id) => {
    setMenuOpen(!menuOpen);
    setShowAddTaskPartId(id);
  };
  //getting replys
  const fetchData = async () => {
    try {
      setLoader(true);
      const apiUrl = `${API_BASE_URL}/api/v1/posts/${postValue}/todos/${todoid}/replies?page=${1}&per_page=10`;
      const response = await axios.get(apiUrl);
      console.log("response:", response?.data?.data?.replies || []);
      setTodoStatus(response.data.data?.todo?.status);
      setCommentType(response?.data?.data?.todo?.type_of);
      setDate(response.data.data.todo.updated_at);
      // console.log(date);
      // console.log("API response:", response.data.data.pagination);
      setLastPage(
        response?.data?.data?.pagination?.current_page <
          response?.data?.data?.pagination?.last_page
      );
      setTodos(response.data.data.post);
      // console.log("Todos:", response.data.data.todo); // Add this line to console the setTodos response
      setPost(response.data.data.todo);
      // console.log("response in replies", response);
      // If it's the first page, set comments directly
      setReplies(response?.data?.data?.replies || []);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  };

  const fetchReplys = async () => {
    try {
      if (lastPage) {
        setLoader1(true);
      }
      const apiUrl = `${API_BASE_URL}/api/v1/posts/${postValue}/todos/${todoid}/replies?page=${currentPage}&per_page=10`;
      const response = await axios.get(apiUrl);
      console.log("Response:", response.data.data);
      console.log("API response:", response.data.data.pagination);
      // console.log("RESPONSE",response);
      // If it's not the first page, concatenate comments
      setReplies((prevComments) => [
        ...prevComments,
        ...response.data.data.replies,
      ]);
      setPreviousTodoId(response.data.data.previous_todo_id);
      console.log("prev", response.data.data.previous_todo_id);

      setNextTodoId(response.data.data.next_todo_id);

      setHasMore(
        response?.data?.data?.pagination?.current_page <=
          response?.data?.data?.pagination?.last_page
      );
      setCurrentPage(response?.data?.data?.pagination?.current_page + 1);
      setLoader1(false);
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  };
  useEffect(() => {
    fetchTodoIds(todoid);
  }, [todoid]); // Call fetchTodoIds whenever todoid changes

  const fetchTodoIds = async (currentTodoId) => {
    try {
      setLoader1(true);
      const apiUrl = `${API_BASE_URL}/api/v1/posts/${postId}/todos/${currentTodoId}/replies?page=${currentPage}&per_page=10`;
      const response = await axios.get(apiUrl);
      console.log("ResponseofTodoID:", response.data.data);
      console.log("API response:", response.data.data.pagination);

      // Set previous and next todo IDs
      const prevId = response.data.data.previous_todo_id;
      const nextId = response.data.data.next_todo_id;

      setPreviousTodoId(prevId);
      console.log("prev", prevId);
      setNextTodoId(nextId);
      console.log("next", nextId);

      setLoader1(false);
    } catch (error) {
      console.error("Error fetching replies:", error);
      setLoader1(false);
    }
  };

  const handlePreviousClick = async () => {
    if (previousTodoId !== null) {
      navigate(`/posts/${postId}/${previousTodoId}/todo-comments`);
      fetchTodoIds(previousTodoId); // Call API to get details of the previous todo
    }
  };

  const handleNextClick = async () => {
    if (nextTodoId !== null) {
      navigate(`/posts/${postId}/${nextTodoId}/todo-comments`);
      fetchTodoIds(nextTodoId); // Call API to get details of the next todo
    }
  };
  //adding replies

  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };
  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };

  const handleMiniSubmit = async (event, postId, todoId) => {
    event.preventDefault();

    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;
      let title = event.target.title.value;

      // Check if the user has uploaded an image or video
      if (file) {
        const imgVideoUrl = await uploadFileToS3(file);
        const fileType = file.type.startsWith("image") ? "image" : "video";
        bodyData = {
          type_of: `task_${fileType}`, // Set type_of to task_image for images and task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
        };
      } else if (thumbnail) {
        const imgVideoUrl = await uploadFileToS3(thumbnail);
        bodyData = {
          type_of: "task_video", // Set type_of to task_video for videos
          comment: `${imgVideoUrl}|&|${title || ""}`,
        };
      } else {
        // Default case: text only
        bodyData = {
          type_of: "task", // Set type_of to task for text comments
          comment: title,
        };
      }

      const response = await fetch(
        `${API_BASE_URL}/api/v1/posts/${postId}/todos/${todoId}/replies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (response.ok) {
        // Handle success
        setTest("");
        setFile(null);
        setThumbnail(null);
        const responseData = await response.json(); // Parse response body
        console.log(responseData.data);
        setReplies((prevReplies) => [responseData.data, ...prevReplies]);

        // fetchData();
      } else {
        // Handle errors
        console.error("Failed to add comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handletextChange = (event) => {
    setMiniComment(event.target.value);
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleCheckboxChange = (event) => {
    setIsTodo(event.target.checked);
  };

  //updating replies
  // const handleMiniEdit = (id, comment, type) => {
  //   setMiniEdit(true);
  //   setMiniTodoId(id);

  //   if (type === "task" || type === "text") {
  //     setInputValue(comment);
  //   } else {
  //     setInputValue(comment.split("|&|")[1]);
  //   }
  //   setCommentValue(comment);
  // };
  const handleMiniEdit = (id, comment) => {
    setMiniEdit(true);
    setMiniTodoId(id);

    // Assuming 'comment' is a string in the format: "commentText|&|additionalInfo"
    const commentParts = comment.split("|&|");
    const inputValueToUpdate =
      commentParts.length > 1 ? commentParts[1] : comment;

    setInputValue(inputValueToUpdate);
    setCommentValue(comment);
  };

  const handleCloseEdit = () => {
    // setEditing(false);
    setMiniEdit(false);
    setMenuOpen(false);
  };

  const handleMiniUpdate = async (postId, todoid, replyId, type) => {
    let updatedComment;
    if (type === "task" || type === "text") {
      updatedComment = inputValue;
    } else {
      updatedComment = `${commentValue.split("|&|")[0]}|&|${inputValue}`;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/v1/posts/${postId}/todos/${todoid}/replies/${replyId}`,
        {
          method: "PUT", // Change the method to PUT for updating
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify({
            type_of: type,
            comment: updatedComment,
            status: "still_working",
          }),
        }
      );

      if (response.ok) {
        console.log("Comment updated successfully");
        const responseData = await response.json();
        console.log("update resp", responseData.data);

        // Update the replies array with the updated reply in the UI
        setReplies((prevReplies) =>
          prevReplies.map((reply) =>
            reply.id === replyId ? responseData.data : reply
          )
        );
      } else {
        console.error("Failed to update comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }

    setMiniEdit(false);
    setMenuOpen(false);
  };

  //deleting replies
  const handleDelMinitodo = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      const response = await axios.delete(
        `${API_BASE_URL}/api/v1/posts/${delMiniTodoParams.postId}/todos/${delMiniTodoParams.todoid}/replies/${delMiniTodoParams.replyId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data); // Response data from the server
        const updatedReplies = replies.filter(
          (reply) => reply.id !== delMiniTodoParams.replyId
        );

        // Update the 'replies' state with the updated array
        setReplies(updatedReplies);
        // fetchData();
        closedeletemodal();
      } else {
        console.error("Failed to delete mini-todo", response.statusText);
      }
    } catch (error) {
      console.error("Error while deleting mini-todo:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [todoid, postId]);

  const handleClickDone = (id) => {
    setShowDoneModalId(id);
    setShowDoneModal(true);
    setCheckDoneId(id);
  };

  const handleCloseDoneModal = () => {
    setShowDoneModal(false);
    setShowDoneModalId(null);
    setCheckDoneId(null);
  };

  const isChecked = checkdoneId === todoid;

  const handleDoneClick = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/comments/${showDoneModalId}`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const postData = {
          status: "done",
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setShowDoneModal(false);
        setShowDoneModalId(null);
        // fetchData();

        toastRef.current.showToast("status updated Successfully!");
      }
    } catch (error) {
      console.error("Error updating todo:", error);
    }
  };

  return (
    <>
      {loader ? (
        <div className="mt-5 pt-5">
          <MinicommentsStatic />
        </div>
      ) : (
        <div>
          <div className="done-top-job-text row">
            <div className="d-flex align-items-center home-text col-9">
              <span className="cursor-pointer todos-home">
              <span onClick={()=>navigate("/posts/new")}>
        Home {`>`}
        </span>
                <Link to="/main-todo">
                  <span className="cursor-pointer  todos-home">
                    To Do {" > "}
                  </span>
                </Link>
                {groupName ? (
                  <Link to={to}>
                    <span className="todos-home">
                      {groupName} {"  > "}
                    </span>
                  </Link>
                ) : (
                  <>
                    {selectedItemName ? (
                      <Link to={to}>
                        <span className="todos-home">
                          {selectedItemName} {"  > "}
                        </span>
                      </Link>
                    ) : (
                      <span className="todos-home">
                        <Link to={to}>
                          {firstTodoName} {"  > "}{" "}
                        </Link>
                      </span>
                    )}
                  </>
                )}
                {/* <span  className="cursor-pointer  home-todo "></span> */}
                <Link
                  to={`/todo-list/${todos?.id}?status=still_working`}
                  className=""
                >
                  <span className="cursor-pointer todos-home ps-1">
                    {todos.notes} {" > "}
                  </span>
                </Link>
              </span>
              {/* <span  className="cursor-pointer  home-todo "></span> */}
              <span>
                {post.type_of === "task" || post.type_of === "text" ? (
                  <>
                    <span className="d-flex home-text ps-1 text-start text-break todos-home cursor-pointer">
                      {post.comment}
                    </span>
                  </>
                ) : post.type_of === "task_image" ||
                  post.type_of === "task_video" ||
                  post.type_of === "task_youtube" ? (
                  <>
                    {(() => {
                      const parts = post.comment.split("|&|");
                      const isVideo = post.type_of === "task_video";
                      const [mediaUrl, text] =
                        parts.length === 2
                          ? parts
                          : [post.comment, post.comment];

                      // Your code specific to "task_image", "task_video", or "task_youtube"
                      return (
                        <>
                          <span className="d-flex align-items-center  ">
                            <span className=" mb-0 todos-home  ps-1">
                              {text}
                            </span>
                          </span>
                        </>
                      );
                    })()}
                  </>
                ) : (
                  <div className="minitodo"></div>
                )}
              </span>
            </div>
            <div className=" col-3 d-flex align-items-end justify-content-end pe-5">
              <div className="button-containers">
                {previousTodoId ? (
                  <button
                    className="prev-button button-next"
                    onClick={handlePreviousClick}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="icon" /> PREV
                  </button>
                ) : (
                  <div className="nav-button-placeholder"></div>
                )}
              </div>

              <div className=" text-end my-auto ">
                {nextTodoId ? (
                  <button
                    className="next-button button-next"
                    onClick={handleNextClick}
                  >
                    NEXT{" "}
                    <FontAwesomeIcon icon={faArrowRight} className="icon" />
                  </button>
                ) : (
                  <div className="nav-button-placeholder"></div>
                )}
              </div>
            </div>
          </div>

          <div className="col-3 text-center">
            <h6 className="gigclass pt-2">Task ID: #{todoid} Still Working</h6>
          </div>
          <div className="container">
            <div className="row">
              {isLoggedIn ? (
                <div
                  className={` ${
                    commentType === "text" || commentType === "task"
                      ? "d-none"
                      : "col-md-4"
                  }`}
                >
                  {/* <div className="row"> */}
                  <div className="col-md-12 d-flex flex-row">
                    <div className="col-md-10 col-10">
                      <div className="video-container">
                        {post.type_of === "task" || post.type_of === "text" ? (
                          <></>
                        ) : post.type_of === "task_image" ||
                          post.type_of === "task_video" ||
                          post.type_of === "task_youtube" ? (
                          <>
                            {(() => {
                              const parts = post.comment.split("|&|");
                              const isVideo = post.type_of === "task_video";
                              const [mediaUrl, text] =
                                parts.length === 2
                                  ? parts
                                  : [post.comment, post.comment];

                              return (
                                <>
                                  <span className=" fw-medium ">
                                    <div className="video-container">
                                      {isVideo ? (
                                        <video
                                          controls
                                          className="video-size rounded-3"
                                          id={post?.id}
                                          onPlay={handlePlay}
                                          onClick={handlePlay}
                                        >
                                          <source
                                            src={mediaUrl}
                                            type="video/mp4"
                                            onClick={() => playVideo(post?.id)}
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      ) : (
                                        <img
                                          src={mediaUrl}
                                          alt="Task Images"
                                          className="img-fluid"
                                        />
                                      )}
                                      <p className="post-time mb-0 text-black ps-1 update-position ">
                                        {formatDate(date)}
                                      </p>
                                    </div>
                                  </span>
                                </>
                              );
                            })()}
                          </>
                        ) : (
                          <div className="minitodo"></div>
                        )}
                        <div></div>
                      </div>
                    </div>
                    <div className="col-md-2 col-2 text-center my-auto ">
                      <div className="d-flex flex-column">
                        <span className="">
                          <img
                            src="https://yesdone.com/build/assets/Doneapp-page-15-5e597573.png"
                            alt="Doneapp-page-15"
                            className="img-responsive post-icons "
                          />
                        </span>
                        <p className="mb-0 py-2">{/* {likes} */}</p>
                        <span className="">
                          <img
                            src="https://yesdone.com/build/assets/Doneapp-page-16-308861db.png"
                            alt="Doneapp-page-15"
                            className="img-responsive post-icons "
                          />
                        </span>
                        <p className="mb-0 py-2">{/* {comments} */}0</p>
                        <span className="">
                          <img
                            src="https://yesdone.com/build/assets/Doneapp-page-17-6a74558d.png"
                            alt="Doneapp-page-15"
                            className="img-responsive post-icons"
                          />
                        </span>
                        <p className="mb-0 py-2">{/* {saves} */}</p>
                        <span className="">
                          <img
                            src="https://yesdone.com/build/assets/Doneapp-page-18-60da9c9e.png"
                            alt="Doneapp-page-15"
                            className="img-responsive post-icons"
                          />
                        </span>
                        <p className="mb-0 py-2 ">1677</p>
                        <span className="">
                          <img
                            src="https://yesdone.com/build/assets/Doneapp-page-19-19dace4b.png"
                            alt="Doneapp-page-15"
                            className="img-responsive post-icons "
                            data-bs-toggle="modal"
                            data-bs-target="#post-update-modal"
                          />
                        </span>
                        <span className="py-3">
                          <img
                            src="https://dtzxzoe2ldz0i.cloudfront.net/job/Delete-icon.png"
                            alt="delete"
                            className="cursor-point post-icons"
                          />
                        </span>
                        <span className="py-3">
                          <Tippy content="checkbox">
                            <i
                              className={`bi bi-check-circle custom-checkbox-icon ${
                                isChecked ? "checked" : ""
                              }`}
                              onClick={() => handleClickDone(todoid)}
                            ></i>
                          </Tippy>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                className={`${
                  commentType === "text" || commentType === "task"
                    ? " col-md-12"
                    : " col-md-8"
                } ${
                  isLoggedIn && commentType !== "task" ? "left-border ps-0" : ""
                }`}
              >
                {isLoggedIn ? (
                  <>
                    <form
                      id="todo-comment-create-form"
                      onSubmit={(event) =>
                        handleMiniSubmit(event, postId, todoid)
                      }
                      className={`${todoStatus === "done" ? "d-none" : ""}`}
                    >
                      <div className="form-control d-flex align-items-end border-0 position-relative mb-3">
                        <textarea
                          required
                          name="title"
                          className="form-control border px-2 rounded-3 shadow-none"
                          rows="4"
                          placeholder=""
                          value={test}
                          onChange={(e) => setTest(e.target.value)}
                        ></textarea>
                        <div className="absoluteposition mt-2 me-2 pt-5 pe-4 pb-2">
                          {(thumbnail || file) && (
                            <ImagePreview
                              file={thumbnail || file}
                              onDelete={handleFileDelete}
                            />
                          )}
                        </div>
                        <div className="position-absolute  end-0 mt-4 me-2 pt-5 pe-4 pb-2">
                          <label
                            htmlFor="imgFileUpload"
                            className="custom-file-upload"
                          >
                            <img
                              src="https://yesdone.com/build/assets/Doneapp-page-11-e43f6bb5.png"
                              alt=""
                              width="40"
                              className="mt-2 px-2 cursor-pointer"
                              height="20"
                            />
                            <input
                              id="imgFileUpload"
                              type="file"
                              name="files"
                              accept="image/*, video/*"
                              onChange={handleFileChange}
                            />
                          </label>
                          <button
                            name="submit"
                            id="search-button"
                            type="submit"
                            className="btn btn-success fw-normal btn-sm px-4"
                          >
                            <b>Send</b>
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                ) : null}
                {loader ? (
                  <div className="d-flex align-items-center justify-content-center my-2">
                    <ChatWindowStatic />
                  </div>
                ) : (
                  <>
                    <p className="ps-3">{todos.notes}</p>
                    <InfiniteScroll
                      dataLength={replies.length}
                      next={() => fetchReplys()}
                      hasMore={hasMore}
                      scrollableTarget="scrollableDiv"
                      className="infinite-overflow"
                    >
                      {replies?.map((reply) => (
                        <React.Fragment key={reply?.id}>
                          {miniEdit && miniTodoId === reply?.id ? (
                            <div className="row">
                              <div className="col-4"></div>
                              <div className="col-7 d-flex edit-form align-items-center">
                                <textarea
                                  type="text"
                                  className={`form-control shadow-none ${
                                    inputValue.length > 35
                                      ? "text-areamini"
                                      : ""
                                  }`}
                                  value={inputValue}
                                  onChange={(e) =>
                                    handleInputChange(e.target.value)
                                  }
                                ></textarea>
                                <span className="d-flex my-3">
                                  <button
                                    className="btn d-flex align-items-center justify-content-center mx-3 col-2"
                                    onClick={() =>
                                      handleMiniUpdate(
                                        reply.post_id,
                                        reply.parent_id,
                                        reply?.id,
                                        reply.type_of
                                      )
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                  </button>
                                  <button
                                    className="btn btn-secondary d-flex align-items-center justify-content-center col-2"
                                    onClick={handleCloseEdit}
                                  >
                                    <i className="bi bi-x"></i>
                                  </button>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div key={reply?.id} className="row">
                              <div
                                className={`d-flex align-items-center ${
                                  Number(userId) === Number(reply.creator?.id)
                                    ? "d-flex justify-content-end pe-4 "
                                    : "minicommentstart "
                                }  `}
                              >
                                {/* <div className="col-9"></div> */}
                                <div className=" d-flex flex-row ps-3">
                                  <div className="d-flex  align-items-center">
                                    <p className=" m-0 my-2">
                                      <span
                                        style={{
                                          backgroundColor:
                                            copytextId === reply?.id && isCopied
                                              ? "#1f02ed"
                                              : null,
                                        }}
                                        onClick={handleTextClick}
                                      >
                                        {reply.type_of === "task_image" ? (
                                          <>
                                            <div
                                              className={`${
                                                reply.comment.split("|&|")[1] >
                                                35
                                                  ? "imgvideo-m"
                                                  : ""
                                              }`}
                                            >
                                            
                                              <img
                                                src={
                                                  reply.comment.split("|&|")[0]
                                                }
                                                alt="comment img"
                                                width="400px"
                                                height="400px"
                                                className="custom-image"
                                               
                                              />
                                            </div>
                                            <div
                                              className={`comments ${
                                                Number(userId) ===
                                                Number(reply.creator.id)
                                                  ? "comment-margin "
                                                  : ""
                                              }  `}
                                            >
                                              <span className="">
                                                <pre className="pre-comments">
                                                  {
                                                    reply.comment.split(
                                                      "|&|"
                                                    )[1]
                                                  }
                                                </pre>
                                              </span>
                                            </div>
                                          </>
                                        ) : reply.type_of === "task_video" ? (
                                          <>
                                            <div>
                                              <video
                                                controls
                                                className="custom-video object-fit-cover "
                                                id={reply.id}
                                                onPlay={handlePlay}
                                                onClick={handlePlay}
                                              >
                                                <source
                                                  src={
                                                    reply.comment.split(
                                                      "|&|"
                                                    )[0]
                                                  }
                                                  type="video/mp4"
                                                  onClick={() =>
                                                    playVideo(reply?.id)
                                                  }
                                                />
                                                Your browser does not support
                                                the video tag.
                                              </video>
                                            </div>
                                            <div
                                              className={`comments ${
                                                Number(userId) ===
                                                Number(reply.creator.id)
                                                  ? "comment-margin "
                                                  : ""
                                              }  `}
                                            >
                                              <span className="">
                                                <pre className="pre-comments">
                                                  {
                                                    reply.comment.split(
                                                      "|&|"
                                                    )[1]
                                                  }
                                                </pre>
                                              </span>
                                            </div>
                                          </>
                                        ) : reply.type_of === "image" ? (<>
                                    

                                    <div
                                              className={`${
                                                reply.comment.split("|&|")[1] >
                                                35
                                                  ? "imgvideo-m"
                                                  : ""
                                              }`}
                                            >
                                            
                                              <img
                                                src={
                                                  reply.comment.split("|&|")[0]
                                                }
                                                alt="comment img"
                                                width="400px"
                                                height="400px"
                                                className="custom-image"
                                               
                                              />
                                            </div>
                                            <div
                                              className={`comments ${
                                                Number(userId) ===
                                                Number(reply.creator.id)
                                                  ? "comment-margin "
                                                  : ""
                                              }  `}
                                            >
                                              <span className="">
                                                <pre className="pre-comments">
                                                  {
                                                    reply.comment.split(
                                                      "|&|"
                                                    )[1]
                                                  }
                                                </pre>
                                              </span>
                                            </div>


{/* 
                                          <img
                                            src={reply.comment.split("|&|")[0]}
                                            alt="comment images"
                                            width="100px"s
                                            className="custom-image"
                                          />
                                          <span>{reply.comment.split("|&|")[1]}</span> */}
                                          </>
                                        ) : reply.type_of === "video" ||
                                          reply.type_of === "youtube" ? (
                                          <div>
                                            <video
                                              controls
                                              className="custom-video object-fit-cover"
                                              id={reply?.id}
                                              onPlay={handlePlay}
                                              onClick={handlePlay}
                                            >
                                              <source
                                                src={
                                                  reply.comment.split("|&|")[0]
                                                }
                                                type="video/mp4"
                                                onClick={() =>
                                                  playVideo(reply?.id)
                                                }
                                              />
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                            <span>
                                              <pre className="pre-comments">
                                                {reply.comment.split("|&|")[1]}
                                              </pre>
                                            </span>
                                          </div>
                                        ) : reply.type_of === "audio" ? (
                                          <audio
                                            controls
                                            className="custom-audio"
                                          >
                                            <source
                                              src={reply.comment}
                                              type="audio/mp3"
                                            />
                                            Your browser does not support the
                                            audio tag.
                                          </audio>
                                        ) : (
                                          <div className="comments">
                                            <pre className="pre-comments">
                                              {reply.comment}
                                            </pre>
                                          </div>
                                        )}
                                      </span>
                                    </p>
                                    {Number(userId) ===
                                    Number(reply.creator?.id) ? (
                                      <div className="dropdown cursor-pointer">
                                        <span
                                          className="d-flex justify-content-center"
                                          data-bs-toggle="dropdown"
                                          onClick={() =>
                                            handleMenuOpen(reply?.id)
                                          }
                                        >
                                          <i className="bi bi-three-dots-vertical "></i>
                                        </span>
                                        <div
                                          className={
                                            menuOpen &&
                                            showAddTaskPartId === reply?.id
                                              ? "dropdown-menu show"
                                              : "dropdown-menu"
                                          }
                                        >
                                          <button
                                            onClick={() =>
                                              handletextcopy(
                                                reply.comment,
                                                reply?.id
                                              )
                                            }
                                            className="dropdown-item"
                                          >
                                            Copy Text
                                          </button>
                                          <button
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleMiniEdit(
                                                reply?.id,
                                                reply.comment
                                              )
                                            }
                                          >
                                            Edit
                                          </button>
                                          <button
                                            className="dropdown-item"
                                            onClick={() =>
                                              opendeletemodal(
                                                reply.post_id,
                                                reply.parent_id,
                                                reply?.id
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={`d-flex align-items-center ${
                                  Number(userId) === Number(reply.creator?.id)
                                    ? " d-flex justify-content-end pe-4 "
                                    : "minicommentstart "
                                } ps-4 mb-3`}
                              >
                                <img
                                  src={reply.creator?.profile_image}
                                  className="profileclass me-2"
                                  alt="Profile"
                                />
                                <span className="postcomment pe-1">by</span>
                                <span className="postcomment">
                                  {reply.creator?.first_name}{" "}
                                  {reply.creator?.last_name} on{" "}
                                  {formatDate(reply.created_at)}
                                </span>
                              </div>

                              <Modal
                                show={delmodalopen}
                                onHide={closedeletemodal}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <Modal.Header
                                  //  closeButton
                                  className="fs-1 fw-bold text-center d-flex justify-content-center border-0"
                                >
                                  <i
                                    className="bi bi-exclamation-circle text-center text-danger modal-icon"
                                    // style={{
                                    //   fontSize: "85px",
                                    //   color: "#f8bb86 !important",
                                    // }}
                                  ></i>
                                </Modal.Header>
                                <Modal.Body className="">
                                  Are you sure ? You won't be able to revert
                                  this!
                                </Modal.Body>
                                <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                                  <Button
                                    variant="primary"
                                    onClick={handleDelMinitodo}
                                  >
                                    Yes, delete it!
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={closedeletemodal}
                                  >
                                    No
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                      {loader1 ? <ChatWindowStatic /> : null}
                      {/* </div> */}
                    </InfiniteScroll>
                  </>
                )}

                {/* </div> */}
              </div>
              {loader1 ? <ChatWindowStatic /> : null}
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showDoneModal}
        onHide={handleCloseDoneModal}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center  text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleDoneClick}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseDoneModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MiniComments;
