import React, { useState, useEffect, useRef } from "react";
import Tippy from "@tippyjs/react";
import { useMiniTodoData } from "../AuthContext/minitodos";
import API_BASE_URL from "../../Config";
import { calculateTimeDifference } from "../../utils/index";
import { fetchPostData } from "../../Api/MiniTodo";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Popup from "../../popup/Popup";
import "./miniDone.css";
import LoadingImg from "../../images/loading.webp";
import ToastContainer from "../toast/ToastContainer";
const MiniDelete = ({ fetchData }) => {
  const { count, setCount, miniTodoData, setMiniTodoData } = useMiniTodoData();
  const [pageNo, setPageNo] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const todos = miniTodoData.todos;
  const [loading, setLoading] = useState(false); // State to track if data is currently being loaded
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [RestoreModalId, setRestoreModalId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("status");
  const [showDoneModalId, setShowDoneModalId] = useState(null);
  const toastRef = useRef();
  const { id } = useParams();
  console.log("miniDonecomponent", id, activeTab);

  const fetchRecords = async () => {
    try {
      setLoading(true);
      const newData = await fetchPostData(id, activeTab, pageNo);
      console.log("peginationInDone", newData.data.pagination);
      setMiniTodoData((prevData) => ({
        todos: [...prevData.todos, ...newData.data.todos],
      }));
      setHasMore(
        newData?.data?.pagination?.current_page <=
          newData?.data?.pagination?.last_page
      );
      setPageNo(newData?.data?.pagination?.current_page + 1);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRestoreClick = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/comments/${RestoreModalId}`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const postData = {
          status: "still_working",
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        console.log("restored successfully");
        setShowRestoreModal(false);
        setRestoreModalId(null);
        setShowDoneModalId(null);
        console.log("restored successfully");
        setMiniTodoData((prevData) => {
          const idToFilter = RestoreModalId;

          const filteredTodos = prevData.todos.filter(
            (todo) => todo.id !== idToFilter
          );

          return {
            todos: filteredTodos,
          };
        });
        setCount((prevTodoData) => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount + 1,
          deleteCount: prevTodoData.deleteCount - 1,
        }));
        toastRef.current.showToast("Comment Status Updated Successfully");
        console.log("current toast",toastRef.current);
      }
    } catch (error) {
      console.error("Error updating todo:", error);
    }
  };

  const handleRestore = (id) => {
    setShowRestoreModal(true);
    setRestoreModalId(id);
  };
  const handleCloseRestore = () => {
    setShowRestoreModal(false);
    setRestoreModalId(null);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={todos.length}
        next={() => fetchRecords()}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        // Necessary to prevent issues with child elements positioning
      >
         <ToastContainer ref={toastRef} timeout={4000}/>
        <div className="scrollableMainDelete" id="scrollableDiv">
          {todos.map((todo, index) => (
            <React.Fragment key={todo.id || index}>
              {/* <div className="row  bottom-border  pt-2 d-flex update-position1"> 
            <div className="col-12 bottom-border  ps-2">
             */}
              {todo.type_of === "task" || todo.type_of === "text" ? (
                <>
                  <div className="container">
                    <div className="row  bottom-border text-dark fw-medium py-2">
                      <div className="col-sm-9 col-9 d-flex  align-items-center ps-4 padding-done">
                        <div className=" text-black  custom-fontsize ps-3">
                          {index + 1}.
                        </div>
                        <div className="  comment-position">
                          <img
                            className="hidden me-2 img-visible"
                            src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png"
                            alt="Hidden Images"
                            width="25px"
                            height="19px"
                          />
                          <span className="ps-1 posttime complete text-break">
                            {todo.comment}
                          </span>
                          <p className="post-time mb-0 text-black">
                            {calculateTimeDifference(todo.updated_at)}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-3 col-3 d-flex justify-content-start align-items-center ps-1">
                        <div onClick={() => handleRestore(todo.id)}>
                          <Tippy content="restore">
                            <i
                              className="fa fa-window-restore text-dark"
                              aria-hidden="true"
                            ></i>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : todo.type_of === "task_image" ||
                todo.type_of === "task_video" ||
                todo.type_of === "task_youtube" ? (
                <>
                  {(() => {
                    const parts = todo.comment.split("|&|");
                    const isVideo = todo.type_of === "task_video";
                    const [mediaUrl, text] =
                      parts.length === 2 ? parts : [todo.comment, todo.comment];

                    // Your code specific to "task_image", "task_video", or "task_youtube"
                    return (
                      <>
                        <div className="container">
                          <div className="row py-1  bottom-border text-dark fw-medium">
                            <div className="col-sm-9 padding-done col-9 d-flex ps-4 ">
                              <div className=" text-black  custom-fontsize ps-3">
                                {index + 1}.
                              </div>

                              <Popup url={mediaUrl} isVideo={isVideo} text={text}/>
                              <div className="d-flex flex-column">
                                <span className="ps-1 posttime complete text-break">
                                  {text}
                                </span>
                                <p className="post-time mb-0 text-black ps-2 ">
                                  {calculateTimeDifference(todo.updated_at)}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-3 col-3 d-flex justify-content-start align-items-center ps-1">
                              <div onClick={() => handleRestore(todo.id)}>
                                <Tippy content="restore">
                                  <i
                                    className="fa fa-window-restore text-dark"
                                    aria-hidden="true"
                                  ></i>
                                </Tippy>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })()}
                </>
              ) : null}
              {/* </div> 
          </div> */}
            </React.Fragment>
          ))}
          <ToastContainer />
          {loading ? (
            <p className="text-center text-muted">
              <img src={LoadingImg} width="35px" height="35px" />
            </p>
          ) : null}
        </div>
      </InfiniteScroll>
      <Modal
        show={showRestoreModal}
        onHide={handleCloseRestore}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center  d-flex align-items-center text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleRestoreClick}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseRestore}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MiniDelete;
