import React, { useEffect } from "react";

const ImageModal = ({ isOpen, imageSrc, onClose }) => {
  // Close the modal when "Escape" key is pressed
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <img src={imageSrc} alt="Enlarged" className="enlarged-image" />
      </div>
    </div>
  );
};

export default ImageModal;
