import React from 'react'

const ChatWindowStatic = () => {
  return (
    <div className='container  '>
  <div className='row'>
      <div className='col-6'></div>
      <div className='col-6'>

    
<div className='image-video mb-3'></div>
<div className='text-static mb-1'></div>
<div className='row mb-1 '>
<div className='profilestatic ms-3 '>
</div>
<div className='profilestatic1 ms-2 my-auto'></div>
</div>
<div className='text-static my-3'></div>
</div>
</div>
    </div>
  )
}

export default ChatWindowStatic