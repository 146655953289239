// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-toast-container {
    background-color:#000000;
    color: #ffffff;
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 5px;
    z-index: 1000;
    transform: translateY(-500px);
    transition: 0.4s;
}
.react-toast-container.show {
    transform: translateY(0px);
}`, "",{"version":3,"sources":["webpack://./src/Components/toast/Toast.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,cAAc;IACd,eAAe;IACf,SAAS;IACT,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;AACpB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".react-toast-container {\n    background-color:#000000;\n    color: #ffffff;\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    padding: 15px;\n    border-radius: 5px;\n    z-index: 1000;\n    transform: translateY(-500px);\n    transition: 0.4s;\n}\n.react-toast-container.show {\n    transform: translateY(0px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
