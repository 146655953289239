import React from "react";
import "./MiniComments.css";

const MinicommentsStatic = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4  ">
          <div className="ministatic"></div>
        </div>
        <div className="col-md-8 mt-5 ">
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <div className="image-video mb-3"></div>
              <div className="text-static mb-1"></div>
              <div className="row mb-1 ">
                <div className="profilestatic ms-3 "></div>
                <div className="profilestatic1 ms-2 my-auto"></div>
              </div>
              <div className="text-static my-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-8"></div>
    </div>
  );
};

export default MinicommentsStatic;
