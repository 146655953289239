// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-tab {
    font-size: 20px;
    color: #c0bdbd;
    border-bottom: 2px solid #BBBBBB;
    }
.custom-navs .nav-link.active .post-tab {
    color: rgb(12, 146, 12) ;
    border-bottom: 2px solid !important;
    border-bottom-color: #55a13c ;
    font-weight: 600;
  }
  .custom-navs .nav-link:hover .post-tab{
    color:rgb(37, 138, 37);
    border-bottom: none;
  }

  .custum-text-area-height{
    min-height: calc(6.5em + .75rem + calc(var(--bs-border-width)* 4)) !important;
}

  
  .button-post {
    background-color: rgb(37, 138, 37);
    color: #ffffff;
    height: 40px;
    border: none; /* remove default button border */
    font-size: x-large;
    border-radius: 5px;
  }
.calander{
  background-color: transparent;
}
.recentCard{
  width: 38rem;
}
.column-layout {
  column-count: 2;
}
.green-background{
  color:#009933 ;
}
.green-border:checked{
  background-color: green;
  border-color: green;
 }
.custom-file-upload input{
display: none;
}
  @media screen and (max-width:425px){
    .column-layout{
      column-count: 1;
    }
  }
  @media screen and (max-width:576px) {
    .post-font{
      font-size: 14px;
      padding-left: 5px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/post/Post.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,gCAAgC;IAChC;AACJ;IACI,wBAAwB;IACxB,mCAAmC;IACnC,6BAA6B;IAC7B,gBAAgB;EAClB;EACA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,6EAA6E;AACjF;;;EAGE;IACE,kCAAkC;IAClC,cAAc;IACd,YAAY;IACZ,YAAY,EAAE,iCAAiC;IAC/C,kBAAkB;IAClB,kBAAkB;EACpB;AACF;EACE,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,uBAAuB;EACvB,mBAAmB;CACpB;AACD;AACA,aAAa;AACb;EACE;IACE;MACE,eAAe;IACjB;EACF;EACA;IACE;MACE,eAAe;MACf,iBAAiB;IACnB;EACF","sourcesContent":[".post-tab {\n    font-size: 20px;\n    color: #c0bdbd;\n    border-bottom: 2px solid #BBBBBB;\n    }\n.custom-navs .nav-link.active .post-tab {\n    color: rgb(12, 146, 12) ;\n    border-bottom: 2px solid !important;\n    border-bottom-color: #55a13c ;\n    font-weight: 600;\n  }\n  .custom-navs .nav-link:hover .post-tab{\n    color:rgb(37, 138, 37);\n    border-bottom: none;\n  }\n\n  .custum-text-area-height{\n    min-height: calc(6.5em + .75rem + calc(var(--bs-border-width)* 4)) !important;\n}\n\n  \n  .button-post {\n    background-color: rgb(37, 138, 37);\n    color: #ffffff;\n    height: 40px;\n    border: none; /* remove default button border */\n    font-size: x-large;\n    border-radius: 5px;\n  }\n.calander{\n  background-color: transparent;\n}\n.recentCard{\n  width: 38rem;\n}\n.column-layout {\n  column-count: 2;\n}\n.green-background{\n  color:#009933 ;\n}\n.green-border:checked{\n  background-color: green;\n  border-color: green;\n }\n.custom-file-upload input{\ndisplay: none;\n}\n  @media screen and (max-width:425px){\n    .column-layout{\n      column-count: 1;\n    }\n  }\n  @media screen and (max-width:576px) {\n    .post-font{\n      font-size: 14px;\n      padding-left: 5px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
