// import { createContext, useContext, useState } from 'react';

// const MiniTodoDataContext = createContext();

// export const MiniTodoDataProvider = ({ children }) => {
//   const [miniTodoData, setMiniTodoData] = useState({
   
// });
// const [count, setCount]=useState({
//   todoCount:0,
//   doneCount:0,
//   deleteCount:0}
// )
//   return (
//     <MiniTodoDataContext.Provider value={{ miniTodoData, setMiniTodoData,count,setCount }}>
//       {children}
//     </MiniTodoDataContext.Provider>
//   );
// };

// export const useMiniTodoData = () => {
//   const context = useContext(MiniTodoDataContext);
//   if (!context) {
//     throw new Error('useTodoData must be used within a TodoDataProvider');
//   }
//   return context;
// };
import React, { createContext, useContext, useState } from 'react';

const MiniTodoDataContext = createContext();

export const MiniTodoDataProvider = ({ children }) => {
  const [miniTodoData, setMiniTodoData] = useState({});
  const [count, setCount] = useState({
    todoCount: 0,
    doneCount: 0,
    deleteCount: 0
  });

  // Function to reset counts
  const resetCounts = () => {
    setCount({
      todoCount: 0,
      doneCount: 0,
      deleteCount: 0
    });
  };

  return (
    <MiniTodoDataContext.Provider value={{ miniTodoData, setMiniTodoData, count, setCount, resetCounts }}>
      {children}
    </MiniTodoDataContext.Provider>
  );
};

export const useMiniTodoData = () => {
  const context = useContext(MiniTodoDataContext);
  if (!context) {
    throw new Error('useTodoData must be used within a TodoDataProvider');
  }
  return context;
};
