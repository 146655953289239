// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Login from "./Components/login/Login";
// import "./App.css"
// import Layout from "./Components/layout/Layout";
// import Todos from "./Components/todos/Todos";
// import Product from "./Components/products/Product";
// import Post from "./Components/post/Post";
// import { AuthProvider } from './Components/AuthContext/AuthContext.js';
// import { TodoDataProvider } from './Components/AuthContext/TodoDataContext.js';
// import { TodoProvider } from "./Components/AuthContext/TodoContext";
// import MiniPost from "./Components/todos/MiniPost";
// import Comments from "./Components/comments/Comments";
// import { MiniTodoDataProvider } from "./Components/AuthContext/minitodos";
// import MiniComments from "./Components/comments/MiniComments";
// import Chat from "./Components/chat/Chat";
// import Users from "./Components/chat/Users.jsx";
// // import { IdProvider } from "./Components/AuthContext/PostIdContext";
// import { Provider } from 'react-redux';
// import store from './Redux/store/store.js'; 
// import { UserProvider } from './Components/AuthContext/ChatContext.js';
// import { FilterDataProvider } from './Components/AuthContext/SidebarContext.js';
// import MainTodoList from "./Components/MainTodoList/MainTodoList.js";
// import CreateGroup from "./Components/post/CreateGroup.jsx";
// import ForgetPassowrd from "./Components/login/ForgetPassowrd.jsx";
// import ChangePassWord from "./Components/login/ChangePassWord.jsx";
// import ProfilePage from "./Components/login/ProfilePage.jsx";
// const AppRoutes = () => {
//   return (
//     <Router>
//        <Provider store={store}>
//       <AuthProvider>
//         <FilterDataProvider>
//       <UserProvider>
//       <TodoDataProvider>
//       <TodoProvider>
//         <MiniTodoDataProvider>
//           <Routes>
//             <Route path="/" element={<Layout />}>
//               <Route index element={<Product />} />
//               <Route path="todo-list">
//                 <Route index element={<Todos />} />
//                 <Route path=":id" element={<MiniPost />} />
//               </Route>
//               <Route path="/main-todo" element={<MainTodoList />} />
//               <Route path="/user/profile" element={<ProfilePage />} />
//               <Route path="posts/new" element={<Post />} />
//               <Route path="posts/creategroup" element={<CreateGroup />} />
//               <Route path="posts/:postid/comments" element={<Comments />} />
//               <Route
//                 path="posts/:postValue/:todoid/todo-comments"
//                 element={<MiniComments />}
//               />
//               <Route path="/chat" element={<Chat/>}/>
//               <Route path="/changepassword" element={<ChangePassWord/>}/>
//               <Route path="/chat-user" element={<Users/>}/>
//             </Route>

//             <Route path="/login" element={<Login />} />
//             <Route path="/password/reset" element={<ForgetPassowrd />} />
//           </Routes>
//         </MiniTodoDataProvider>
//       </TodoProvider>
     
      
//       </TodoDataProvider>
//       </UserProvider>
//       </FilterDataProvider>
//       </AuthProvider>
//       </Provider>
//     </Router>
//   );
// };

// export default AppRoutes;
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Components/login/Login";
import "./App.css"
import Layout from "./Components/layout/Layout";
import Todos from "./Components/todos/Todos";
import Product from "./Components/products/Product";
import Post from "./Components/post/Post";
import { AuthProvider } from './Components/AuthContext/AuthContext.js';
import { TodoDataProvider } from './Components/AuthContext/TodoDataContext.js';
import { TodoProvider } from "./Components/AuthContext/TodoContext";
import MiniPost from "./Components/todos/MiniPost";
import Comments from "./Components/comments/Comments";
import { MiniTodoDataProvider } from "./Components/AuthContext/minitodos";
import MiniComments from "./Components/comments/MiniComments";
import Chat from "./Components/chat/Chat";
import Users from "./Components/chat/Users.jsx";
// import { IdProvider } from "./Components/AuthContext/PostIdContext";
import { Provider } from 'react-redux';
import store from './Redux/store/store.js'; 
import { UserProvider } from './Components/AuthContext/ChatContext.js';
import { FilterDataProvider } from './Components/AuthContext/SidebarContext.js';
import MainTodoList from "./Components/MainTodoList/MainTodoList.js";
import CreateGroup from "./Components/post/CreateGroup.jsx";
import ForgetPassowrd from "./Components/login/ForgetPassowrd.jsx";
import ChangePassWord from "./Components/login/ChangePassWord.jsx";
import ProfilePage from "./Components/login/ProfilePage.jsx";

const AppRoutes = () => {
  // State to handle server connection status
  const [serverStatus, setServerStatus] = useState(true); // Assume server is initially reachable

  useEffect(() => {
    // Here you can add logic to check server status, like sending a request to your API
    // If the server is unreachable or there's an error response, set serverStatus to false
    // For demonstration purposes, I'm setting it to false after 5 seconds
    const timeout = setTimeout(() => {
      setServerStatus(false);
    }, 5000); // Change this value according to your actual API request timeout

    // Clean up function to clear the timeout
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Router>
      <Provider store={store}>
        <AuthProvider>
          <FilterDataProvider>
            <UserProvider>
              <TodoDataProvider>
                <TodoProvider>
                  <MiniTodoDataProvider>
                    <Routes>
                      {/* Render a static page if server is unreachable */}
                      {!serverStatus && <Route element={<Navigate to="/server-error" />} />}

                      <Route path="/" element={<Layout />}>
                        <Route index element={<Product />} />
                        <Route path="todo-list">
                          <Route index element={<Todos />} />
                          <Route path=":id" element={<MiniPost />} />
                        </Route>
                        <Route path="/main-todo" element={<MainTodoList />} />
                        <Route path="/user/profile" element={<ProfilePage />} />
                        <Route path="posts/new" element={<Post />} />
                        <Route path="posts/creategroup" element={<CreateGroup />} />
                        <Route path="posts/:postid/comments" element={<Comments />} />
                        <Route
                          path="posts/:postValue/:todoid/todo-comments"
                          element={<MiniComments />}
                        />
                        <Route path="/chat" element={<Chat/>}/>
                        <Route path="/changepassword" element={<ChangePassWord/>}/>
                        <Route path="/chat-user" element={<Users/>}/>
                      </Route>

                      <Route path="/login" element={<Login />} />
                      <Route path="/password/reset" element={<ForgetPassowrd />} />
                      <Route path="/server-error" element={<ServerUnavailable />} />
                    </Routes>
                  </MiniTodoDataProvider>
                </TodoProvider>
              </TodoDataProvider>
            </UserProvider>
          </FilterDataProvider>
        </AuthProvider>
      </Provider>
    </Router>
  );
};

// Define a component for the static server error page
const ServerUnavailable = () => {
  return (
    <div>
      <h1>Server Unavailable</h1>
      <p>Sorry, the server is currently unreachable. Please try again later.</p>
    </div>
  );
};

export default AppRoutes;

