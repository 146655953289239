import "./Mainposts.css";
import Tippy from "@tippyjs/react";
import Popup from "../../popup/Popup";
import API_BASE_URL from "../../Config";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

import { useSearchParams } from "react-router-dom";
import ImagePreview from "../../utils/imagePreview";
import { saveBookMark } from "../../Api/MainTodoActions";
import { FaFileExport } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { FaClipboard } from "react-icons/fa";
import copy from "../../images/copy-icon-01.png";
import { useTodoData } from "../AuthContext/TodoDataContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMiniTodoData } from "../AuthContext/minitodos";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { fetchTodoRecords, formatRelativeTime } from "../../Api/fetchTodo";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
  calculateTimeDifference,
} from "../../utils/index";
import { formatDate, formatDateforDue } from "../../utils/index";
import LoadingImg from "../../images/loading.webp";
import fetch from "isomorphic-fetch";
import { faL } from "@fortawesome/free-solid-svg-icons";
//  import { ToastContainer, toast } from "react-toastify";
import ToastContainer from "../toast/ToastContainer";
const MainPost = ({ initialFetchTodos, sortData, isLastPage }) => {
  const scrollableMainRef = useRef(null);
  const [isScrollbarPresent, setIsScrollbarPresent] = useState(false);
  const [replyText, setReplyText] = useState("");
  const { resetCounts } = useMiniTodoData();
  useEffect(() => {
    const scrollableMainElement = scrollableMainRef.current;
    if (scrollableMainElement) {
      // Check if scrollbar is present
      const isScrollbarPresent =
        scrollableMainElement.scrollHeight > scrollableMainElement.clientHeight;
      setIsScrollbarPresent(isScrollbarPresent);
    }
  }, []);
  const [checkedState, setCheckedState] = useState({});
  const [file, setFile] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const { todoData, setTodoData } = useTodoData();
  const [thumbnail, setThumbnail] = useState(null);
  const [deleteIds, setDeleteIds] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [updatedModal, setUpdatedModal] = useState(false);
  const [notDoneModal, setNotDoneModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [notDoneId, setNotDoneId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [currentPage, setCurrentPage] = useState(2);
  const [showModal1, setShowModal1] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditing, setIsEditing] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreId, setRestoreId] = useState(null);
  const [checkedCheckboxId, setCheckedCheckboxId] = useState(null);
  const [editingComment, setEditingComment] = useState(false);
  const [inputValueText, setInputValueText] = useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [commentEditId, setCommentEditId] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [url, setUrl] = useState(null);
  const posts = todoData.posts;
  console.log("post", posts);

  const userId = searchParams.get("user_id");
  const activeTab = searchParams.get("status");
  const groupId = searchParams.get("group_id");
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(true);
  const [selectedTodoId, setSelectedTodoId] = useState(null);
  const [selectedReplyId, setSelectedReplyID] = useState(null);
  const [selectedComment, setSelectedComment] = useState("");
  const [todoNotes, setTodoNotes] = useState(null);
  const [bookMarkModal, setBookMarkModal] = useState(false);
  const [bookMarkId, setBookMarkId] = useState(null);
  const [title1, setTitle1] = useState("");
  const [showCalander, setShowCalander] = useState(false);
  const [copyMove, setCopyMove] = useState(null);
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 7);
    return date;
  });
  const getInitialDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date;
  };

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = selectedDate.toLocaleDateString("en-US", options);
  const handleShowCalander = () => {
    setShowCalander(!showCalander);
  };

  const handleDateChange = (date) => {
    console.log("handle date change", date);

    setSelectedDate(date);
  };
  const toastRef = useRef();
  const textareaRef = useRef(null);

  useEffect(() => {
    const storedSelectedReplyId = localStorage.getItem("selectedReplyId");
    if (storedSelectedReplyId) {
      setReplyText(storedSelectedReplyId);
    }
  }, []);

  const handleTextChange = (e) => {
    setTitle1(e.target.value);
    console.log(e.target.value);
    adjustTextareaHeight();
  };

  const handleMoveButtonClick = (todoId) => {
    setCopyMove("move");
    setSelectedTodoId(todoId);
    setShowSelect(true);
  };

  const openModal = (todoId, notes) => {
    setSelectedTodoId(todoId);
    setSelectedReplyID(null);
    setTodoNotes(notes);
    setIsEditing(false); // Creating a new reply
    setModalIsOpen(true);
    setReplyText("");
  };
  const openModalReply = (replyID, comment) => {
    setSelectedReplyID(replyID);
    setSelectedTodoId(null);
    setReplyText(comment);
    setTodoNotes(comment);
    setIsEditing(true); // Editing an existing reply
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedReplyID(null);
    setSelectedReplyID(null);
    setReplyText("");
    setIsEditing(false); // Reset editing state
  };

  const submitReply = async () => {
    setLoading(true); // Start loader

    const payload = {
      comment: replyText,
      type_of: "reply",
    };

    try {
      let url;
      let method;

      if (isEditing) {
        // Editing an existing reply
        url = `${API_BASE_URL}/api/v1/comments/${selectedReplyId}`;
        method = "PUT";
      } else {
        // Creating a new reply
        url = `${API_BASE_URL}/api/v1/post-reply/${selectedTodoId}`;
        method = "POST";
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const newReply = await response.json(); // Get the new or updated reply data from the response

        setTodoData((prevTodoData) => {
          return {
            ...prevTodoData,
            posts: prevTodoData.posts.map((post) => {
              if (post.id === selectedTodoId) {
                return {
                  ...post,
                  replies: isEditing
                    ? post.replies.map((reply) =>
                        reply.id === selectedReplyId
                          ? { ...reply, comment: replyText } // Update only the edited reply with the new content
                          : reply
                      )
                    : [...post.replies, newReply?.data], // Append the new reply for new submissions
                };
              }
              return post;
            }),
          };
        });

        // If it's a new reply, update the selected reply ID and todo ID
        if (!isEditing) {
          setSelectedReplyID(newReply?.data?.id || null);
          setSelectedTodoId(newReply?.data?.todo_id);
        }

        // Close the modal and reset the input after successful submission
        setModalIsOpen(false);
        setReplyText(""); // Clear the reply input
        console.log("Reply submitted and UI updated successfully");
      } else {
        const errorData = await response.json();
        console.error(
          "Failed to submit reply:",
          errorData.message || response.statusText
        );
      }
    } catch (error) {
      console.error("Error submitting reply:", error.message || error);
    } finally {
      setLoading(false); // Stop loader after submission
    }
  };

  useEffect(() => {
    // Assuming filterData is stored in localStorage as JSON string
    const data = localStorage.getItem("filterData");
    if (data) {
      setFilterData(JSON.parse(data));
    } else {
      console.log("No filter data found in localStorage");
    }
  }, []);

  // Retrieve selected country from localStorage and convert it to lowercase
  const selectedCountry = localStorage
    .getItem("selectedCountry")
    ?.toLowerCase();

  // Define filtered teammates based on selectedCountry
  const filteredTeammates =
    filterData?.filter((item) => {
      const itemCountry = item.country?.toLowerCase() || null;

      // When selectedCountry is not null, filter by matching country
      if (selectedCountry) {
        return itemCountry === selectedCountry;
      }

      // When selectedCountry is null, show users with null country
      return itemCountry === null;
    }) || [];

  // Handle user click
  const handleUserClick = (userId) => {
    setSelectedUserIds((prev) => {
      // Add userId to the array if not already present
      if (!prev.includes(userId)) {
        return [...prev, userId];
      }
      // Remove userId from the array if already present
      return prev.filter((id) => id !== userId);
    });
  };

  // Function to send the PUT request
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form behavior
    setLoading(true); //
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${selectedTodoId}/update-taggings`;

        const response = await axios.put(
          apiUrl,
          { users: selectedUserIds },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setTodoData((prevTodoData) => ({
            ...prevTodoData,
            posts: prevTodoData.posts.filter(
              (post) => post.id !== selectedTodoId
            ),
            todoCount: prevTodoData.todoCount - 1,
          }));

          await fetchRecords();
          setShowSelect(false);
          setSelectedTodoId(null);
          setSelectedUserIds([]);
          toastRef.current.showToast("MainPost Assigned Successfully");

          console.log("Success:", response.data);
        } else {
          console.error("Failed to update status:", response.status);
        }
      } else {
        console.error("No token found in localStorage");
        // Handle missing token scenario
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };
  const handleSortButtonClick = (sortBy) => {
    sortData(sortBy);
  };

  const handleCloseModalBookMark = () => {
    setBookMarkModal(false);
    setBookMarkId(null);
  };
  const handleBookMarkModal = (id) => {
    setBookMarkModal(true);
    setBookMarkId(id);
  };

  const fetchRecords = async () => {
    try {
      // setLoading(true);

      if (isLastPage) {
        // Proceed only if it's not the last page
        const resp = await fetchTodoRecords(
          userId || groupId,
          activeTab,
          currentPage,
          groupId === null ? "user" : "group"
        );

        // Check if response contains data
        if (resp?.data?.posts) {
          const updatedPosts = resp.data.posts.map((post) => ({
            ...post,
            created_at: formatDate(post.created_at), // Assumes post.created_at is already a string
          }));

          setTodoData({
            todoCount: resp.data.still_working?.count || 0,
            approvedCount: resp.data.approved?.count || 0,
            deleteCount: resp.data.deleted?.count || 0,
            posts: [...posts, ...updatedPosts],
          });

          setHasMore(
            resp.data.pagination?.current_page < resp.data.pagination?.last_page
          );
          setCurrentPage(resp.data.pagination?.current_page + 1);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Ensure loading state is turned off
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecords(); // Fetch records on component mount
  }, []);

  const handleDelete = (pending, id) => {
    const pendingValue = Number(pending);

    if (pendingValue > 0) {
      setShowModal(true);
    } else {
      setShowModal1(true);
      // Pass the 'id' parameter to handleDeleteCall
      setDeleteIds([id]);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setCheckedCheckboxId(null);
  };
  const handleCloseModalUpdate = () => {
    setUpdatedModal(false);
    setUpdateId(null);
    setCheckedCheckboxId(null);
  };
  const handleCloseModalNotDone = () => {
    setNotDoneModal(false);
    setNotDoneId(null);
    // setCheckedCheckboxId(null)
  };
  const handleCloseModalRestore = () => {
    setRestoreModal(false);
    setRestoreId(null);
    // setCheckedCheckboxId(null)
  };
  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const handleDeleteCallYes = () => {
    // Check if deleteIds is not null or undefined before calling handleDeleteCall
    if (deleteIds) {
      handleDeleteCall(deleteIds[0]);
    }
    handleCloseModal1();
  };

  const handleDeleteCall = async (id) => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${id}/update-status`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const user_group_id = userId || groupId;
        const is_user = userId === null ? "user" : "group";
        const postData = {
          status: "deleted",
          users: is_user === "user" ? [user_group_id] : [],
          groups: is_user === "group" ? [user_group_id] : [],
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (response.status === 200) {
          console.log("Deleted successfully");

          // Update the state to reflect the changes
          setTodoData((prevTodoData) => ({
            ...prevTodoData,
            todoCount: prevTodoData.todoCount - 1,
            deleteCount: prevTodoData.deleteCount + 1,
            posts: prevTodoData.posts.filter((post) => post.id !== id),
          }));
          setShowModal1(false);
          // toast.success("MainPost Status Deleted  Successfully");
          toastRef.current.showToast("MainPost Status Updated Successfully");
        }
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  //radio box change for still working code
  const handleDoneCall = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${updateId}/update-status`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const user_group_id = userId || groupId;
        const is_user = userId === null ? "user" : "group";

        const postData = {
          status: "approved",
          users: is_user === "user" ? [user_group_id] : [],
          groups: is_user === "group" ? [user_group_id] : [],
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (response.status === 200) {
          console.log("Status changed to approved");
          setTodoData((prevTodoData) => ({
            ...prevTodoData,
            todoCount: prevTodoData.todoCount - 1,
            approvedCount: prevTodoData.approvedCount + 1,
            posts: prevTodoData.posts.filter((post) => post.id !== updateId),
          }));

          // initialFetchTodos(); // Assuming this is an asynchronous operation
          setUpdatedModal(false);
          setUpdateId(null);
          setShowModal1(false);
        }
        // toast.success("MainPost Status Updated  Successfully");
        toastRef.current.showToast("MainPost Status Updated Successfully");
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };
  const handleDone = (pending, id) => {
    const pendingValue = Number(pending);

    if (pendingValue > 0) {
      setCheckedCheckboxId(id);
      setShowModal(true);
    } else {
      // handleDoneCall(id);
      setUpdatedModal(true);
      setUpdateId(id);
      setCheckedCheckboxId(id);
    }
  };

  const handleNotDoneCall = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${notDoneId}/update-status`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const user_group_id = userId || groupId;
        const is_user = userId === null ? "user" : "group";

        const postData = {
          status: "still_working",
          users: is_user === "user" ? [user_group_id] : [],
          groups: is_user === "group" ? [user_group_id] : [],
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (response.status === 200) {
          console.log("Status changed to approved");
          setNotDoneModal(false);
          setNotDoneId(null);

          setTodoData((prevTodoData) => ({
            ...prevTodoData,
            todoCount: prevTodoData.todoCount + 1,
            approvedCount: prevTodoData.approvedCount - 1,
            posts: prevTodoData.posts.filter((post) => post.id !== notDoneId),
          }));
          // toast.success("MainPost Status Updated  Successfully");
          toastRef.current.showToast("MainPost Status Updated Successfully");
        }
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };
  const handleNotDone = (pending, id) => {
    setCheckedState((prevCheckedStates) => ({
      ...prevCheckedStates,
      [id]: !prevCheckedStates[id], // Toggle the state for the specific checkbox
    }));
    const pendingValue = Number(pending);

    if (pendingValue > 0) {
      // setCheckedCheckboxId(id);
      setShowModal(true);
    } else {
      // handleNotDoneCall(id);
      setNotDoneModal(true);
      setNotDoneId(id);
      // setCheckedCheckboxId(id);
    }
  };

  const handleRestore = (id) => {
    setRestoreId(id);
    setRestoreModal(true);
  };

  const handleRestoreClick = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${restoreId}/update-status`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const user_group_id = userId || groupId;
        const is_user = userId === null ? "user" : "group";
        const postData = {
          status: "still_working",
          users: is_user === "user" ? [user_group_id] : [],
          groups: is_user === "group" ? [user_group_id] : [],
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        console.log("restored successfully");
        setRestoreId(null);
        setRestoreModal(false);
        setTodoData((prevTodoData) => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount + 1,
          deleteCount: prevTodoData.deleteCount - 1,
          posts: prevTodoData.posts.filter((post) => post.id !== restoreId),
        }));
        // toast.success("MainPost Restored Successfully");
        toastRef.current.showToast("MainPost Restored Successfully");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  function toCamelCase(text) {
    return text
      .replace(/\s*-\s*/g, "-") // Remove spaces around hyphens
      .replace(/(\d)([A-Za-z])/g, "$1 $2") // Add space between numbers and letters
      .replace(/([A-Za-z])(\d)/g, "$1 $2") // Add space between letters and numbers
      .replace(/(\n|\r\n)/g, " ") // Replace line breaks with a space
      .replace(/https?:\/\/\S+/g, (url) => `|${url}|`) // Isolate URLs temporarily
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .replace(/ \|/g, "|") // Remove space before URL delimiter
      .replace(/\| /g, "|") // Remove space after URL delimiter
      .replace(/\|/g, "") // Remove URL delimiters
      .replace(/\s*([.,?!;:])/g, "$1") // Remove spaces before punctuation
      .replace(/([.,?!;:])\s*/g, "$1 ") // Add space after punctuation
      .trim() // Trim leading and trailing spaces
      .split(/\s+/) // Split by any spaces
      .map(
        (word) =>
          /^https?:\/\//.test(word) // Leave URLs unchanged
            ? word
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter
      )
      .join(" ") // Join words with a single space
      .replace(/\s+/, " "); // Ensure no extra spaces remain between words
  }

  // Test example
  const inputText =
    "Home page cart value not getting refreshed, please check. Also, other cart spelling mistake, it is now other card. https://www.awesomescreenshot.com/video/30973111?key=9d0904d4c053be84feea844523aa1d5a";
  console.log(toCamelCase(inputText));

  // Usage

  const handleBookMark = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        setTodoData((prevData) => {
          const updatedPosts = prevData?.posts?.map((p) =>
            p.id === bookMarkId ? { ...p, is_saved: !p.is_saved } : p
          );
          return {
            ...prevData,
            posts: updatedPosts,
          };
        });
        await saveBookMark(bookMarkId);
        setBookMarkModal(false);
        setBookMarkId(null);
        initialFetchTodos();
        console.log("Bookmark action successful");
      } else {
        console.error("Token not available");
      }
    } catch (error) {
      console.error("Error in handleBookMark:", error);
    }
  };

  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };

  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
    setVideoFile(null);
    setImageFile(null);
    setPreviewVisible(true);
  };
  const formatDateToLocalISO = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().split("T")[0];
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      const title = e.target.title.value;

      const user_group_id = userId || groupId;
      const is_user = userId !== null;
      const youtube_url = e.target.video_url.value;

      let img_video_url = youtube_url;
      if (file) {
        img_video_url = await uploadFileToS3(file);
      } else {
        console.error("File is undefined");
        // Handle the error or provide a fallback behavior
      }

      let thumbnail_url = img_video_url;
      if (thumbnail) thumbnail_url = await uploadFileToS3(thumbnail);
      // const formattedSelectedDate = selectedDate.toISOString().split("T")[0];
      const formattedSelectedDate = formatDateToLocalISO(selectedDate);

      const apiUrl = `${API_BASE_URL}/api/v1/posts`;
      const headers = {
        Authorization: `Bearer ${storedToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const postData = getTodoReqParams(
        title,
        img_video_url,
        thumbnail_url,
        user_group_id,
        is_user,
        null,
        formattedSelectedDate
      );

      fetch(apiUrl, { method: "POST", headers: headers, body: postData })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((response) => {
          const resp = response?.data || {};
          console.log("resp", resp);

          // Create a new array with the added object at the beginning
          const newPosts = [resp, ...todoData.posts];

          // Update todoCount and posts in the state
          setTodoData((prevTodoData) => ({
            ...prevTodoData,
            todoCount: prevTodoData.todoCount + 1,
            posts: newPosts,
          }));
          setTitle1("");
          setFile(null);
          setThumbnail(null);
          setShowCalander(false); // Hide the calendar
          setSelectedDate(getInitialDate());
          // toast.success("MainPost Added Successfully");
          toastRef.current.showToast("MainPost Added Successfully");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleInputChange = (value) => {
    setInputValueText(value);
  };

  // const handleImageChange = async (event) => {
  //   // setUrl(null); // Reset URL
  //   // setThumbnailUrl(null); // Reset thumbnail URL
  //   const file = event.target.files[0];
  //   if (file) {
  //     if (file.type.startsWith("image")) {
  //       setImageFile(file);
  //       // Clear videoFile state when image file is selected

  //       setVideoFile(null);
  //     } else if (file.type.startsWith("video")) {
  //       setVideoFile(file);
  //       // Clear imageFile state when video file is selected
  //       setImageFile(null);

  //       // Generate thumbnail URL
  //       const thumbnailBlob = await videoThumbnail(file);
  //       console.log("Thumbnail Blob:", thumbnailBlob); // Log thumbnail blob
  //       if (thumbnailBlob) {
  //         const thumbnailUrl = await uploadFileToS3(
  //           thumbnailBlob,
  //           "image/jpeg"
  //         );
  //         console.log("Thumbnail URL:", thumbnailUrl); // Log thumbnail URL
  //         setThumbnailUrl(thumbnailUrl);
  //       }
  //     }
  //     setPreviewVisible(false);
  //   }
  // };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    setShowUploadPopup(true);

    if (file) {
      if (file.type.startsWith("image")) {
        setImageFile(file);
        // Clear videoFile state when image file is selected
        setVideoFile(null);

        // Generate thumbnail URL
        const thumbnailBlob = await videoThumbnail(file);
        console.log("Thumbnail Blob:", thumbnailBlob); // Log thumbnail blob
        if (thumbnailBlob) {
          const thumbnailUrl = await uploadFileToS3(
            thumbnailBlob,
            "image/jpeg",
            // Progress callback function to track upload progress
            (progress) => setUploadProgress(progress)
          );
          console.log("Thumbnail URL:", thumbnailUrl); // Log thumbnail URL
          setThumbnailUrl(thumbnailUrl);
        }

        // Hide upload popup after thumbnail generation
        setShowUploadPopup(false);
      } else if (file.type.startsWith("video")) {
        setVideoFile(file);
        // Clear imageFile state when video file is selected
        setImageFile(null);

        // Show upload popup

        // Generate thumbnail URL
        const thumbnailBlob = await videoThumbnail(file);
        console.log("Thumbnail Blob:", thumbnailBlob); // Log thumbnail blob
        if (thumbnailBlob) {
          const thumbnailUrl = await uploadFileToS3(
            thumbnailBlob,
            "image/jpeg",
            // Progress callback function to track upload progress
            (progress) => setUploadProgress(progress)
          );
          console.log("Thumbnail URL:", thumbnailUrl); // Log thumbnail URL
          setThumbnailUrl(thumbnailUrl);
        }

        // Hide upload popup after thumbnail generation
        setShowUploadPopup(false);
      }
      setPreviewVisible(false);
    }
  };

  const handleCloseEditComment = () => {
    setEditingComment(false);
    setPreviewVisible(true);
    setVideoFile(null);
    setImageFile(null);
    // Update editingComment state to false
  };
  const handleCommentEditing = (id, comment, url, mediatype, image) => {
    setEditingComment(true);
    setCommentEditId(id);
    setInputValueText(comment);
    setMediaType(mediatype);
    setUrl(url);
    setImageUrl(url);
    setVideoThumbnailUrl(image); // Set imageUrl state variable with the URL of the image
    console.log("media type", id, comment, url, mediaType);
    console.log("url", url);
    setVideoFile(videoFile);
    setImageFile(imageFile);
    setPreviewVisible(true);

    setTimeout(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto"; // Reset height to auto to get the correct scrollHeight
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to the scrollHeight
      }
    }, 0);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = textarea.scrollHeight + "px"; // Set height to the scrollHeight
    }
  }, [inputValueText]);

  const handleImageVedioDelete = async () => {
    setUrl(null); // Reset URL
    setThumbnailUrl(null);
    console.log("Previous imageUrl:", imageUrl); // Log previous imageUrl
    console.log("Previous videoThumbnailUrl:", videoThumbnailUrl); // Log previous videoThumbnailUrl

    // If there is a previous image URL, set it to null (delete from local state)
    if (mediaType === "image") {
      setImageUrl(null);
      console.log("ImageUrl set to null"); // Log that imageUrl set to null
      // If you need to delete from the server, call your delete function here
      // Example: await deleteImageFromServer(imageUrl);
      setMediaType("text"); // Set mediaType to "text"
    }

    // If there is a previous video thumbnail URL, set it to null (delete from local state)
    if (mediaType === "video") {
      setVideoThumbnailUrl(null);
      console.log("VideoThumbnailUrl set to null"); // Log that videoThumbnailUrl set to null
      // If you need to delete from the server, call your delete function here
      // Example: await deleteVideoThumbnailFromServer(videoThumbnailUrl);
      setMediaType("text"); // Set mediaType to "text"
    }

    // Reset other state variables
    setVideoFile(null);
    setImageFile(null);
    setPreviewVisible(false);
  };
  async function fetchToDoDataPost(id) {
    return fetch(`${API_BASE_URL}/api/v1/posts?post_id=${id}&type=to-do`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if required
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data
        console.log("posted data", data);
        // You can process the data further or return it as needed
        return data;
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with the fetch operation:", error);
        // You can rethrow the error or handle it as needed
        throw error;
      });
  }
  const handleUpdateComment = async (event, postId) => {
    event.preventDefault();
    console.log("clicked");
    try {
      const storedToken = localStorage.getItem("token");
      setShowUploadPopup(true); // Show the upload progress popup

      if (storedToken) {
        // Make a PUT request to update the post
        const apiUrl = `${API_BASE_URL}/api/v1/posts/${commentEditId}`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };

        const user_group_id = userId || groupId;
        const is_user = userId !== null;

        // Check if there are image or video files to upload
        let imageUrl = url; // Initialize with current URL
        let videoUrl = url; // Initialize with current URL
        let updatedMediaType = mediaType; // Initialize with current mediaType

        if (imageFile && imageFile.type.startsWith("image")) {
          // Upload image file to server or S3
          imageUrl = await uploadFileToS3(imageFile); // Implement this function
          updatedMediaType = "image"; // Update mediaType to image
          videoUrl = imageUrl; // Pass imageUrl to both video_image and video_url
        }

        if (videoFile && videoFile.type.startsWith("video")) {
          // Upload video file to server or S3
          videoUrl = await uploadFileToS3(videoFile); // Implement this function
          updatedMediaType = "video"; // Update mediaType to video

          // Generate thumbnail image from video
          const thumbnailBlob = await videoThumbnail(file); // Use the videoFile as input to generate thumbnail
          if (thumbnailBlob) {
            // Upload the generated thumbnail to server or S3
            imageUrl = await uploadFileToS3(thumbnailBlob, "image/jpeg");
            console.log("Thumbnail URL:", imageUrl); // Log thumbnail URL
          }
        }

        // Ensure that the thumbnail image URL ends with .jpg extension
        if (imageUrl && !imageUrl.endsWith(".jpg")) {
          const lastIndex = imageUrl.lastIndexOf(".");
          imageUrl = imageUrl.substring(0, lastIndex) + ".jpg";
        }

        // Prepare post data
        const postData = getTodoReqParams(
          inputValueText,
          videoUrl, // Pass videoUrl to video_url
          thumbnailUrl, // Pass imageUrl to video_image
          user_group_id,
          is_user,
          updatedMediaType // Use updated mediaType
        );

        // Make the PUT request
        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: postData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log(response);
        // Update the state and reset form values
        // initialFetchTodos();
        const resp = await fetchToDoDataPost(postId);
        const rec = resp?.data?.posts[0];
        const newPosts = posts?.map((e) => {
          if (e) {
            if (e?.id === postId) {
              console.log("rec", resp);
              return rec;
            } else {
              return e;
            }
          }
        });
        setTodoData({ ...todoData, posts: newPosts });
        setEditingComment(false);
        setMediaType(null);
        setUrl(null);
        setCommentEditId(null);
        setImageFile(null);
        setVideoFile(null);
        setShowUploadPopup(false);
        // toast.success("MainPost Updated Successfully");
        toastRef.current.showToast("MainPost Updated Successfully");
        console.log("all the posts", posts);
      }
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={posts.length}
        next={() => fetchRecords()}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        // loader={
        //   loading ? (
        //     <p className="text-center text-muted">Loading...</p>
        //   ) : null
        // }
      >
        {activeTab === "still_working" ? (
          <div className="custom-infinite">
            <div className="input-group mb-1 mt-1  ">
              <input
                type="text"
                className="form-control searchtodo ps-4 rounded-start-pill cursor-point shadow-none"
                placeholder="Search"
                name=""
                id="searchTodo"
                // value={searchQuery}
                // onChange={handleSearch}
                autoComplete="off"
              />
              <div className="input-group-append searchtodo ">
                <span className="input-group-text greysearchimg   cursor-point">
                  <img
                    src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                    width="23px"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div
              className="scrollableMain"
              ref={scrollableMainRef}
              id="scrollableDiv"
            >
              <ul className="list-inline">
                {posts?.map((post, index) => (
                  <>
                    <div
                      className="card col-12   tdata"
                      key={`${index}${index + 1}`}
                    >
                      <div className="container text-center">
                        <div className="row">
                          <div className="col-sm-1 col-2  p-0 d-flex align-items-center justify-content-evenly">
                            <div className="indexnum fw-medium d-flex align-items-center pe-3">
                              {index + 1}.
                            </div>
                            <div className="form-check d-flex align-items-center">
                              <Tippy content="checkbox">
                                <input
                                  className="form-check-input custm-checkbox cursor-point"
                                  type="checkbox"
                                  name="checkboxes"
                                  id={`defaultCheck1${post.id}`}
                                  checked={checkedCheckboxId === post.id}
                                  onChange={() =>
                                    handleDone(post.pending_tasks, post.id)
                                  }
                                  onClick={() => console.log(post.id)}
                                />
                              </Tippy>
                            </div>
                          </div>

                          <div className="col-sm-9 col-10 d-flex align-items-center notes main-postp ">
                            <span className="d-flex align-items-center pe-2">
                              <Tippy
                                content={
                                  post.media_type === "video"
                                    ? "Video"
                                    : post.media_type === "image"
                                    ? "Image"
                                    : ""
                                }
                              >
                                <a
                                  className={`ps-1 cursor-point ${
                                    post.media_type !== "video" &&
                                    post.media_type !== "image"
                                      ? "hidden-popup"
                                      : ""
                                  }`}
                                >
                                  {post.media_type === "video" ||
                                  post.media_type === "image" ? (
                                    <Popup
                                      url={post.video_url || post.video_image}
                                      isVideo={post.media_type === "video"}
                                      text={toCamelCase(post.notes)}
                                    />
                                  ) : (
                                    <Popup
                                      url={post.video_url || post.video_image}
                                      text={toCamelCase(post.notes)}
                                      className="hidden-popup"
                                    />
                                  )}
                                </a>
                              </Tippy>
                            </span>
                            <span className="d-flex doneheading align-items-center pt-1">
                              {editingComment && commentEditId === post.id ? (
                                <div className="col-12  d-flex edit-form ">
                                  <>
                                    <label className="custom-file-upload  pe-2 d-flex align-items-center pb-2">
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        id="mediaFile"
                                        name="files"
                                        accept="image/*, video/*"
                                        onChange={handleImageChange}
                                      />
                                      <img
                                        src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                        className=""
                                        alt=""
                                        width="30px"
                                        height="25px"
                                      />
                                    </label>
                                    <span className="pt-2">
                                      <textarea
                                        ref={textareaRef}
                                        type="text"
                                        className="form-control shadow-none mobile_height"
                                        value={inputValueText}
                                        cols="100"
                                        onChange={(e) =>
                                          setInputValueText(e.target.value)
                                        }
                                      ></textarea>
                                    </span>

                                    <span className="d-flex my-3">
                                      <button
                                        className="btn d-flex align-items-center justify-content-center mx-3"
                                        onClick={(e) =>
                                          handleUpdateComment(e, post.id)
                                        }
                                      >
                                        <Tippy content="save">
                                          <i className="bi bi-check-lg"></i>
                                        </Tippy>
                                      </button>
                                      <button
                                        className="btn btn-secondary d-flex align-items-center justify-content-center"
                                        onClick={handleCloseEditComment}
                                      >
                                        <Tippy content="delete">
                                          <i className="bi bi-x"></i>
                                        </Tippy>
                                      </button>
                                    </span>

                                    {previewVisible && (
                                      <div className="px-2 py-1 position-relative">
                                        {mediaType === "image" && imageUrl && (
                                          <div className="preview-container">
                                            <img
                                              src={imageUrl}
                                              className=""
                                              alt=""
                                              width="40px"
                                              height="45px"
                                            />
                                            <i
                                              className="fa fa-times text-dark position-absolute top-0 end-0 mt-1 me-2"
                                              onClick={handleImageVedioDelete}
                                            ></i>
                                          </div>
                                        )}
                                        {mediaType === "video" &&
                                          videoThumbnailUrl && (
                                            <div className="preview-container">
                                              <img
                                                src={videoThumbnailUrl}
                                                className=""
                                                alt=""
                                                width="40px"
                                                height="45px"
                                              />
                                              <i
                                                className="fa fa-times text-dark position-absolute top-0 end-0 mt-1 me-1"
                                                onClick={handleImageVedioDelete}
                                              ></i>
                                            </div>
                                          )}
                                      </div>
                                    )}

                                    <div className="image-preview-container px-2 py-3">
                                      {(videoFile || imageFile) && (
                                        <ImagePreview
                                          file={videoFile || imageFile}
                                          videoUrl={url} // Pass the video URL
                                          thumbnailUrl={thumbnailUrl} // Pass the thumbnail URL
                                          onDelete={handleFileDelete}
                                        />
                                      )}
                                    </div>
                                  </>

                                  {showUploadPopup && (
                                    <div className="upload-popup">
                                      <p>Done Uploading: {uploadProgress}%</p>
                                      <div className="progress-bar">
                                        <div
                                          className="progress"
                                          style={{
                                            width: `${uploadProgress}%`,
                                            backgroundColor: "#007bff",
                                            height: "8px",
                                            borderRadius: "4px",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="position">
                                  <p className="m-0 fs-6 fw-medium  cursor-point">
                                    <Link
                                      to={`/todo-list/${post.id}?status=still_working`}
                                      className="link-textColor"
                                    >
                                      <span
                                        className="font-break mainPostFont"
                                        onClick={resetCounts}
                                      >
                                        {toCamelCase(post.notes)}
                                        {post.order_id
                                          ? `-${post.order_id}`
                                          : null}
                                      </span>
                                    </Link>
                                    <Tippy content="Edit">
                                      <span
                                        className="ps-4 cursor-point hide-on-small-devices" // Apply hide-on-small-devices class
                                        onClick={() =>
                                          handleCommentEditing(
                                            post.id,
                                            post.notes,
                                            post.video_url,
                                            post.media_type,
                                            post.video_image
                                          )
                                        }
                                      >
                                        <img
                                          src="https://yesdone.com/build/assets/pencil-bc3d8d52.png"
                                          alt=""
                                          className="pencilbox"
                                          width="12px"
                                          height="12px"
                                        />
                                      </span>
                                    </Tippy>
                                  </p>
                                  <div className="gap-2 d-flex">
                                    <span className="day text-muted createdAt pt-1">
                                      by {post.creator.name} on{" "}
                                      {post.created_at}
                                    </span>
                                    <Tippy content="Move">
                                      <span className="button-container cursor-point pe-1">
                                        <FaFileExport
                                          onClick={() =>
                                            handleMoveButtonClick(post?.id)
                                          }
                                          className="button-icon"
                                        />
                                      </span>
                                    </Tippy>
                                    <Tippy content="Reply">
                                      <span
                                        className="button-container cursor-pointer pe-1"
                                        onClick={() =>
                                          openModal(post?.id, post.notes)
                                        }
                                      >
                                        <FaComments className="button-icon" />
                                      </span>
                                    </Tippy>
                                    <Tippy content="Copy to Clipboard">
                                      <span
                                        className="button-container cursor-pointer pe-1"
                                        onClick={() =>
                                          navigator.clipboard.writeText(
                                            post.notes
                                          )
                                        }
                                      >
                                        <img
                                          src={copy}
                                          width="15px"
                                          height="15px"
                                        />
                                      </span>
                                    </Tippy>
                                  </div>
                                  <div className="d-flex">
                                    <span>
                                      {post.replies && post.replies.length > 0
                                        ? post.replies.map((reply) => (
                                            <>
                                              <div className="text-start">
                                                <span
                                                  key={reply.id}
                                                  className="font-break mainPostFont ps-4"
                                                >
                                                  <span className="font-break mainReplyPostFont pb-0">
                                                    {reply.comment}
                                                  </span>
                                                </span>
                                                <span
                                                  className="ps-1"
                                                  onClick={() =>
                                                    openModalReply(
                                                      reply?.id,
                                                      reply.comment
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src="https://yesdone.com/build/assets/pencil-bc3d8d52.png"
                                                    alt=""
                                                    className="pencilbox"
                                                    width="12px"
                                                    height="12px"
                                                  />
                                                </span>
                                              </div>
                                              <span className="day text-muted createdAt pt-1 ps-4">
                                                by {reply.creator.name} on{" "}
                                                {reply.created_at}
                                              </span>
                                            </>
                                          ))
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              )}

                              <div className="position"></div>
                            </span>
                          </div>
                          <div className="col-sm-2 col-5 d-flex flex-column align-items-center justify-content-evenly icons ">
                            <div class="d-flex justify-content-start w-100">
                              <span className="pe-1 icon-padding">
                                {post.pending_tasks > 0 ? (
                                  <span className="pe-2 cursor-point">
                                    <span className="text-mute">
                                      {post.pending_tasks}{" "}
                                    </span>
                                    <Tippy content="pendingtask">
                                      <img
                                        src="https://yesdone.com/build/assets/time-9c77e909.png"
                                        width="20px"
                                        height="17px"
                                        alt=""
                                      />
                                    </Tippy>
                                  </span>
                                ) : (
                                  <span className="pe-1  hidden-popup">
                                    {post.pending_tasks}{" "}
                                    <Tippy content="pendingtask">
                                      <img
                                        src="https://yesdone.com/build/assets/time-9c77e909.png"
                                        width="20px"
                                        height="17px"
                                        alt=""
                                      />
                                    </Tippy>
                                  </span>
                                )}
                              </span>
                              <Tippy content="edit">
                                <span
                                  className="cursor-point pe-2 hide-on-large-devices"
                                  onClick={() =>
                                    handleCommentEditing(
                                      post.id,
                                      post.notes,
                                      post.video_url,
                                      post.media_type,
                                      post.video_image
                                    )
                                  }
                                >
                                  <img
                                    src="https://yesdone.com/build/assets/pencil-bc3d8d52.png"
                                    alt=""
                                    className="pencilbox"
                                    width="12px"
                                    height="12px"
                                  />
                                </span>
                              </Tippy>
                              <Link
                                to={`/posts/${post.id}/comments`}
                                className="pe-1 icon-padding"
                              >
                                <Tippy content="comments">
                                  {post.total_comments > 0 ? (
                                    <img
                                      src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                      alt="Green Message"
                                      width="17px"
                                      height="17px"
                                      className="cursor-point"
                                    />
                                  ) : (
                                    <img
                                      src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                      alt="Grey Message"
                                      width="17px"
                                      height="17px"
                                      className="cursor-point"
                                    />
                                  )}
                                </Tippy>
                              </Link>
                              <Tippy content="delete">
                                <span className="ps-1 icon-padding">
                                  <img
                                    onClick={() => {
                                      handleDelete(post.pending_tasks, post.id);
                                    }}
                                    src="https://yesdone.com/build/assets/bin-15fd3a47.png"
                                    width="15px"
                                    height="17px"
                                    alt="delete"
                                    className="cursor-point"
                                  />
                                </span>
                              </Tippy>
                              <Tippy
                                content={
                                  post.is_saved
                                    ? "Bookmark Checked"
                                    : "Bookmark"
                                }
                              >
                                <span className="ps-1 icon-padding">
                                  <img
                                    onClick={() => {
                                      handleBookMarkModal(post.id);
                                    }}
                                    src={
                                      post.is_saved
                                        ? "https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                        : "https://yesdone.com/build/assets/emptypen-94551c55.png"
                                    }
                                    alt={
                                      post.is_saved
                                        ? "Green Message"
                                        : "Grey Message"
                                    }
                                    width="14px"
                                    height="22px"
                                    className="cursor-point"
                                  />
                                </span>
                              </Tippy>
                            </div>
                            <div className="day fw-medium createdAt pt-1">
                              due date{" "}
                              {formatDateforDue(post?.commission_due_date)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span>
                      {showSelect && selectedTodoId === post?.id && (
                        <div
                          className="modal copyMoveModal"
                          style={{ display: "block" }}
                          onClick={(e) => {
                            // Close the modal if clicking outside of the modal content
                            if (e.target.className === "modal copyMoveModal") {
                              setShowSelect(false);
                            }
                          }}
                        >
                          <div
                            className="modal-content modal-content1"
                            tabIndex="0"
                            onKeyDown={(e) => {
                              // Close the modal when the Escape key is pressed
                              if (e.key === "Escape") {
                                setShowSelect(false);
                              }
                            }}
                            // Focus the modal content to ensure it's listening for the Escape key
                            onFocus={(e) => e.currentTarget.focus()}
                          >
                            {/* Close button to hide the modal */}
                            <button
                              type="button"
                              className="btn-close"
                              aria-label="Close"
                              onClick={() => setShowSelect(false)}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "10px",
                              }}
                            ></button>

                            <div
                              className="accordion pt-5"
                              id="assignTaskAccordion"
                            >
                              <div className="accordion-item mb-4">
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  <button
                                    className="accordion-button collapsed shadow-none mainpost-acrdn border-bottom"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    Assign Task to Your Teammates
                                  </button>
                                </h2>
                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#assignTaskAccordion"
                                >
                                  <div className="accordion-body px-0">
                                    {/* Display filtered teammates */}
                                    {filteredTeammates.length > 0 ? (
                                      <ul
                                        className="list-group d-flex"
                                        style={{
                                          maxHeight: "200px", // Set max height for the list
                                          overflowY: "auto", // Enable vertical scrolling
                                        }}
                                      >
                                        {filteredTeammates
                                          .sort((a, b) =>
                                            a.name && b.name
                                              ? a.name.localeCompare(b.name) // Sort alphabetically by name
                                              : 0
                                          )
                                          .map((item) => (
                                            <li
                                              className="list-group-item border-bottom text-start ps-5"
                                              key={item.id} // Use item.id if available
                                              onClick={() =>
                                                handleUserClick(item.id)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              {selectedUserIds.includes(
                                                item.id
                                              ) && (
                                                <span
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  ✔
                                                </span>
                                              )}
                                              {item.name || "Unnamed Teammate"}
                                            </li>
                                          ))}
                                      </ul>
                                    ) : (
                                      <p>No teammates available</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {loading ? (
                              <button className="btn btn-primary" disabled>
                                <img
                                  src={LoadingImg}
                                  width="20px"
                                  height="20px"
                                  alt="Loading"
                                />
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </span>
                  </>
                ))}
                {/* {loading ? (
                  <p className="text-center text-muted">
                    <img src={LoadingImg} width="20px" height="20px" />
                  </p>
                ) : null}
                {((!hasMore && !loading) || (!isLastPage && !loading)) &&
                  isScrollbarPresent && (
                    <div className="text-black d-flex align-items-center justify-content-center d-none">
                      No Records available
                    </div>
                  )} */}
                <Modal
                  show={modalIsOpen}
                  onHide={closeModal}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Modal.Header className="fs-1 fw-bold text-center border-0">
                    <h5>{todoNotes}</h5>
                    <Button
                      variant="close"
                      onClick={closeModal}
                      aria-label="Close"
                    >
                      &times;
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <textarea
                      type="text"
                      rows={5}
                      placeholder="Enter your reply..."
                      className="reply-input"
                      value={replyText} // Ensure this reflects the comment you want to edit
                      onChange={(e) => setReplyText(e.target.value)} // Update state on change
                    />
                  </Modal.Body>
                  <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                    <Button
                      variant="primary"
                      onClick={submitReply}
                      disabled={loading}
                    >
                      {loading ? (
                        <img
                          src={LoadingImg}
                          alt="Loading"
                          width="20px"
                          height="20px"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModal}
                  onHide={handleCloseModal}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Modal.Header
                    //  closeButton
                    className="fs-1 fw-bold text-center border-0"
                  >
                    <i
                      className="bi bi-exclamation-circle text-center mx-auto text-danger modal-icon"
                      // style={{ fontSize: "85px", color: "#f8bb86 !important" }}
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="">
                    There are pending tasks in this group. Please complete all
                    tasks before you can delete this.
                  </Modal.Body>
                  <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                    <Button variant="primary" onClick={handleCloseModal}>
                      OK
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={updatedModal}
                  onHide={handleCloseModalUpdate}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Modal.Header
                    //  closeButton
                    className="fs-1 fw-bold text-center border-0 mx-auto"
                  >
                    <i
                      className="bi bi-exclamation-circle text-center text-danger modal-icon"
                      // style={{ fontSize: "85px", color: "#f8bb86 !important" }}
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="">
                    Are you sure you want change status ?
                  </Modal.Body>
                  <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                    <Button variant="primary" onClick={handleDoneCall}>
                      Yes
                    </Button>
                    <Button variant="primary" onClick={handleCloseModalUpdate}>
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModal1}
                  onHide={handleCloseModal1}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Modal.Header
                    //  closeButton
                    className="fs-1 fw-bold text-center border-0 mx-auto"
                  >
                    <i
                      className="bi bi-exclamation-circle text-center text-danger modal-icon"
                      // style={{ fontSize: "85px", color: "#f8bb86 !important" }}
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="">
                    Are you sure you want delete this ?
                  </Modal.Body>
                  <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                    <Button variant="primary" onClick={handleDeleteCallYes}>
                      Yes
                    </Button>
                    <Button variant="primary" onClick={handleCloseModal1}>
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>
              </ul>
            </div>
            <div className="addtaskpart py-0 mt-2">
              <form action="" id="AddTask" onSubmit={handleFormSubmit}>
                <div
                  className={`${
                    title1.length > 118 ? "taskborder1" : "taskborder"
                  } pt-0 px-0 pb-1 d-flex align-items-center justify-content-between`}
                >
                  {/* Task Input Textarea */}
                  <textarea
                    type="text"
                    className="form-control add-input px-0 shadow-none border-0"
                    autoComplete="off"
                    required
                    ref={textareaRef}
                    name="title"
                    onChange={(e) => handleTextChange(e)}
                    value={title1}
                    placeholder="+   Name of Task"
                    style={{ flexGrow: 1 }}
                  />

                  {/* Due Date and Calendar */}
                  <div className="position-relative d-flex align-items-center ms-2">
                    <button
                      type="button"
                      className="border-0 text-start calander bg-transparent"
                      onClick={handleShowCalander}
                      style={{ position: "relative", zIndex: 2 }}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <span className="me-2 custom-fontsize">Due date :</span>
                        <span className="custom-fontsize">{formattedDate}</span>
                      </span>
                    </button>
                    {showCalander ? (
                      <div
                        className="position-absolute mt-2 shadow bg-white"
                        style={{
                          zIndex: 999,
                          top: "",
                          bottom: "30px",
                          right: "0px",
                        }}
                      >
                        <Calendar
                          onChange={handleDateChange}
                          value={selectedDate}
                          // minDate={currentDate}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Additional Controls */}
                <div className="d-flex mt-3">
                  <label className="custom-file-upload me-2">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="mediaFile"
                      name="files"
                      accept="image/*, video/*"
                      onChange={handleFileChange}
                    />
                    <img
                      src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                      alt=""
                      width="22px"
                      height="18px"
                    />
                  </label>
                  <textarea
                    type="text"
                    className="form-control add-input shadow-none text-break text-resize border-0"
                    autoComplete="off"
                    name="video_url"
                    placeholder="Youtube Video"
                  />
                  <button type="submit" className="submitclass btn ms-auto">
                    <img
                      src="https://yesdone.com/build/assets/send-99cff475.png"
                      alt="send img"
                      width="35px"
                      height="35px"
                    />
                  </button>
                </div>

                {(thumbnail || file) && (
                  <ImagePreview
                    file={thumbnail || file}
                    onDelete={handleFileDelete}
                  />
                )}
              </form>
            </div>

            <ToastContainer ref={toastRef} timeout={4000} />
          </div>
        ) : activeTab === "approved" ? (
          <div>
            <div className="input-group mb-1 mt-1  ">
              <input
                type="text"
                className="form-control ps-4 rounded-start-pill searchtodo cursor-point shadow-none"
                placeholder="Search"
                name=""
                id="searchTodo"
                // value={searchQuery}
                // onChange={handleSearch}
              />
              <div className="input-group-append searchtodo ">
                <span className="input-group-text searchtodo cursor-point">
                  <img
                    src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                    width="23px"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div
              className="scrollableMainDone"
              ref={scrollableMainRef}
              id="scrollableDiv"
            >
              <ul className="list-inline">
                {posts?.map((post, index) => (
                  <div
                    className="card col-12  mt- tdata"
                    key={`${index}${index + 2}`}
                  >
                    <div className="container text-center">
                      <div className="row">
                        <div className="col-sm-1 col-2 p-0 d-flex align-items-center justify-content-evenly">
                          <div className="indexnum fw-medium d-flex align-items-center pe-3">
                            {index + 1}.
                          </div>
                          <div className="form-check">
                            <Tippy content="checkbox">
                              <input
                                className="form-check-input custm-checkbox cursor-point pe-1"
                                type="checkbox"
                                name="checkboxes"
                                id={`defaultCheck1${post.id}`}
                                checked={checkedState[post.id] || true} // Set to true for initial check
                                onChange={() =>
                                  handleNotDone(post.pending_tasks, post.id)
                                }
                                onClick={() => console.log(post.id)}
                              />
                            </Tippy>
                          </div>
                        </div>

                        <div className="col-sm-9 col-8 d-flex align-items-center notes">
                          <span className="d-flex align-items-center pe-2">
                            <Tippy
                              content={
                                post.media_type === "video"
                                  ? "Video"
                                  : post.media_type === "image"
                                  ? "Image"
                                  : ""
                              }
                            >
                              <a
                                className={`ps-1 cursor-point ${
                                  post.media_type !== "video" &&
                                  post.media_type !== "image"
                                    ? "hidden-popup"
                                    : ""
                                }`}
                              >
                                {post.media_type === "video" ||
                                post.media_type === "image" ? (
                                  <Popup
                                    url={post.video_url || post.video_image}
                                    isVideo={post.media_type === "video"}
                                    text={toCamelCase(post.notes)}
                                  />
                                ) : (
                                  <Popup
                                    url={post.video_url || post.video_image}
                                    text={toCamelCase(post.notes)}
                                    className="hidden-popup"
                                  />
                                )}
                              </a>
                            </Tippy>
                          </span>
                          <span className="d-flex doneheading align-items-center pt-1">
                            <div className="position">
                              <p className="m-0 fs-6 fw-medium  cursor-point">
                                <Link
                                  to={`/todo-list/${post.id}?status=still_working`}
                                  className="link-textColor"
                                >
                                  <span
                                    className="font-break mainPostFont complete"
                                    onClick={() => {}}
                                  >
                                    {toCamelCase(post.notes)}
                                  </span>
                                </Link>
                              </p>
                              <span className="day text-muted createdAt pt-1">
                                by {post.creator.name} on {post.created_at}
                              </span>
                            </div>
                            <div className="position"></div>
                          </span>
                        </div>
                        <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                          <Link
                            to={`/posts/${post.id}/comments`}
                            className="pe-1"
                          >
                            <Tippy content="comments">
                              {post.total_comments > 0 ? (
                                <img
                                  src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                  alt="Green Message"
                                  width="17px"
                                  height="17px"
                                  className="cursor-point"
                                />
                              ) : (
                                <img
                                  src="https://yesdone.com/build/assets/greymessage-7c7383fd.png"
                                  alt="Grey Message"
                                  width="17px"
                                  height="17px"
                                  className="cursor-point"
                                />
                              )}
                            </Tippy>
                          </Link>
                          <Tippy
                            content={
                              post.is_saved ? "Bookmark Checked" : "Bookmark"
                            }
                          >
                            <span className="ps-1">
                              <img
                                onClick={() => {
                                  handleBookMarkModal(post.id);
                                }}
                                src={
                                  post.is_saved
                                    ? "https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                    : "https://yesdone.com/build/assets/emptypen-94551c55.png"
                                }
                                alt={
                                  post.is_saved
                                    ? "Green Message"
                                    : "Grey Message"
                                }
                                width="14px"
                                height="22px"
                                className="cursor-point"
                              />
                            </span>
                          </Tippy>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* {loading ? (
                  <p className="text-center text-muted">
                    <img src={LoadingImg} width="20px" height="20px" />
                  </p>
                ) : null}
                {((!hasMore && !loading) || (!isLastPage && !loading)) &&
                  isScrollbarPresent && (
                    <div className="text-black d-flex align-items-center justify-content-center d-none">
                      No Records available
                    </div>
                  )} */}
                <Modal
                  show={notDoneModal}
                  onHide={handleCloseModalNotDone}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Modal.Header
                    //  closeButton
                    className="fs-1 fw-bold text-center border-0 mx-auto"
                  >
                    <i
                      className="bi bi-exclamation-circle text-center text-danger modal-icon"
                      // style={{ fontSize: "85px", color: "#f8bb86 !important" }}
                    ></i>
                  </Modal.Header>
                  <Modal.Body className="">
                    Are you sure you want change status ?
                  </Modal.Body>
                  <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
                    <Button variant="primary" onClick={handleNotDoneCall}>
                      Yes
                    </Button>
                    <Button variant="primary" onClick={handleCloseModalNotDone}>
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>
              </ul>
            </div>
            <ToastContainer ref={toastRef} timeout={4000} />
          </div>
        ) : (
          <div>
            <div className="input-group mb-1 mt-1 todosearch ">
              <input
                type="text"
                className="form-control ps-4 searchtodo  rounded-start-pill cursor-point shadow-none"
                placeholder="Search"
                name=""
                id="searchTodo"
                // value={searchQuery}
                // onChange={handleSearch}
              />
              <div className="input-group-append searchtodo ">
                <span className="input-group-text greysearchimg cursor-point">
                  <img
                    src="https://yesdone.com/build/assets/searchgrey-628b752d.png"
                    width="23px"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div
              className="scrollableMainDelete"
              ref={scrollableMainRef}
              id="scrollableDiv"
            >
              <ul className="list-inline">
                {posts?.map((post, index) => (
                  <div
                    className="card col-12  mt- tdata"
                    key={`${index}${index + 3}`}
                  >
                    <div className="container text-center">
                      <div className="row">
                        <div className="col-sm-1 col-1 p-0 d-flex align-items-center justify-content-evenly">
                          <div className="indexnum fw-medium d-flex align-items-center pe-3">
                            {index + 1}.
                          </div>
                        </div>

                        <div className="col-sm-9 col-9 d-flex align-items-center notes p-0">
                          <span className="d-flex align-items-center pe-2">
                            <Tippy
                              content={
                                post.media_type === "video"
                                  ? "Video"
                                  : post.media_type === "image"
                                  ? "Image"
                                  : ""
                              }
                            >
                              <a
                                className={`ps-1 cursor-point ${
                                  post.media_type !== "video" &&
                                  post.media_type !== "image"
                                    ? "hidden-popup"
                                    : ""
                                }`}
                              >
                                {post.media_type === "video" ||
                                post.media_type === "image" ? (
                                  <Popup
                                    url={post.video_url || post.video_image}
                                    isVideo={post.media_type === "video"}
                                    text={toCamelCase(post.notes)}
                                  />
                                ) : (
                                  <Popup
                                    url={post.video_url || post.video_image}
                                    text={toCamelCase(post.notes)}
                                    className="hidden-popup"
                                  />
                                )}
                              </a>
                            </Tippy>
                          </span>
                          <span className="d-flex doneheading align-items-center pt-1">
                            <div className="position">
                              <p className="m-0 fs-6 fw-medium  cursor-point">
                                <Link
                                  to={`/todo-list/${post.id}?status=still_working`}
                                  className="link-textColor"
                                >
                                  <span
                                    className="font-break mainPostFont complete"
                                    onClick={() => {}}
                                  >
                                    {toCamelCase(post.notes)}
                                  </span>
                                </Link>
                              </p>
                              <span className="day text-muted createdAt pt-1">
                                by {post.creator.name} on {post.created_at}
                              </span>
                            </div>
                            <div className="position"></div>
                          </span>
                        </div>
                        <div className="col-sm-2 col-2 d-flex align-items-center justify-content-end">
                          <div className="col-4">
                            <Tippy content="restore">
                              <i
                                className="fa fa-window-restore text-dark pt-3 cursor-pointer"
                                aria-hidden="true"
                                // onClick={() => handleRestoreClick(post.id)}
                                onClick={() => handleRestore(post.id)}
                              ></i>
                            </Tippy>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* {loading ? (
                  <p className="text-center text-muted">
                    <img src={LoadingImg} width="20px" height="20px" />
                  </p>
                ) : null}
                {((!hasMore && !loading) || (!isLastPage && !loading)) &&
                  isScrollbarPresent && (
                    <div className="text-black d-flex align-items-center justify-content-center d-none">
                      No Records available
                    </div>
                  )} */}
              </ul>
            </div>
            <ToastContainer ref={toastRef} timeout={4000} />
          </div>
        )}
      </InfiniteScroll>

      <Modal
        show={restoreModal}
        onHide={handleCloseModalRestore}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 mx-auto"
        >
          <i
            className="bi bi-exclamation-circle text-center text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleRestoreClick}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseModalRestore}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={bookMarkModal}
        onHide={handleCloseModalBookMark}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 mx-auto"
        >
          <i
            className="bi bi-exclamation-circle text-center text-danger modal-icon"
            // style={{ fontSize: "85px", color: "#f8bb86 !important" }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleBookMark}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseModalBookMark}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MainPost;
