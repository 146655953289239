// import React, { useState } from "react";
// import LoadingImg from "../../images/loading.webp";
// import loginImage from "../../images/app_logo-02d8f0cb.png";
// import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// const ForgetPassword = () => {
//   const [email, setEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [loginError, setLoginError] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isEmailSent, setIsEmailSent] = useState(false);
//   const [verifiedEmail, setVerifiedEmail] = useState(null);
//   const [isOTPVerified, setIsOTPVerified] = useState(false);
//   const [showPasswordFields, setShowPasswordFields] = useState(false);
//   const navigate = useNavigate();
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       const response = await fetch(
//         "https://beta.yesdone.com/api/v1/forgot-password",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ email }),
//         }
//       );

//       const responseData = await response.json();

//       if (response.ok && responseData.success) {
//         console.log(responseData);
//         setIsEmailSent(true);
//         setVerifiedEmail(email);
//         toast.success("Email sent successfully!");
//       } else {
//         console.error(responseData.message);
//         toast.error("Pls Enter Valid Email!");
//       }
//     } catch (error) {
//       console.error(error);
//       setLoginError(error.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleVerify = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       // Check if OTP is empty
//       if (!otp.trim()) {
//         throw new Error("Please enter OTP");
//       }

//       const response = await fetch(
//         "https://beta.yesdone.com/api/v1/verify-otp",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ email, otp }), // Send email and entered OTP to verify
//         }
//       );

//       const responseData = await response.json();

//       if (response.ok) {
//         console.log(response);
//         if (responseData && responseData.success) {
//           setIsOTPVerified(true); // OTP verified successfully
//           setShowPasswordFields(true); // Show password fields after OTP verification
//           toast.success("OTP verified successfully!"); // Show success toast message
//         } else {
//           throw new Error("Invalid OTP");
//         }
//       } else {
//         throw new Error(responseData.message || "Error verifying OTP");
//       }
//     } catch (error) {
//       console.log("error", error);
//       setLoginError(error.message);
//       toast.error(error.message); // Show error toast message
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleUpdatePassword = async (e) => {
//     e.preventDefault(); // Prevent default form submission behavior

//     try {
//       const response = await fetch(
//         "https://beta.yesdone.com/api/v1/reset-password",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ email: verifiedEmail, password: newPassword }),
//         }
//       );

//       if (response.ok) {
//         toast.success("Password changed successfully!");
//         navigate("/login"); // Navigate to the login page
//       } else {
//         const errorData = await response.json();
//         throw new Error(errorData.message);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };
//   // Initialize useNavigate

//   const handleGoBack = () => {
//       // Navigate back to the previous screen
//       navigate(-1);
//   };


//   return (
//     <div className="container d-flex justify-content-center align-items-center vh-100">
//       <div className="row">
//         <div className="col-12 login">
//           <div className="card login-card">
//             <img
//               src={loginImage}
//               className="card-img-top cardimage mx-auto py-5"
//               width="170px"
//               alt="yesdonelogo"
//             />
//             <div className="card-body text-center px-5">
//               {verifiedEmail && (
//                 <div className="mb-3">Email: {verifiedEmail}</div>
//               )}
//               {!isEmailSent ? (
//                 <>
//                   <h5 className="card-title fw-medium fs-6 text-start">
//                     Email Address
//                   </h5>
//                   <form onSubmit={handleSubmit}>
//                     <input
//                       type="email"
//                       placeholder="Email Address"
//                       className="form-card "
//                       value={email}
//                       onChange={(e) => setEmail(e.target.value)}
//                     />
//                     {loginError && (
//                       <div className="text-danger fs-6 mb-3">{loginError}</div>
//                     )}
//                     {isLoading ? (
//                       <button
//                         className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2"
//                         disabled
//                       >
//                         <img
//                           src={LoadingImg}
//                           width="20px"
//                           height="20px"
//                           alt="Loading"
//                         />
//                       </button>
//                     ) : (
//                       <button
//                         type="submit"
//                         className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2"
//                       >
//                         Sending Otp To Mail
//                       </button>
//                     )}
//                   </form>
//                 </>
//               ) : (
//                 <>
//                   {!isOTPVerified ? (
//                     <>
//                       <h5 className="card-title fw-medium fs-6 text-start">
//                         Verification Code
//                       </h5>
//                       <form onSubmit={handleVerify}>
//                         <input
//                           type="text"
//                           placeholder="Enter OTP"
//                           className="form-card "
//                           value={otp}
//                           onChange={(e) => setOtp(e.target.value)}
//                         />
//                         {loginError && (
//                           <div className="text-danger fs-6 mb-3">
//                             {loginError}
//                           </div>
//                         )}
//                         {isLoading ? (
//                           <button
//                             className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2"
//                             disabled
//                           >
//                             <img
//                               src={LoadingImg}
//                               width="20px"
//                               height="20px"
//                               alt="Loading"
//                             />
//                           </button>
//                         ) : (
//                           <button
//                             type="submit"
//                             className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2"
//                           >
//                             Verify OTP
//                           </button>
//                         )}
//                         <h5 className="card-title fw-medium fs-6 text-start text-danger pt-3 text-break">
//                           {" "}
//                           "Emails may sometimes land in spam folders"
//                         </h5>
//                         <button 
//                           type="button"
//                           className="btn btn-success mt-2"
//                           onClick={handleGoBack}
//                         >
//                           Go Back
//                         </button>
//                       </form>
//                     </>
//                   ) : (
//                     <>
//                       <h5 className="card-title fw-medium fs-6 text-start mt-4">
//                         New Password
//                       </h5>
//                       <form>
//                         <input
//                           type="password"
//                           placeholder="Enter New Password"
//                           className="form-card "
//                           value={newPassword}
//                           onChange={(e) => setNewPassword(e.target.value)}
//                         />
//                         <input
//                           type="password"
//                           placeholder="Confirm New Password"
//                           className="form-card mt-2"
//                           value={confirmPassword}
//                           onChange={(e) => setConfirmPassword(e.target.value)}
//                         />
//                         {newPassword !== confirmPassword && (
//                           <div className="text-danger fs-6 mb-3">
//                             Passwords do not match
//                           </div>
//                         )}
//                         <button
//                           type="submit"
//                           className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2"
//                           disabled={newPassword !== confirmPassword}
//                           onClick={handleUpdatePassword}
//                         >
//                           Update Password
//                         </button>
//                       </form>
//                     </>
//                   )}
//                 </>
//               )}
//             </div>
//           </div>
//           <ToastContainer position="top-center" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgetPassword;
import React, { useState } from "react";
import LoadingImg from "../../images/loading.webp";
import loginImage from "../../images/app_logo-02d8f0cb.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(null);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [currentStep, setCurrentStep] = useState("email");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://beta.yesdone.com/api/v1/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        console.log(responseData);
        setIsEmailSent(true);
        setVerifiedEmail(email);
        toast.success("Email sent successfully!");
        setCurrentStep("otp");
      } else {
        console.error(responseData.message);
        toast.error("Please Enter a Valid Email!");
      }
    } catch (error) {
      console.error(error);
      setLoginError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Check if OTP is empty
      if (!otp.trim()) {
        throw new Error("Please enter OTP");
      }

      const response = await fetch(
        "https://beta.yesdone.com/api/v1/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, otp }),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        console.log(response);
        if (responseData && responseData.success) {
          setIsOTPVerified(true);
          setShowPasswordFields(true);
          toast.success("OTP verified successfully!");
          setCurrentStep("password");
        } else {
          throw new Error("Invalid OTP");
        }
      } else {
        throw new Error(responseData.message || "Error verifying OTP");
      }
    } catch (error) {
      console.error(error);
      setLoginError(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://beta.yesdone.com/api/v1/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: verifiedEmail, password: newPassword }),
        }
      );

      if (response.ok) {
        toast.success("Password changed successfully!");
        navigate("/login");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row">
        <div className="col-12 login">
          <div className="card login-card">
            <img
              src={loginImage}
              className="card-img-top cardimage mx-auto pt-5 pb-3"
              width="170px"
              alt="yesdonelogo"
            />
            <div className="card-body text-center px-5">
              {verifiedEmail && <div className="mb-3">Email: {verifiedEmail}</div>}
              {currentStep === "email" && (
                <>
                  <h5 className="card-title fw-medium fs-6 text-start">Email Address</h5>
                  <form className="pb-3" onSubmit={handleSubmit}>
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="form-card"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {loginError && <div className="text-danger fs-6 mb-3">{loginError}</div>}
                    {isLoading ? (
                      <button className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2" disabled>
                        <img src={LoadingImg} width="20px" height="20px" alt="Loading" />
                      </button>
                    ) : (
                      <button type="submit" className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2">
                        Sending OTP To Mail
                      </button>
                    )}
                  </form>
                </>
              )}
              {currentStep === "otp" && (
                <>
                  <h5 className="card-title fw-medium fs-6 text-start">Verification Code</h5>
                  <form onSubmit={handleVerify}>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      className="form-card"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    {loginError && <div className="text-danger fs-6 mb-3">{loginError}</div>}
                    {isLoading ? (
                      <button className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2" disabled>
                        <img src={LoadingImg} width="20px" height="20px" alt="Loading" />
                      </button>
                    ) : (
                      <button type="submit" className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2">
                        Verify OTP
                      </button>
                    )}
                    <h5 className="card-title fw-medium fs-6 text-start text-danger pt-3 text-break"> "Emails may sometimes land in spam folders"</h5>
                  </form>
                  <button className="btn btn-success mt-2" onClick={() => setCurrentStep("email")}>Go Back</button>
                </>
              )}
              {currentStep === "password" && (
                <>
                  <h5 className="card-title fw-medium fs-6 text-start mt-4">New Password</h5>
                  <form className="pb-2">
                    <input
                      type="password"
                      placeholder="Enter New Password"
                      className="form-card"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                      type="password"
                      placeholder="Confirm New Password"
                      className="form-card mt-2"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {newPassword !== confirmPassword && <div className="text-danger fs-6 mb-3">Passwords do not match</div>}
                    <button type="submit" className="btn login-button btn-success fw-medium rounded-0 w-100 py-2 px-4 mt-2" disabled={newPassword !== confirmPassword} onClick={handleUpdatePassword}>Update Password</button>
                  </form>
                </>
              )}
            </div>
          </div>
          <ToastContainer position="top-center" />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

