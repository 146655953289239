import API_BASE_URL from '../Config.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const loginUser = async (email, password) => {
  const url = `${API_BASE_URL}/api/v1/login`;  // Use backticks to enclose the URL
  const data = {
    email: email,
    password: password,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      toast.success("Login Successfully"); // Move this line before the return statement
      return responseData;
    } else {
      throw new Error('Login failed');
    }
  } catch (error) {
    throw new Error('Login failed');
  }
};
