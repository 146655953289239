import React from 'react'

const StaticSidebar = () => {
  return (
    <div className="container text-center">
    <div className="row pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
    <div className="row pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
    <div className="row pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
    <div className="row pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
    <div className="row  pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
    <div className="row pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
    <div className="row pb-3">
      <div className="col-sm-2 col-2 p-0 d-flex align-items-center justify-content-evenly">
        <div className=" d-flex align-items-center pe-3"></div>
        <div className=" d-flex align-items-center check-static"></div>
      </div>
      <div className="col-sm-10 col-10 d-flex align-items-center notes">
        <span className="d-flex align-items-center pe-2 profilestatic1">
        </span>
      </div>
    </div>
  </div>
  )
}

export default StaticSidebar
