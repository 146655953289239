import { createContext, useContext, useState } from 'react';

const TodoDataContext = createContext();

export const TodoDataProvider = ({ children }) => {
  const [todoData, setTodoData] = useState({
    todoCount: 0,
    approvedCount: 0,
    deleteCount: 0,
    posts: [],
  });
 ;

  return (
    <TodoDataContext.Provider value={{ todoData, setTodoData, }}>
      {children}
    </TodoDataContext.Provider>
  );
};

export const useTodoData = () => {
  const context = useContext(TodoDataContext);
  if (!context) {
    throw new Error('useTodoData must be used within a TodoDataProvider');
  }
  return context;
};
