// api.js

import API_BASE_URL from "../Config";

export const saveBookMark = async (postId) => {
    const storedToken=localStorage.getItem("token");
  const apiUrl = `${API_BASE_URL}/api/v1/posts/${postId}/action`;
  const headers = {
    Authorization: `Bearer ${storedToken}`,
    'Content-Type': 'application/json',
  };

  const postData = {
    type_of: 'save',
    url: '',
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify(postData),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json(); // Assuming the response is JSON
};
