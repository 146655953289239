// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-img{
    background-color:#198754;
    width: 34px;
    height: 34px;
    border-radius: 50%;
}
.search-icon{
    position: absolute;
    left: 34px;
    top: 25px;
}
.changes-font{
    font-size: 20px;
}
@media screen and (max-width:576px){
    .recentCard1{
        width: 22rem;
    }
    .changes-font{
        font-size: 12px;
    }
    .font1{
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/post/CreateGroup.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb;AACA;IACI,eAAe;AACnB;AACA;IACI;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".search-img{\n    background-color:#198754;\n    width: 34px;\n    height: 34px;\n    border-radius: 50%;\n}\n.search-icon{\n    position: absolute;\n    left: 34px;\n    top: 25px;\n}\n.changes-font{\n    font-size: 20px;\n}\n@media screen and (max-width:576px){\n    .recentCard1{\n        width: 22rem;\n    }\n    .changes-font{\n        font-size: 12px;\n    }\n    .font1{\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
