import "./Todo.css";
import MainPost from "./MainPost";
import { Tabs, Tab } from "react-bootstrap";
import DateAndSort from "../datesort/DateAndSort";
import LoadingImg from "../../images/loading.webp";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { useTodo } from "../AuthContext/TodoContext";
import { fetchTodoRecords } from "../../Api/fetchTodo";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
  calculateTimeDifference,
  genNewSearchParamString,
} from "../../utils/index";
import { formatDate } from "../../utils/index";
import { useTodoData } from "../AuthContext/TodoDataContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import API_BASE_URL from "../../Config";
import ImagePreview from "../../utils/imagePreview";
import StaticBox from "./StaticBox";
import UserContext from "../AuthContext/ChatContext";
import Login from "../login/Login";
import { useAuth } from "../AuthContext/AuthContext";
import { useFilterData } from '../AuthContext/SidebarContext'; 
const Todos = () => {
  const location = useLocation();
 const { filterData, setFilterData } = useFilterData();
  const [todoCount, setTodoCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortOrder, setSortOrder] = useState(null);
  const [sortData, setSortData] = useState(null);
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(true);
  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [title1, setTitle1] = useState("");
  const [isLastPage, setIsLastPage] = useState(false);
  const [showCalander, setShowCalander] = useState(false); 
  const [isIconUp, setIsIconUp] = useState(false);

  const handleIconClick = async () => {
    const newSortOrder = isIconUp ? 'asc' : 'desc';
    setIsIconUp(!isIconUp); // Toggle sorting order
    setLoading(true);
    try {
      const resp = await fetchTodoRecords(
        userId || groupId,
        activeTab,
        1,
        groupId === null ? "user" : "group",
        'commission_due_date', // sortBy parameter
        newSortOrder // Correctly use newSortOrder
      );
  
      // Process the response data
      setIsLastPage(
        resp?.data?.pagination?.current_page < resp?.data?.pagination?.last_page
      );
  
      const updatedPosts = resp?.data?.posts?.map((post) => ({
        ...post,
        created_at: formatDate(post.created_at),
      }));
  
      // Assuming resp is your response object
      const usersArray = resp?.data?.posts[0]?.users;
  
      if (Array.isArray(usersArray)) {
        for (const user of usersArray) {
          if (user.group_name !== null) {
            // Found the first object where group_name is not null
            localStorage.setItem("usersList", JSON.stringify(user.users));
            break; // Stop searching after finding the first object with non-null group_name
          }
        }
      }
  
      // Update state with the sorted data
      setSortData(resp);
      setTodoData({
        todoCount: resp?.data.still_working?.count || 0,
        approvedCount: resp?.data.approved?.count || 0,
        deleteCount: resp?.data.deleted?.count || 0,
        posts: updatedPosts,
      });
      setTodoCount(resp?.data.still_working?.count || 0);
      setApprovedCount(resp?.data.approved?.count || 0);
      setDeleteCount(resp?.data.deleted?.count || 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching todos:", error);
    }
  };
  
  
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);
  const [selectedDate, setSelectedDate] = useState(() => {
     const date = new Date(currentDate);
     date.setDate(currentDate.getDate() + 7);
     return date;
   });  
   const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = selectedDate.toLocaleDateString("en-US", options);
 const handleShowCalander = () => {
     setShowCalander(!showCalander);
   }; 
 
   const handleDateChange = (date) => {
     setSelectedDate(date);
   };
  const textareaRef = useRef(null);
  const abortControllerRef = useRef(null);
  const handleTextChange = (e) => {
    setTitle1(e.target.value);
    console.log(e.target.value);
    adjustTextareaHeight();
  };
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };
  const { groupName } = useContext(UserContext);
  const navigate = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();
  const dateField = "posts.commission_due_date";
  const notes = "posts.notes";
  const createdat = "posts.created_at";
  const Updatedat = "posts.updated_at";
  const userId = searchParams.get("user_id");
  const activeTab = searchParams.get("status");
  const groupId = searchParams.get("group_id");
  const fullName = localStorage.getItem("fullName");
  const { todoData, setTodoData } = useTodoData();
  const { selectedItemName, firstTodoName, firstId, firstType } = useTodo();
  console.log("firsttodoData", todoData);
  const handleSelect = (key) =>
    navigate(genNewSearchParamString(searchParams, "status", key));
  const handleSortChange = async (sortBy, sortOrder) => {
    try {
      setLoading(true);
      await initialFetchTodos(sortBy, sortOrder); // Ensure sortOrder is passed here
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const initialFetchTodos = async (sortBy = "posts.commission_due_date", sortOrder = "desc") => {
    try {
      setLoading(true);
  
      // Determine the API parameter for sorting based on sortBy value
  
      // Map the clicked sortBy values to the required API parameters
      let sortByParam;
      switch (sortBy) {
        case createdat:
          sortByParam = "posts.created_at"; // Sort by post creation date
          break;
        case Updatedat:
          sortByParam = "posts.updated_at"; // Sort by comment creation date
          break;
        case notes:
          sortByParam = "posts.notes"; // Sort by name/notes
          break;
        case dateField:
          sortByParam = "posts.commission_due_date"; // Default sort by due_date
          break;
        default:
          sortByParam = "posts.commission_due_date"; // Fallback in case of unexpected sortBy values
      }
      // Reset data before making a new API call
      setTodoData({
        todoCount: 0,
        approvedCount: 0,
        deleteCount: 0,
        posts: [],
      });
  
      // Call the API with the selected sorting parameters
      const resp = await fetchTodoRecords(
        userId || groupId,
        activeTab,
        1,
        groupId === null ? "user" : "group",
        sortByParam,
        sortOrder // Pass the sort order (asc or desc)
      );
  
      console.log("todos console for pagination", resp);
  
      // Check if it's the last page of the response
      setIsLastPage(
        resp?.data?.pagination?.current_page < resp?.data?.pagination?.last_page
      );
  
      // Format dates within the response data
      const updatedPosts = resp?.data?.posts?.map((post) => ({
        ...post,
        created_at: formatDate(post.created_at),
      }));
  
      // Extract user list from response and store in local storage
      const usersArray = resp?.data?.posts[0]?.users;
      if (Array.isArray(usersArray)) {
        for (const user of usersArray) {
          if (user.group_name !== null) {
            localStorage.setItem("usersList", JSON.stringify(user.users));
            break;
          }
        }
      }
  
      // Set sorted data to state
      setSortData(resp);
      setTodoData({
        todoCount: resp?.data.still_working?.count || 0,
        approvedCount: resp?.data.approved?.count || 0,
        deleteCount: resp?.data.deleted?.count || 0,
        posts: updatedPosts,
      });
  
      // Update counts in state
      setTodoCount(resp?.data.still_working?.count || 0);
      setApprovedCount(resp?.data.approved?.count || 0);
      setDeleteCount(resp?.data.deleted?.count || 0);
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.status);
      } else if (error.request) {
        console.error("No Response:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleHome =(e) => {
  
    navigate("/posts/new");
  };

  useEffect(() => {
    initialFetchTodos();
    // return () => {
    //   if (abortControllerRef.current) {
    //     abortControllerRef.current.abort();
    //   }
    // };
  }, [userId, groupId, activeTab]);

  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };

  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
    setVideoFile(null);
    setImageFile(null);
    setPreviewVisible(true);
  };
  const formatDateToLocalISO = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().split("T")[0];
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      const title = e.target.title.value;

      const user_group_id = userId || groupId;
      const is_user = userId !== null;
      const youtube_url = e.target.video_url.value;

      let img_video_url = youtube_url;
      if (file) {
        img_video_url = await uploadFileToS3(file);
      } else {
        console.error("File is undefined");
        // Handle the error or provide a fallback behavior
      }

      let thumbnail_url = img_video_url;
      if (thumbnail) thumbnail_url = await uploadFileToS3(thumbnail);

      const apiUrl = `${API_BASE_URL}/api/v1/posts`;
      const headers = {
        Authorization: `Bearer ${storedToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const formattedSelectedDate = formatDateToLocalISO(selectedDate);
      const postData = getTodoReqParams(
        title,
        img_video_url,
        thumbnail_url,
        user_group_id,
        is_user,
        null,
      formattedSelectedDate 
      );

      fetch(apiUrl, { method: "POST", headers: headers, body: postData })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((response) => {
          const resp = response?.data || {};
          console.log("resp", resp);

          // Create a new array with the added object at the beginning
          const newPosts = [resp, ...todoData.posts];

          // Update todoCount and posts in the state
          setTodoData((prevTodoData) => ({
            ...prevTodoData,
            todoCount: prevTodoData.todoCount + 1,
            posts: newPosts,
          }));
          setTitle1("");
          setFile(null);
          setThumbnail(null);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  // console.log(
  //   "groupname,selectedname,",
  //   groupName,
  //   selectedItemName,
  //   firstTodoName
  // );
  console.log("filterData",JSON.stringify(filterData));
  const mainId = parseInt(userId || groupId);
  console.log(typeof(mainId));
  const allIds = filterData?.map(item => (item.id));
  console.log("allIds",allIds);
  const idMatch = allIds.includes(mainId);

  console.log(idMatch);  
  const [nameValue, setNameValue] = useState(null);

  // Effect to update the nameValue when mainId or filterData changes
  useEffect(() => {
    // Find the matched item
    const matchedItem = filterData.find(item => item.id === mainId);

    // Set nameValue if a match is found
    if (matchedItem) {
      setNameValue(matchedItem.name);
    } else {
      // If no match is found, set nameValue to null
      setNameValue(null);
    }
  }, [mainId, filterData]);
  console.log(nameValue);
  return (
    <>
      {isLoggedIn ? (
        <>
          {" "}
          <div className="pt-1 ps-4 todos-pl ms-2 ">
            
            <span className="todos-home"   onClick={()=>{handleHome()}}>Home {" > "}</span>

            <Link to="/main-todo">
              <span className="todos-home">To-do {" > "}</span>
            </Link>

            {/* {selectedItemName ? (
            <span className="todos-home">{` ${selectedItemName}`}</span>
          ) : (
            <span>{firstTodoName}{firstId}{firstType}</span>
          )}
          */}
            {/* {groupName ? (
              <span className="todos-home">{groupName}</span>
            ) : (
              <>
                {selectedItemName ? (
                  <span className="todos-home">{` ${selectedItemName}`}</span>
                ) : (
                  <>
                    {firstTodoName ? (
                      <span className="todos-home">{firstTodoName}</span>
                    ) : (
                      <>
                        {fullName && (
                          <span className="todos-home">{fullName}</span>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )} */}
            <span className="todos-home">{nameValue}</span>
          </div>
          <div className="container mt-2">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-md-12 todo-paddingr ">
              <div className="position-relative mb-1">
                <Tabs
                  defaultActiveKey="still_working"
                  activeKey={activeTab}
                  id="tab-example"
                  className="todonav text-muted mb-1  tab-border"
                  onSelect={handleSelect}
                >
                  <Tab
                    eventKey="still_working"
                    title={`To do (${todoData.todoCount})`}
                    className="text-success  navlink py-0"
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center my-2">
                        {/* <img src={LoadingImg} alt="" width="50px" /> */}
                        <StaticBox />
                      </div>
                    ) : (
                      <>
                        {todoData.todoCount === 0 ? (
                          <>
                            <p className="text-center fw-bold text-black heighttodos pt-5 mb-0">
                              It seems like there are no tasks pending.
                              <span className="d-block">
                                Check back later for new tasks.
                              </span>
                            </p>
                            <div className="addtaskpart py-0">
                              <form
                                action=""
                                id="AddTask"
                                onSubmit={handleFormSubmit}
                              >
                                <div
                                  className={`${
                                    title1.length > 118
                                      ? "taskborder1"
                                      : "taskborder"
                                  } pt-0 px-0 pb-1 d-flex align-items-center`}
                                  // style={{
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  // }}
                                >
                                  <textarea
                                    type="text"
                                    className="form-control add-input px-0 shadow-none border-0  "
                                    autoComplete="off"
                                    required
                                    ref={textareaRef}
                                    name="title"
                                    onChange={(e) => handleTextChange(e)}
                                    value={title1}
                                    placeholder="+   Name of Task"
                                    // style={{ height: "30px", resize: "none" }}
                                  />
                                  <div className="position-relative d-flex align-items-center ms-2">
        <button
          type="button"
          className="border-0 text-start calander bg-transparent"
          onClick={handleShowCalander}
          style={{ position: 'relative', zIndex: 2 }}
        >
          <span className="d-flex justify-content-between align-items-center">
            <span className="me-2 custom-fontsize">Due date :</span>
            <span className="custom-fontsize">{formattedDate}</span>
          </span>
        </button>
        {showCalander ? (
          <div
            className="position-absolute mt-2 shadow bg-white"
            style={{ zIndex: 999, top: '', bottom:'30px',right: '0px' }}
          >
            <Calendar onChange={handleDateChange} value={selectedDate}
              // minDate={currentDate}
               />
          </div>
        ) : null}
      </div>
                                </div>
                                <div className="d-flex">
                                  <label className="custom-file-upload">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="mediaFile"
                                      name="files"
                                      accept="image/*, video/*"
                                      onChange={handleFileChange}
                                    />
                                    <img
                                      src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                      className=""
                                      alt=""
                                      width="22px"
                                      height="18px"
                                    />
                                  </label>
                                  <textarea
                                    type="text"
                                    className="form-control add-input shadow-none text-break border-0 text-resize"
                                    autoComplete="off"
                                    name="video_url"
                                    placeholder="Youtube Video"
                                    // style={{ resize: "none" }}
                                  />
                                  <button
                                    type="submit"
                                    className="submitclass btn ms-auto"
                                  >
                                    <img
                                      src="https://yesdone.com/build/assets/send-99cff475.png"
                                      className=""
                                      alt="send img"
                                      width="35px"
                                      height="35px"
                                    />
                                  </button>
                                </div>
                                {(thumbnail || file) && (
                                  <ImagePreview
                                    file={thumbnail || file}
                                    onDelete={handleFileDelete}
                                  />
                                )}
                              </form>
                            </div>
                          </>
                        ) : activeTab === "still_working" ? (
                          <MainPost
                            isLastPage={isLastPage}
                            initialFetchTodos={initialFetchTodos}
                            sortData={sortData}
                          />
                        ) : (
                          <p>still_working</p>
                        )}
                      </>
                    )}
                  </Tab>
                  <Tab
                    eventKey="approved"
                    title={`Done (${todoData.approvedCount})`}
                    className="text-success navlink py-0"
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center my-2">
                        {/* <img src={LoadingImg} alt="" width="50px" /> */}
                        <StaticBox />
                      </div>
                    ) : (
                      <>
                        {todoData.approvedCount === 0 ? (
                          <p className="text-center fw-bold text-black heighttodos pt-5">
                            It seems like there are no tasks pending.
                            <span className="d-block">
                              Check back later for new tasks.
                            </span>
                          </p>
                        ) : activeTab === "approved" ? (
                          <MainPost
                            isLastPage={isLastPage}
                            initialFetchTodos={initialFetchTodos}
                          />
                        ) : (
                          <p>approved</p>
                        )}
                      </>
                    )}
                  </Tab>
                  <Tab
                    eventKey="deleted"
                    title={`Deleted (${todoData.deleteCount})`}
                    className="text-success navlink py-0"
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center my-2">
                        {/* <img src={LoadingImg} alt="" width="50px" /> */}
                        <StaticBox />
                      </div>
                    ) : (
                      <>
                        {todoData.deleteCount === 0 ? (
                          <p className="text-center fw-bold text-black heighttodos pt-5">
                            It seems like there are no tasks pending.
                            <span className="d-block">
                              Check back later for new tasks.
                            </span>
                          </p>
                        ) : activeTab === "deleted" ? (
                          <MainPost
                            isLastPage={isLastPage}
                            initialFetchTodos={initialFetchTodos}
                          />
                        ) : (
                          <p>deleted</p>
                        )}
                      </>
                    )}
                  </Tab>
                 
                </Tabs>
                {activeTab === "still_working" && (
              <div
                className="d-flex align-items-center"
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '75px',
                  transform: 'translateY(-50%)',
                }}
              >
                <span>Due Date</span>
                <button
                  type="button"
                  className="btn p-0 ms-2"
                  onClick={handleIconClick}
                  style={{ background: 'transparent', border: 'none' }}
                >
                  {isIconUp ? (
                    <ChevronUp size={16} />
                  ) : (
                    <ChevronDown size={16} />
                  )}
                </button>
              </div>
            )}
        

               
      </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <DateAndSort
                  handleSortChange={handleSortChange}
                  currentSortOrder={sortOrder}
                  dateField={dateField} 
                  alphabetical={notes}
                  createdat={createdat}
                  Updatedat={Updatedat}
                  
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Todos;
