import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Post.css";
import API_BASE_URL from "../../Config";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
  getTodoReqParams,
  calculateTimeDifference,
  getTodoReqParams1,
} from "../../utils/index";
import ImagePreview from "../../utils/imagePreview";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFilterData } from "../AuthContext/SidebarContext";
import axios from "axios";
import ReactDOM from "react-dom";
import PostStatic from "./PostStatic";

const groupByCountry = (employees) => {
  const grouped = employees.reduce((acc, emp) => {
    let country = emp.country;
    if (
      country === null ||
      country === "US" ||
      country === "USA" ||
      country === "+1"
    ) {
      country = "United States";
    }
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push(emp);
    return acc;
  }, {});
  return grouped;
};

const Post = () => {
  const currentDate = new Date(); // current date
  const sevenDays = new Date(currentDate);
  sevenDays.setDate(currentDate.getDate() + 7);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() + 7);
    return date;
  });
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = selectedDate.toLocaleDateString("en-US", options);
  const [showCalander, setShowCalander] = useState(false);
  const [group, setGroup] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [groupedEmployees, setGroupedEmployees] = useState({});
  const [recent, setRecent] = useState([]);
  const [showGroup, setShowGroup] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [text, setText] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [checkedName, setCheckedName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [title, setTitle] = useState("");
  const [showToast, setShowToast] = useState(false);
  const { filterData, setFilterData } = useFilterData();
  const [loading, setLoading] = useState(false);
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };

  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleShowCalander = () => {
    setShowCalander(!showCalander);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleTagPeople = async () => {
    await handleGroupPeople();
    await handleEmployee();
    await handleRecent();
    await handleDepartments();
  };

  const handleGroupPeople = () => {
    setLoading(true);
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      setLoading(false);
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
        setShowGroup(true);
        setGroup(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completes
      });
  };
  const handleRecent = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      setLoading(false);
      return;
    }
    fetch(`${API_BASE_URL}/api/v1/users?recent=1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
        setShowGroup(true);
        setRecent(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completes
      });
  };
  const handleDepartments = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      setLoading(false);
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/departments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
        setShowGroup(true);
        setDepartments(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completes
      });
  };
  const handleChangesSelect = (event) => {
    console.log(event.target.value);
  };

  const handleEmployee = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      console.error("No token found in localStorage");
      setLoading(false);
      return;
    }

    fetch(`${API_BASE_URL}/api/v1/users?is_employee=1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the data received from the API
        setShowGroup(true);
        setEmployee(data.data);
        setGroupedEmployees(groupByCountry(data.data));
        console.log(data.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("There was a problem with your fetch operation:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call completes
      });
  };

  const handlePost = () => {
    // Logic for posting
  };
  const handleImageClick = (e) => {
    // Prevent click event from propagating to the label
    e.stopPropagation();
  };
  const handleCheckboxChange = (event, group, name) => {
    console.log("id,name", group, name);
    const isChecked = event.target.checked;
    if (isChecked) {
      // If checkbox is checked, add userId to the selectedUserIds list
      setSelectedUserIds((prevIds) => [...prevIds, group]);
    } else {
      // If checkbox is unchecked, remove userId from the selectedUserIds list
      setSelectedUserIds((prevIds) => prevIds.filter((id) => id !== group));
    }
    if (event.target.checked) {
      setCheckedName([...checkedName, name]);
    } else {
      // If checkbox is unchecked, remove the name from the checkedNames list
      setCheckedName(checkedName.filter((item) => item !== name));
    }
  };

  const handleCheckboxGroupChange = (event, groupId, groupName) => {
    console.log("groupid,name", groupId, groupName);
    setSelectedGroup(groupName);
    setSelectedGroupIds([groupId]);
  };
  console.log("selecteduserids", selectedUserIds);
  console.log("selectedgroupids", selectedGroupIds);

  // api.js

  const fetchUserData = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        // setError('Authorization token is missing');
        // setLoading(false);
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/v1/filters/list`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.data && response.data.status === 200) {
        setFilterData([]);
        setFilterData((prev) => [...prev, ...response.data.data]);

        console.log("users-list", response.data.data);
      } else {
        // setError('Failed to fetch filter data');
      }
    } catch (error) {
      // setError('Error fetching filter data');
    } finally {
      // setLoading(false);
    }
  };
  const Toast = ({ message, type }) => {
    return (
      <div
        style={{
          backgroundColor: type === "success" ? "green" : "red",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        {message}
      </div>
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      const youtube_url = e.target.video_url.value;

      let img_video_url = youtube_url;
      if (file) {
        img_video_url = await uploadFileToS3(file);
      } else {
        console.error("File is undefined");
        // Handle the error or provide a fallback behavior
      }
      const userIds = selectedUserIds;
      const groupIds = selectedGroupIds;

      let thumbnail_url = img_video_url;
      if (thumbnail) thumbnail_url = await uploadFileToS3(thumbnail);

      const apiUrl = `${API_BASE_URL}/api/v1/posts`;
      const headers = {
        Authorization: `Bearer ${storedToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const postData = getTodoReqParams1(
        title,
        img_video_url,
        thumbnail_url,
        userIds,
        groupIds
      );

      fetch(apiUrl, { method: "POST", headers: headers, body: postData })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(async (response) => {
          const resp = response?.data || {};
          console.log("resp", resp);
          fetchUserData();
          setFile(null);
          setThumbnail(null);
          setText(null);
          setTitle("");
          setCheckedName("");
          setSelectedGroup("");
          setShowGroup(false);
          ReactDOM.render(
            <Toast message="New Task created successfully!" type="success" />,
            document.getElementById("toast-container")
          );
          // Automatically remove the toast after 4 seconds
          setTimeout(() => {
            ReactDOM.unmountComponentAtNode(
              document.getElementById("toast-container")
            );
          }, 3000);
        })
        .catch((error) => {
          console.error("Error:", error);
          ReactDOM.render(
            <Toast
              message="An error occurred. Please try again."
              type="error"
            />,
            document.getElementById("toast-container")
          );
        });
    }
  };

  // useEffect(() => {
  //   if (employee && employee.length > 0) {
  //     setGroupedEmployees(groupByCountry(employee));
  //   }
  // }, [employee]);

  const handleUpdatedCreate = (event, id, name) => {
    const isChecked = event.target.checked;
  };
  return (
    <div className="container">
      <form
        action=""
        id="AddTask"
        onSubmit={handleFormSubmit}
        className="post-form"
      >
        <div className="row ps-2 pt-4">
          <div className="col-lg-6 col-sm-12 col-md-12 border border-bottom-0 px-4">
            <h3 className="d-flex justify-content-center border-bottom py-2 mb-3 green-background">
              Create Task
            </h3>
            <div className="addtaskpart py-0">
              <div className="pt-0 px-0 pb-1 d-flex align-items-center">
                <textarea
                  type="text"
                  className="form-control add-input px-0 shadow-none border-0 text-resize custum-text-area-height"
                  autoComplete="off"
                  required
                  name="title"
                  value={title}
                  onChange={(e) => handleChangeTitle(e)}
                  placeholder="+   Name of Task"
                />
              </div>
              {(thumbnail || file) && (
                <ImagePreview
                  file={thumbnail || file}
                  onDelete={handleFileDelete}
                />
              )}
            </div>
            <div></div>
            <div className="d-flex pt-3">
              <label
                className="custom-file-upload col-1 pt-1 text-start"
                htmlFor="mediaFile"
              >
                <input
                  type="file"
                  id="mediaFile"
                  name="files"
                  accept="image/*, video/*"
                  // style={{ display: "none" }} // hide the input element
                  onChange={handleFileChange}
                />
                <button
                  type="button"
                  onClick={() => document.getElementById("mediaFile").click()}
                >
                  <img
                    src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                    className=""
                    alt=""
                    width="22px"
                    height="18px"
                  />
                </button>
              </label>
              <textarea
                type="text"
                className="form-control add-input shadow-none text-break border-0 text-resize"
                autoComplete="off"
                name="video_url"
                placeholder="Youtube Video"
              />
            </div>
            <div className="row border-bottom py-3 px-3">
              <button type="button" className="border-0 text-start calander">
                <span
                  onClick={handleShowCalander}
                  className="d-flex justify-content-between"
                >
                  <span>Due</span>
                  <span className="custom-fontsize">{formattedDate}</span>
                </span>
                {showCalander ? (
                  <Calendar onChange={handleDateChange} value={selectedDate} />
                ) : null}
              </button>
            </div>
            <div className="row border-bottom py-3 px-4">
              <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border-0 ps-0 form-select shadow-none"
              >
                <option value="">Select</option>
                <option value="">Urgent</option>
                <option value="">Extremely Urgent</option>
                {/* Add options as needed */}
              </select>
            </div>
            <div className="row border-bottom py-3 px-4">
              <button
                type="button"
                onClick={handleTagPeople}
                className="border-0 text-start calander d-flex justify-content-between p-0"
              >
                <span className="ps-1">Tag People</span>
                <span>
                  <i className="bi bi-caret-right-fill  green-background"></i>
                </span>
              </button>
            </div>
            <span>
              {checkedName.length > 0 && (
                <>
                  <div class="card recentCard mx-auto mt-2">
                    <h4 class="card-header text-center">Selected Users</h4>
                    <div className="column-layout">
                      <ul className="list-group list-group-flush border-bottom">
                        {checkedName.map((item, index) => (
                          <li
                            key={index}
                            className="list-group-item d-flex justify-content-between align-items-center p-0"
                          >
                            <div className="d-flex align-items-center py-1 col-lg-11">
                            <div className="col-lg-1 form-check m-0 ms-1">
                              <input
                                className="form-check-input green-border cursor-pointer"
                                type="checkbox"
                                id={`flexCheckDefault-selected-${index}`}
                                onChange={(event) =>
                                  handleUpdatedCreate(event, item.id, item.name)
                                }
                                checked={true} // All existing employees are initially checked
                              />
                            </div>
                              <span className="col-lg-1">
                                <i className="bi bi-person-fill"></i>
                              </span>
                              <span className="col-lg-10 ">{item}</span>{" "}
                              {/* Assuming 'item' is a string */}
                            </div>
                           
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}

              {selectedGroup.length > 0 && (
                <>
                  <div class="card recentCard mx-auto mt-2">
                    <h4 class="card-header text-center">Selected Groups</h4>
                    <div className="column-layout">
                      <ul className="list-group list-group-flush border-bottom">
                        <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                          <div className="d-flex align-items-center py-1 col-lg-11">
                          <div className="col-lg-1 form-check m-0 ms-1">
                            <input
                              className="form-check-input green-border cursor-pointer"
                              type="checkbox"
                              checked={true} // All existing employees are initially checked
                            />
                          </div>
                            <span className="col-lg-1">
                              <i className="bi bi-people-fill"></i>
                            </span>
                            <span className="col-lg-10 ">{selectedGroup}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </span>
            <ToastContainer />
            <div className="row  py-3 ">
              <button
                type="submit"
                className="button-post  d-flex justify-content-center align-items-center"
              >
                Post
              </button>
              <div id="toast-container"></div>
            </div>
            <div className="toast-container position-fixed bottom-0 end-0 p-3">
              {/* Conditionally render the toast based on state */}
              {showToast && (
                <div
                  className="toast"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div className="toast-header">
                    <img src="..." className="rounded me-2" alt="..." />
                    <strong className="me-auto">Bootstrap</strong>
                    <small>Just now</small>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowToast(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="toast-body">Your toast message here</div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-12 ps-4">
            {loading ? (
              <PostStatic />
            ) : (
              <>
                <div className="row">
                  {showGroup && departments && departments.length > 0 ? (
                    <select
                      onChange={handleChangesSelect}
                      className="selectbox cursor-pointer"
                    >
                      <option value="">Select Item</option>
                      {departments.map((item) => (
                        <option key={item.id} value={JSON.stringify(item)}>
                          <span>{item.name}</span>
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
                <div className="row">
                  {showGroup && recent && recent.length > 0 ? (
                    <>
                      <div className="card recentCard">
                        <h3 className="card-header text-center">Recent</h3>
                        <div className="column-layout">
                          {recent
                            .filter((recent) => recent.status === "active")
                            .sort((a, b) =>
                              a.first_name.localeCompare(b.first_name)
                            ) // Sort by first name
                            .map((recent, index) => (
                              <ul
                                className="list-group list-group-flush border-bottom"
                                key={index}
                              >
                                <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                  <div className="col-lg-1 form-check m-0">
                                    <input
                                      className="form-check-input green-border cursor-pointer"
                                      type="checkbox"
                                      value=""
                                      id={`flexCheckDefault-${index}`}
                                      onChange={(event) =>
                                        handleCheckboxChange(
                                          event,
                                          recent.id,
                                          recent.first_name
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="d-flex align-items-center col-lg-11">
                                    <span className="col-lg-1"></span>
                                    <span className="col-lg-10 post-font">
                                      {recent.first_name} {recent.last_name}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="row py-3">
                  {showGroup && group && group.length > 0 ? (
                    <>
                      <div className="card recentCard">
                        <h3 className="card-header text-center">Groups</h3>
                        <div className="column-layout">
                          {group
                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by group name
                            .map((group, index) => (
                              <ul
                                className="list-group list-group-flush border-bottom"
                                key={index}
                              >
                                <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                  <div className="col-lg-1 form-check m-0">
                                    <input
                                      className="form-check-input green-border cursor-pointer"
                                      type="checkbox"
                                      value=""
                                      id={`flexCheckDefault-${index}`}
                                      checked={selectedGroup === group.name}
                                      onChange={(event) =>
                                        handleCheckboxGroupChange(
                                          event,
                                          group.id,
                                          group.name
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="d-flex align-items-center col-lg-11">
                                    <span className="col-lg-1"></span>
                                    <span className="col-lg-10 post-font">
                                      {group.name}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="row pb-4">
                  {showGroup && Object.keys(groupedEmployees).length > 0 ? (
                    <>
                      {Object.keys(groupedEmployees)
                        .sort() // Sort countries alphabetically
                        .map((country) => (
                          <div className="card recentCard" key={country}>
                            <h3 className="card-header text-center">
                              {country}
                            </h3>
                            <div className="column-layout">
                              {[...new Set(groupedEmployees[country])]
                                .filter((emp) => emp.status === "active")
                                .sort((a, b) =>
                                  a.first_name
                                    .trim()
                                    .toLowerCase()
                                    .localeCompare(
                                      b.first_name.trim().toLowerCase()
                                    )
                                ) // Trim whitespace and sort by employee first name, case-insensitive
                                .map((emp, index) => (
                                  <ul
                                    className="list-group list-group-flush border-bottom"
                                    key={emp.id}
                                  >
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                      <div className="col-lg-1 form-check m-0">
                                        <input
                                          className="form-check-input green-border cursor-pointer"
                                          type="checkbox"
                                          value=""
                                          id={`flexCheckDefault-${index}`}
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              event,
                                              emp.id,
                                              emp.first_name
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="d-flex align-items-center col-lg-11">
                                        <span className="col-lg-1"></span>
                                        <span className="col-lg-10 post-font">
                                          {emp.first_name.trim()}{" "}
                                          {emp.last_name}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                ))}
                            </div>
                          </div>
                        ))}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Post;
