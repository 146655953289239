import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import rootReducer from '../reducer/reducers'; // Create reducers file
const reducer = combineReducers({
  reducer: rootReducer,
})
const store = configureStore({
  reducer,
});

export default store;