// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-fontsize{
    font-size: 13px;
}
.text-blue{
    font-size: 13px;
}
.link-textColor{
    color: black !important;
}
.adjust-name{
    padding-right:60px;
}
*{
    font-family: "Poppins", sans-serif;
   
    font-style: normal;
}
.img-visible{
    visibility: hidden ;
  }
.modal-icon{
    font-Size: 85px;
}
.eye-position{
    top: 8px;
    position: absolute;
    right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kCAAkC;;IAElC,kBAAkB;AACtB;AACA;IACI,mBAAmB;EACrB;AACF;IACI,eAAe;AACnB;AACA;IACI,QAAQ;IACR,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".custom-fontsize{\n    font-size: 13px;\n}\n.text-blue{\n    font-size: 13px;\n}\n.link-textColor{\n    color: black !important;\n}\n.adjust-name{\n    padding-right:60px;\n}\n*{\n    font-family: \"Poppins\", sans-serif;\n   \n    font-style: normal;\n}\n.img-visible{\n    visibility: hidden ;\n  }\n.modal-icon{\n    font-Size: 85px;\n}\n.eye-position{\n    top: 8px;\n    position: absolute;\n    right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
