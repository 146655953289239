import AWS from 'aws-sdk';

AWS.config.update({
   accessKeyId: process.env.AWS_ACCESS_KEY || "AKIAY76T7ZNLX2ESEJU2",
    secretAccessKey: process.env.AWS_SECRET_KEY || "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",
    region: process.env.AWS_REGION || "us-west-1",
});

const s3 = new AWS.S3();

export default s3
