import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "./MiniTodos.css";
import Popup from "../../popup/Popup";
import axios from "axios";
import { useMiniTodoData } from "../AuthContext/minitodos";
import API_BASE_URL from "../../Config.js";
import { Modal, Button } from "react-bootstrap";
import { calculateTimeDifference,formatDate,  } from "../../utils/index";
import { formatDateforDue } from "../../utils/index";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTodo } from "../AuthContext/TodoContext";
import MiniComments from "../comments/MiniComments";
import { fetchPostData } from "../../Api/MiniTodo";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingImg from "../../images/loading.webp";
import ImagePreview from "../../utils/image-preview";
import { useFilterData } from "../AuthContext/SidebarContext.js";
import {
  compressImage,
  videoThumbnail,
  uploadFileToS3,
} from "../../utils/index";
import { BiCopy } from "react-icons/bi";
import { FaFileExport } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { useTodoData } from "../AuthContext/TodoDataContext";
import ToastContainer from "../toast/ToastContainer";
const MiniTodo = ({ fetchData, id,isIconUp,sortOrder, sortBy}) => {
  const videoRef = useRef(null);
  const toastRef = useRef();
  const textareaRef = useRef(null);
  // const [file, setFile] = useState(null);
  // const [thumbnail, setThumbnail] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const { filterData } = useFilterData();
  const [showModal, setShowModal] = useState(false);
  const [selectedTodoId, setSelectedTodoId] = useState(null);
  const { count, setCount, miniTodoData, setMiniTodoData } = useMiniTodoData();
  const [commentValue, setCommentValue] = useState("");
  const [miniComment, setMiniComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [postId, setPostId] = useState(""); // Assuming postId is dynamic
  const [userId, setUserId] = useState("");
  const [showMiniTodos, setShowMiniTodos] = useState({});
  const [currentid, setCurrentId] = useState(null);
  const [pageNo, setPageNo] = useState(2);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasMore, setHasMore] = useState(true); // Assuming userId is dynamic
  const [file, setFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const todos = miniTodoData.todos;
  console.log("tods", todos);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showDoneModalId, setShowDoneModalId] = useState(null);
  const [checkdoneId, setCheckDoneId] = useState(null);
  // console.log("mini todo", todos);
  const activeTab = searchParams.get("status");
  const [loading, setLoading] = useState(false);
  const [todoLoader, setTodoLoader] = useState(false);
  const [preAssign, setPreAssign] = useState([]);
  // const {id} = useParams()
  // console.log("miniDonecomponent",id,activeTab);
  const {
    selectedItemId,
    selectedItemName,
    selectedItemType,
    firstTodoName,
    firstId,
    firstType,
  } = useTodo();
  // console.log("selectedItemId", selectedItemId);
  // console.log("postId", id);

  const [showAddTaskPart, setShowAddTaskPart] = useState(false);
  const [showAddTaskPartId, setShowAddTaskPartId] = useState(false);
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editId, setEditId] = useState(null);
  const [miniTodoId, setMiniTodoId] = useState(null);
  const [miniEdit, setMiniEdit] = useState(null);
  const [reply, setReply] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [deleteMiniTodoParams, setDeleteMiniTodoParams] = useState({
    postId: null,
    todoid: null,
    replyId: null,
  });
  const [showBookMarkModal, setShowBookMarkModal] = useState(false);
  const [bookMarkId, setBookMarkId] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showGroupList, setShowGroupList] = useState(false);
  const [selectedItemId1, setSelectedItemId1] = useState(null);
  const [selectedItemType1, setSelectedItemType1] = useState(null);
  const [todoRecords, setTodoRecords] = useState([]);
  const [showSecondSelect, setShowSecondSelect] = useState(false);
  const [showCheckbox, setShowCheckBox] = useState(false);
  const [mainpostId, setMainPostId] = useState(false);
  const [copyMove, setCopyMove] = useState(null);
  const [groups, setGroups] = useState([]);
  const [employees, setEmployees] = useState([]);
  const groupId = searchParams.get("group_id");
  const selectedType = localStorage.getItem("selectedType");
  const isGroup = selectedType === "group";
  const selectedItemIds = localStorage.getItem("selectedItemId");
  const { todoData, setTodoData } = useTodoData();
  const [assignUsers, setAssignUsers] = useState([]);

  const usersList = JSON.parse(localStorage.getItem("usersList"));

  console.log("posts in minitodos", usersList);
  console.log("group", selectedItemIds);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const handleCopyButtonClick = (todoId) => {
    setCopyMove("copy");
    setSelectedTodoId(todoId);
    setShowSelect(true);
    setShowSecondSelect(null);
  };
  const closeModal = () => {
    setShowSelect(false);
    setShowGroupList(false);
    setShowCheckBox(false);
    setSelectedItemId1(null);
    setSelectedItemType1(null);
    setCheckedCheckboxes([]);
    setPreAssign([]);
  };

  const handleUsersSelect = (event, id) => {
    const checkboxId = id.toString(); // Convert ID to string
    if (event.target.checked) {
      // If checkbox is checked, add its ID to the array
      setCheckedCheckboxes((prevState) => [...prevState, checkboxId]);
    } else {
      // If checkbox is unchecked, remove its ID from the array
      setCheckedCheckboxes((prevState) =>
        prevState.filter((prevId) => prevId !== checkboxId)
      );
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  useEffect(() => {
    // Convert IDs in preAssign to strings to match the data type of IDs in usersList
    const preAssignIds = preAssign.map((user) => user.id.toString());
    setCheckedCheckboxes(preAssignIds);
  }, [preAssign]);
  // useEffect(() => {
  //   console.log("userId", checkedCheckboxes);
  // }, [checkedCheckboxes]);

  // Filter out IDs from preAssign array that are present in checkedCheckboxes array
  const filteredIds = preAssign.map((assign) => assign.id.toString());
  // Filter out numbers from checkedCheckboxes array that are present in removeArray

  console.log("userId", checkedCheckboxes);
  console.log(filteredIds);
  const removeArray = filteredIds.filter(
    (id) => !checkedCheckboxes.includes(id)
  );

  const handleMoveButtonClick = (todoId) => {
    setCopyMove("move");
    setSelectedTodoId(todoId);
    setShowSelect(true);
    setShowSecondSelect(null);
  };

  const handleTaskAssignButtonClick = async (todoId, assigedTo) => {
    console.log("checked");
    setPreAssign(assigedTo);

    setSelectedTodoId(todoId);
    setShowGroupList(true);
  };
  console.log("preAssign", preAssign);
  const handleChangesSelect = (event) => {
    const data = JSON.parse(event.target.value);
    setSelectedItemId1(data?.id);
    setSelectedItemType1(data?.type_of);
  };
  const handleCheckboxChange = (event, todoComment, todoTypeOf, id) => {
    if (copyMove === "copy") {
      handleCopyChange(event, todoComment, todoTypeOf);
    } else if (copyMove === "move") {
      handleMoveChange(event, todoComment, todoTypeOf, id);
    }
  };
  const handleCopyChange = async (event, comment, type) => {
    event.preventDefault();
    console.log("comment", comment, type);
    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;
      let title = event.target.title.value;

      // Check if the user has uploaded an image or video

      // Default case: text only
      bodyData = {
        post_id: mainpostId,
        user_id: selectedItemId1,
        type_of: type, // Set type_of to task for text comments
        comment: comment,
        status: "still_working",
      };

      const response = await fetch(`${API_BASE_URL}/api/v1/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        // Handle success
        console.log("Comment copied successfully");
        // Optionally, you can clear the comment input field here
        // setComment("");
        setSelectedItemId1(null);
        setMainPostId(null);
        setShowCheckBox(false);
        setShowSecondSelect(false);
        setShowSelect(false);
        toastRef.current.showToast("Comment copied Successfully");

        // Fetch data again after a successful API call
        await fetchData();
      } else {
        // Handle errors
        console.error("Failed to add comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleMoveChange = async (event, comment, type, id) => {
    console.log("move", comment, type);

    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;

      bodyData = {
        post_id: mainpostId,
        comment_id: id,
      };

      const response = await fetch(`${API_BASE_URL}/api/v1/movetask`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        // Handle success
        console.log("Comment moved successfully");
        // Optionally, you can clear the comment input field here
        // setComment("");
        toastRef.current.showToast("Comment moved Successfully");
        setSelectedItemId1(null);
        setMainPostId(null);
        setShowCheckBox(false);
        setShowSecondSelect(false);
        setShowSelect(false);
        setCopyMove(null);
        fetchData();
      } else {
        // Handle errors
        console.error("Failed to add comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    if (selectedItemId1 !== null && selectedItemType1 !== null) {
      console.log("id,type", selectedItemId1, selectedItemType1);
      fetchTodoRecords(); // Call your function here
      setShowSecondSelect(true);
    }
  }, [selectedItemId1, selectedItemType1]);
  const handleChangesSelect1 = (event) => {
    console.log(event.target.value);
    const data1 = JSON.parse(event.target.value);
    console.log("postId:", data1?.id); // Log the id property
    setMainPostId(data1?.id);
    setShowCheckBox(true);
    // Log the updated mainPostId state
  };

  console.log("id,type", selectedItemId1, selectedItemType1);
  // mainpost fetch records
  const fetchTodoRecords = async () => {
    setTodoLoader(true);
    try {
      const storedToken = localStorage.getItem("token");

      if (!selectedItemId1 || !storedToken) {
        throw new Error("Missing required parameters");
      }

      let currentPage = 1;
      let lastPage = 1;
      let allTodoRecords = [];

      while (currentPage <= lastPage) {
        let apiUrl = `${API_BASE_URL}/api/v1/posts?status=still_working&per_page=20&page=${currentPage}`;

        if (selectedItemType1 === "user") {
          apiUrl += `&user_id=${selectedItemId1}&type=to-do`;
        } else if (selectedItemType1 === "group") {
          apiUrl += `&group_id=${selectedItemId1}&type=to-do`;
        }

        const headers = {
          Authorization: `Bearer ${storedToken}`,
        };

        console.log("API URL:", apiUrl);

        const response = await fetch(apiUrl, { headers });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Network response was not ok: ${errorMessage}`);
        }

        const responseData = await response.json();
        console.log("Response:", responseData);

        // Add the fetched posts to allTodoRecords array
        allTodoRecords = [...allTodoRecords, ...responseData.data.posts];

        // Update last page
        lastPage = responseData.data.pagination.last_page;
        currentPage++;
      }

      // Update todoRecords state with all fetched data
      setTodoRecords(allTodoRecords);
      setTodoLoader(false);
      console.log("All Todo Records:", allTodoRecords);
      return allTodoRecords;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const handleBookmarkModal = (id) => {
    setShowBookMarkModal(true);
    setBookMarkId(id);
  };
  const handleCloseBookMarkModal = () => {
    setShowBookMarkModal(false);
    setBookMarkId(null);
  };

  const handleOpenModal1 = (postId, todoid, replyId) => {
    console.log("Opening modal...");
    setDeleteMiniTodoParams({ postId, todoid, replyId });
    setModalOpen(true);
  };
  const handleCloseModal1 = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = textarea.scrollHeight + "px"; // Set height to the scrollHeight
    }
  }, [inputValue]);

    const fetchRecords = async () => {
      try {
        setLoading(true);
        const sort = isIconUp ? 'asc' : 'desc';
        const newData = await fetchPostData(id, activeTab, pageNo,sortBy,sortOrder);
        setMiniTodoData((prevData) => ({
          todos: [...prevData.todos, ...newData.data.todos],
        }));
        setHasMore(
          newData?.data?.pagination?.current_page <=
            newData?.data?.pagination?.last_page
        );
        setPageNo(newData?.data?.pagination?.current_page + 1);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

  // useEffect(()=>{
  // fetchRecords()
  // },[])

  const handleDeleteConfirmation = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      const response = await fetch(
        `${API_BASE_URL}/api/v1/posts/${deleteMiniTodoParams.postId}/todos/${deleteMiniTodoParams.todoid}/replies/${deleteMiniTodoParams.replyId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.ok) {
        const updatedtodos = todos?.map((todo) => {
          if (todo?.id === deleteMiniTodoParams.todoid) {
            todo.mini_todos = todo?.mini_todos.filter(
              (e) => e?.id !== deleteMiniTodoParams.replyId
            );
          }
          return todo;
        });
        setMiniTodoData({ ...miniTodoData, todos: updatedtodos });

        // Show success toast message
        toastRef.current.showToast("comment deleted Successfully");
      } else {
        console.error("Failed to delete mini-todo", response.statusText);
        toastRef.current.showToast("Failed to delete mini-todo");
      }
    } catch (error) {
      console.error("Error while deleting mini-todo:", error);
      toastRef.current.showToast("Failed to delete mini-todo");
    } finally {
      setModalOpen(false);
    }
  };

  const formRef = useRef(null);
  const storedToken = localStorage.getItem("token");
  const handleCommentClick = () => {
    localStorage.setItem("postId", id);
  };
  //adding minitodos
  const handleFileChange = async (event) => {
    setThumbnail(null);
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image")) {
        const cfile = await compressImage(file);
        setFile(cfile);
      } else {
        setFile(file);
        const thumbnail = await videoThumbnail(file);
        const cfile = await compressImage(thumbnail);
        setThumbnail(cfile);
      }
    }
  };
  const handleFileDelete = () => {
    setFile(null);
    setThumbnail(null);
  };

  const handleMiniSubmit = async (event, postId, todoId) => {
    event.preventDefault();

    try {
      const storedToken = localStorage.getItem("token");
      let bodyData;
      let title = event.target.title.value;

      // Check if the user has uploaded an image or video
      if (file) {
        const imgVideoUrl = await uploadFileToS3(file);
        const fileType = file.type.startsWith("image") ? "image" : "video";
        bodyData = {
          type_of: `task_${fileType}`,
          comment: `${imgVideoUrl}|&|${title || ""}`,
        };
      } else if (thumbnail) {
        const imgVideoUrl = await uploadFileToS3(thumbnail);
        bodyData = {
          type_of: "task_video",
          comment: `${imgVideoUrl}|&|${title || ""}`,
        };
      } else {
        // Default case: text only
        bodyData = {
          type_of: "task",
          comment: title,
        };
      }

      const response = await fetch(
        `${API_BASE_URL}/api/v1/posts/${postId}/todos/${todoId}/replies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (response.ok) {
        // Handle success
        toastRef.current.showToast("comment Added successfully!");
        setShowAddTaskPart(false);
        setMiniComment("");
        setFile(null);

        const responseData = await response.json();

        if (responseData && responseData.data) {
          const newMiniTodo = responseData.data; // Assuming the new mini-todo is directly under "data"

          // Update miniTodoData state with the new mini-todo
          setMiniTodoData((prevData) => {
            if (!prevData || !prevData.todos) {
              return { todos: [newMiniTodo] };
            }

            const updatedTodos = prevData.todos.map((todo) => {
              if (todo.id === todoId) {
                return {
                  ...todo,
                  mini_todos: [...(todo.mini_todos || []), newMiniTodo],
                };
              }
              return todo;
            });

            return {
              ...prevData,
              todos: updatedTodos,
            };
          });
        } else {
          console.error(
            "Failed to add mini-todo: Response data missing or invalid"
          );
        }
      } else {
        // Handle errors
        console.error("Failed to add mini-todo");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleReplySubmit = async (event, postId, todoId) => {
    event.preventDefault();
    const requestData = {
      comment: replyComment,
      type_of: "text",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/v1/posts/${postId}/todos/${todoId}/replies`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        // Handle success, maybe close the add task part or reset the form
        setShowAddTaskPart(false);
        setMiniComment("");
        fetchData();
        // Additional actions if needed
      } else {
        // Handle errors
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //deleting minitodos

  //showing minitod logic
  const handleShowMiniTodos = (id) => {
    setShowMiniTodos((prev) => ({ ...prev, [id]: !prev[id] }));
    // setIconRotation((prev) => !prev);
    setCurrentId(id);
  };
  const handleMiniEdit = (id, comment) => {
    setMiniEdit(true);
    setMiniTodoId(id);
    setInputValue(comment);
  };

  const handleReply = (id) => {
    setReply(true);
    setMiniTodoId(id);
  };
  const handleMiniCloseEdit = () => {
    setMiniEdit(false);
  };
  const handleMiniUpdate = async (postId, todoid, replyId) => {
    try {
      const storedToken = localStorage.getItem("token");

      const response = await fetch(
        `${API_BASE_URL}/api/v1/posts/${postId}/todos/${todoid}/replies/${replyId}`,
        {
          method: "PUT", // Change the method to PUT for updating
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify({
            type_of: "task",
            comment: inputValue, // Assuming inputValue is defined elsewhere
            status: "still_working",
          }),
        }
      );

      if (response.ok) {
        console.log("Comment updated successfully");

        // Assuming you have todos and setTodos functions defined in your component
        const updatedTodos = todos?.map((todo) => {
          if (todo.id === todoid) {
            // Update the specific mini-todo within the todo
            todo.mini_todos = todo?.mini_todos?.map((miniTodo) => {
              if (miniTodo.id === replyId) {
                return {
                  ...miniTodo,
                  comment: inputValue, // Update the comment with the new value
                  status: "still_working", // Update status if needed
                };
              }
              return miniTodo;
            });
          }
          return todo;
        });

        // Update the state with the updated todos
        setMiniTodoData({ ...miniTodoData, todos: updatedTodos });
        toastRef.current.showToast("comment Updated successfully!");
      } else {
        console.error("Failed to update comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
    setMiniEdit(false);
  };

  //handle addminitodo logic
  const handleAddMiniTask = (id) => {
    setShowAddTaskPart(!showAddTaskPart);
    setShowAddTaskPartId(id);
  };
  //update todo logic
  const generateVideoThumbnail = async (videoUrl) => {
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;
    videoElement.muted = true;
    videoElement.currentTime = 5; // Set the time to capture the thumbnail (e.g., 5 seconds)

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    return new Promise((resolve, reject) => {
      videoElement.onloadeddata = () => {
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          resolve(blob);
          videoElement.pause();
          videoElement.src = "";
          videoElement.remove();
          canvas.remove();
        }, "image/jpeg");
      };

      videoElement.onerror = (error) => {
        reject(error);
        videoElement.remove();
        canvas.remove();
      };

      videoElement.src = videoUrl;
      videoElement.autoplay = true;
    });
  };
  const handleOpenEdit = async (id, comment, type) => {
    setEditing(true); // Always set editing to true when opening the edit mode
    setEditId(id);
    setInputValue(comment); // Initialize inputValue with the comment value

    if (type === "task" || type === "text") {
      setInputValue(comment);
    } else if (type === "task_image") {
      // Extract the image URL from the comment
      const imageUrl = comment.split("|&|")[0];
      setInputValue(comment.split("|&|")[1]);
      setThumbnail(imageUrl); // Set thumbnail to display the image preview
    } else if (type === "task_video") {
      const videoUrl = comment.split("|&|")[0];
      setInputValue(comment.split("|&|")[1]);
      const thumbnail1 = await videoThumbnail(videoUrl);
      console.log("thumbnail", thumbnail1);
      setThumbnail(thumbnail1);
    } else {
      setInputValue(comment.split("|&|")[1]);
    }

    setCommentValue(comment);
    
    setTimeout(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto"; // Reset height to auto to get the correct scrollHeight
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to the scrollHeight
      }
    }, 0);
  };
  const handleCloseEdit = () => {
    setEditing(false);
    setMiniEdit(false);
    setFile(null);
    setThumbnail(null);
  };
  const handleCloseReply = () => {
    setReply(false);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleUpdate = async (e, id, type) => {
    e.preventDefault();
    try {
      let updatedComment;
      let updatedType = type;

      if (type === "task" || type === "text") {
        // Check if a new file is uploaded
        if (file) {
          // Determine the updated type based on the file type
          updatedType = file.type.startsWith("image")
            ? "task_image"
            : "task_video";
          // Upload the file to S3
          const newUrl = await uploadFileToS3(file);
          // Construct the updated comment with the new image URL
          updatedComment = `${newUrl}|&|${inputValue}`;
        } else {
          // No file uploaded, use the existing comment
          updatedComment = inputValue;
        }
      } else {
        const [previousUrl, previousText] = commentValue.split("|&|");
        let newUrl;

        // Check if the type is 'task_image' and no new file uploaded, but text is edited
        if (type === "task_image" && !file) {
          // Combine the existing image URL with the edited text
          updatedComment = `${previousUrl}|&|${inputValue}`;
        } else {
          // Handle file upload
          if (file) {
            newUrl = await uploadFileToS3(file);
            updatedType = file.type.startsWith("image")
              ? "task_image"
              : "task_video";
          } else if (thumbnail) {
            // If thumbnail exists but handleDeleteFile was triggered, set updatedType to 'task'
            if (!file) {
              updatedType = "task";
              updatedComment = inputValue; // Set comment to previous text without URL
            } else {
              newUrl = await uploadFileToS3(thumbnail);
              updatedType = thumbnail.type.startsWith("image")
                ? "task_image"
                : "task_video";
            }
          }

          updatedComment = `${newUrl || previousUrl}|&|${
            inputValue || previousText
          }`;
        }
      }

      // Check if handleFileDelete was triggered and no media files added
      if (!file && !thumbnail) {
        updatedType = "task"; // Set type to 'task'
        updatedComment = inputValue; // Update comment to inputValue
      }

      const response = await fetch(`${API_BASE_URL}/api/v1/comments/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          type_of: updatedType,
          comment: updatedComment,
          status: "still_working",
        }),
      });

      if (response.ok) {
        const newComment = await response.json();
        const newData = newComment.data;
        const newPosts = todos?.map((e) => {
          if (e) {
            if (e?.id === id) {
              console.log("Comment updated successfully", newData);
              return newData;
            } else {
              return e;
            }
          }
        });
        setMiniTodoData({ ...miniTodoData, todos: newPosts });
        toastRef.current.showToast("post updated Successfully!");
        // await fetchData();
      } else {
        console.error("Failed to update comment");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
    setEditing(false);
  };

  // adding todo logoic

  // Helper function to check if a string is a valid YouTube URL
  const isValidYouTubeUrl = (url) => {
    // Implement your YouTube URL validation logic here
    // Return true if the URL is valid, false otherwise
  };

  // Function to open the modal
  const handleOpenModal = (todoId) => {
    setSelectedTodoId(todoId); // Set the selected todo ID
    setShowModal(true); // Open the modal
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDoneClick = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        const apiUrl = `${API_BASE_URL}/api/v1/comments/${showDoneModalId}`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const postData = {
          status: "done",
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setShowDoneModal(false);
        setShowDoneModalId(null);
        // fetchData();
        setMiniTodoData((prevData) => {
          const idToFilter = showDoneModalId;

          const filteredTodos = prevData.todos.filter(
            (todo) => todo?.id !== idToFilter
          );

          return {
            todos: filteredTodos,
          };
        });
        setCount((prevTodoData) => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount - 1,
          doneCount: prevTodoData.doneCount + 1,
        }));

        toastRef.current.showToast("status updated Successfully!");
      }
    } catch (error) {
      console.error("Error updating todo:", error);
    }
  };
  const handleClickDone = (id) => {
    setShowDoneModalId(id);
    setShowDoneModal(true);
    setCheckDoneId(id);
  };
  const handleCloseDoneModal = () => {
    setShowDoneModal(false);
    setShowDoneModalId(null);
    setCheckDoneId(null);
  };
  const handleDelete = async () => {
    try {
      const storedToken = localStorage.getItem("token");

      if (storedToken && selectedTodoId) {
        const apiUrl = `${API_BASE_URL}/api/v1/comments/${selectedTodoId}`;
        const headers = {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        };
        const postData = {
          status: "deleted",
        };

        const response = await fetch(apiUrl, {
          method: "PUT",
          headers,
          body: JSON.stringify(postData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        console.log("deleted successfully");
        // fetchData();
        handleCloseModal(); // Close the modal after deletion
        setMiniTodoData((prevData) => {
          const idToFilter = selectedTodoId;

          const filteredTodos = prevData.todos.filter(
            (todo) => todo?.id !== idToFilter
          );

          return {
            todos: filteredTodos,
          };
        });
        setCount((prevTodoData) => ({
          ...prevTodoData,
          todoCount: prevTodoData.todoCount - 1,
          deleteCount: prevTodoData.deleteCount + 1,
        }));
        toastRef.current.showToast("Post Deleted Successfully!");
      }
    } catch (error) {
      console.error("Error deleting todo:", error);
    }
  };
  const handleBookmark = async (bookmark) => {
    try {
      const storedToken = localStorage.getItem("token");
      const response = await fetch(
        `${API_BASE_URL}/api/v1/todos/${bookMarkId}}/bookmarks`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
          body: JSON.stringify({
            isBookMarked: !bookmark,
          }),
        }
      );

      if (response.ok) {
        // Update the local state immediately after the API call
        setMiniTodoData((prevData) => {
          const updatedTodos = prevData.todos?.map((todo) =>
            todo?.id === bookMarkId
              ? { ...todo, is_bookmarked: !bookmark }
              : todo
          );
          return { ...prevData, todos: updatedTodos };
        });

        console.log("Bookmark success");
        fetchData();
      }
    } catch (error) {
      console.error("Error while updating bookmark status:", error);
      // Handle error if needed
    }
  };
  const stopVideo = () => {
    console.log("stop video functio is coled");
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleTaskAssign = async () => {
    // Reset assignUsers state

    const token = localStorage.getItem("token");
    const url = "https://beta.yesdone.com/api/v1/assign-task";

    // Check if there are any checkboxes checked
    if (checkedCheckboxes?.length > 0) {
      const requestBody = {
        comment_id: selectedTodoId,
        user_id: checkedCheckboxes,
        remove_user_ids: removeArray,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Handle the response data here
        const rec = data.data.todo;
        const newPosts = todos?.map((e) => {
          if (e) {
            if (e?.id === selectedTodoId) {
              console.log("rec", rec);
              return rec;
            } else {
              return e;
            }
          }
        });
        setMiniTodoData({ ...miniTodoData, todos: newPosts });
        closeModal();

        // Filter usersList and setAssignUsers only if request is successful
        const matchedObjects = usersList.filter((obj) =>
          checkedCheckboxes.includes(obj?.id.toString())
        );
        // setAssignUsers([]);
        // setAssignUsers(matchedObjects);
        // console.log("matched objects in assign", matchedObjects);
        toastRef.current.showToast("Task Assigned Successfully");
      } catch (error) {
        // Handle fetch errors here
        console.error("There was a problem with the fetch operation:", error);

        toastRef.current.showToast("Failed to Task Assign");
      }
    } else {
      toastRef.current.showToast("please select atleast one checkbox");
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={todos?.length}
        next={() => fetchRecords()}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
      >
        <div className="scrollableMain" id="scrollableDiv">
          {todos?.map((todo, index) => (
            <React.Fragment key={todo?.id + todo?.name || index}>
              {todo?.mini_todos?.length > 0 ? (
                <>
                  <div className="container">
                    <div className="row bottom-border ms-3">
                      <div className="col-sm-1 col-2 col-md-1 d-flex justify-content-between checkbox-pl ms-2 ps-0">
                        <span className="text-black fsize ">{index + 1}.</span>
                        <span className="text-start checkbox-pl">
                          <Tippy content="checkbox">
                            <input
                              className="form-check-input custm-checkbox1  cursor-pointer pe-1  "
                              type="checkbox"
                              name="checkboxes"
                              checked={checkdoneId === todo?.id}
                              // onChange={() => handleDoneClick(todo?.id)}
                              onChange={() => handleClickDone(todo?.id)}
                            />
                          </Tippy>
                        </span>
                      </div>
                      <div className="col-sm-7 col-7 col-md-7 ps-0">
                        {editing && editId === todo?.id ? (
                          <div className=" d-flex ms-3">
                            <form
                              action=""
                              id="AddTask"
                              onSubmit={(e) =>
                                handleUpdate(e, todo?.id, todo?.type_of)
                              }
                            >
                              <div
                                className="taskborder pt-0 px-0 pb-1 d-flex align-items-center"
                                // style={{
                                //   display: "flex",
                                //   alignItems: "center",
                                // }}
                              >
                                <input
                                  type="text"
                                  className="form-control add-input px-0 shadow-none border-0"
                                  required
                                  name="title"
                                  placeholder="+   Name of Task"
                                  value={inputValue} // Use inputValue here
                                  onChange={(e) =>
                                    handleInputChange(e.target.value)
                                  }
                                />
                              </div>
                              <div className="videpart">
                                <label className="custom-file-upload">
                                  <input
                                    type="file"
                                    className="custom-file-input shadow-none"
                                    id="mediaFile"
                                    name="files"
                                    accept="image/*, video/*"
                                    onChange={handleFileChange}
                                  />
                                  <img
                                    src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                    alt=""
                                    width="22px"
                                    height="18px"
                                    className="cursor-point"
                                  />
                                </label>
                                <input
                                  type="text"
                                  className="form-control add-input border-0 shadow-none"
                                  name="video_url"
                                  placeholder="Youtube Video"
                                />
                                <button
                                  type="submit"
                                  className="submitclass btn"
                                >
                                  <img
                                    src="https://yesdone.com/build/assets/send-99cff475.png"
                                    alt="send img"
                                    width="35px"
                                    height="35px"
                                    className="cursor-point"
                                  />
                                </button>
                              </div>
                            </form>
                            {(thumbnail || file) && (
                              <span className="ps-3">
                                <ImagePreview
                                  file={thumbnail || file}
                                  onDelete={handleFileDelete}
                                />
                              </span>
                            )}
                            <span className="ps-3 pt-3">
                              <button
                                className="btn btn-success d-flex align-items-center justify-content-center"
                                onClick={handleCloseEdit}
                              >
                                <Tippy content="delete">
                                  <i className="bi bi-x"></i>
                                </Tippy>
                              </button>
                            </span>
                          </div>
                        ) : (
                          <div className="rel-position">
                            {todo?.type_of === "task" ||
                            todo?.type_of === "text" ? (
                              <>
                                <span className="text-dark d-flex fw-medium posttime text-start text-break  cursor-pointer">
                                  <img
                                    className="hidden me-2 img-visible"
                                    src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png"
                                    alt="Hidden Images"
                                    // style={{ visibility: "hidden" }}
                                    width="25px"
                                    height="19px"
                                  />
                                  <Link
                                    to={`/posts/${id}/${todo?.id}/todo-comments`}
                                    onClick={handleCommentClick}
                                  >
                                    {todo.comment}
                                  </Link>
                                  <div
                                    onClick={() =>
                                      handleShowMiniTodos(todo?.id)
                                    }
                                    id={`collapseOne${todo?.id}`}
                                    className="abs-position"
                                    // className={`rotate-icon${isIconRotated ? ' rotated' : ''}`}
                                  >
                                    {/* <i className="bi bi-caret-down-fill"></i> */}
                                    {todo?.mini_todos?.length > 0 &&
                                    showMiniTodos[todo?.id] ? (
                                      <FontAwesomeIcon
                                        icon={faAngleUp}
                                        title="Show Mini Todos"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faAngleDown}
                                        title="Close Mini Todos"
                                      />
                                    )}
                                  </div>
                                </span>
                                <p className="post-time mb-0 text-black  update-position">
                                by {" "}{todo?.creator?.name} on {formatDate(todo?.created_at)}
                                </p>
                              </>
                            ) : todo?.type_of === "task_image" ||
                              todo?.type_of === "task_video" ||
                              todo?.type_of === "task_youtube" ? (
                              <>
                                {(() => {
                                  const parts = todo.comment.split("|&|");
                                  const isVideo =
                                    todo?.type_of === "task_video";
                                  const [mediaUrl, text] =
                                    parts?.length === 2
                                      ? parts
                                      : [todo.comment, todo.comment];

                                  // Your code specific to "task_image", "task_video", or "task_youtube"
                                  return (
                                    <>
                                      <span className="d-flex  fw-medium cursor-point">
                                        <Popup
                                          url={mediaUrl}
                                          isVideo={isVideo}
                                          text={text}
                                        />

                                        <span className="posttime mb-0 text-black ps-1">
                                          <Link
                                            to={`/posts/${id}/${todo?.id}/todo-comments`}
                                            onClick={handleCommentClick}
                                          >
                                            {text}
                                          </Link>
                                          <span
                                            onClick={() =>
                                              handleShowMiniTodos(todo?.id)
                                            }
                                            id={`collapseOne${todo?.id}`}
                                            className="abs-position"
                                            // className={`rotate-icon${isIconRotated ? ' rotated' : ''}`}
                                          >
                                            {/* <i className="bi bi-caret-down-fill"></i> */}
                                            {todo?.mini_todos?.length > 0 &&
                                            showMiniTodos[todo?.id] ? (
                                              <FontAwesomeIcon
                                                icon={faAngleUp}
                                                title="Close Mini Todos"
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                icon={faAngleDown}
                                                title="Close Mini Todos"
                                              />
                                            )}
                                          </span>
                                        </span>
                                      </span>
                                      <p className="post-time mb-0 text-black ps-1 update-position ">
                                      by {" "}{todo?.creator?.name} on {formatDate(todo?.created_at)}
                                      </p>
                                    </>
                                  );
                                })()}
                              </>
                            ) : (
                              <div
                                className="minitodo"
                                // style={{ width: "25px", height: "19px" }}
                              ></div>
                            )}
                            <div className="update-position">
                              <span
                                className=" pe-2 cursor-point"
                                onClick={() =>
                                  handleOpenEdit(
                                    todo?.id,
                                    todo.comment,
                                    todo?.type_of
                                  )
                                }
                              >
                                <Tippy content="edit">
                                  <img
                                    src="https://yesdone.com/build/assets/pencil-bc3d8d52.png"
                                    alt=""
                                    width="12px"
                                    height="12px"
                                    className="pt-0"
                                  />
                                </Tippy>
                              </span>
                              <Tippy content="adding-subtask">
                                <span
                                  className="cursor-point  px-2"
                                  onClick={() => handleAddMiniTask(todo?.id)}
                                >
                                  <img
                                    src="https://yesdone.com/build/assets/greyplus-2f2d8fa2.png"
                                    alt="greyplus"
                                    width="12px"
                                    height="12px"
                                    className=""
                                  />
                                </span>
                              </Tippy>
                              <span className="">
                                <Link
                                  to={`/posts/${id}/${todo?.id}/todo-comments`}
                                  onClick={handleCommentClick}
                                >
                                  <Tippy content="minicomments">
                                    <span className="green-icon px-1">
                                      {todo?.mini_todos?.length > 0 ? (
                                        <>
                                          <img
                                            src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                            alt="Green Message"
                                            width="14px"
                                            height="14px"
                                            className="cursor-point "
                                          />
                                          <span>
                                            {" "}
                                            {todo?.mini_todos?.length}
                                          </span>
                                        </>
                                      ) : (
                                        <img
                                          src="https://beta.yesdone.com/build/assets/greymessage-7c7383fd.png"
                                          alt="emptypen"
                                          width="14px"
                                          height="14px"
                                          className="cursor-point"
                                        />
                                      )}
                                    </span>
                                  </Tippy>
                                </Link>
                              </span>
                              <span key={todo?.id}>
                                <Tippy content="copy">
                                  <span className="button-container cursor-point px-2">
                                    <BiCopy
                                      onClick={() =>
                                        handleCopyButtonClick(todo?.id)
                                      }
                                      className="button-icon"
                                    />
                                  </span>
                                </Tippy>
                              </span>
                              <Tippy content="Move">
                                <span className="button-container cursor-point pe-1">
                                  <FaFileExport
                                    onClick={() =>
                                      handleMoveButtonClick(todo?.id)
                                    }
                                    className="button-icon"
                                  />
                                </span>
                              </Tippy>
                              {isGroup && (
                                <Tippy content="Task_Assign">
                                  <span className="button-container cursor-point pe-1">
                                    <FaUserPlus
                                      onClick={() =>
                                        handleTaskAssignButtonClick(
                                          todo?.id,
                                          todo?.assigned_to
                                        )
                                      }
                                      className="button-icon"
                                    />
                                  </span>
                                </Tippy>
                              )}
                              <Tippy content="delete">
                                <span
                                  className="pe-2 ps-1"
                                  onClick={() => handleOpenModal(todo?.id)}
                                >
                                  {" "}
                                  {/* Pass the todo ID to handleOpenModal */}
                                  <img
                                    src="https://yesdone.com/build/assets/bin-15fd3a47.png"
                                    alt="bin"
                                    width="14px"
                                    height="14px"
                                    className="cursor-point"
                                  />
                                </span>
                              </Tippy>
                              <Tippy
                                content={
                                  todo?.is_bookmarked
                                    ? "Bookmark Checked"
                                    : "Bookmark"
                                }
                              >
                                <span
                                  className="ps-1"
                                  onClick={() =>
                                    handleBookmarkModal(
                                      todo?.id,
                                      todo?.is_bookmarked
                                    )
                                  }
                                >
                                  {todo?.is_bookmarked ? (
                                    <img
                                      src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                      alt="emptypen"
                                      width="11px"
                                      height="15px"
                                      className="cursor-point"
                                    />
                                  ) : (
                                    <img
                                      src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                      alt="emptypen"
                                      width="11px"
                                      height="15px"
                                      className="cursor-point"
                                    />
                                  )}
                                </span>
                              </Tippy>
                           
                             
                              <span className="d-flex align-items-center">
                                {showSelect && selectedTodoId === todo?.id && (
                                  <div className="modal copyMoveModal">
                                    <div className="modal-content modal-content1">
                                      <div className="container">
                                        {todo?.type_of === "task" ||
                                        todo?.type_of === "text" ? (
                                          <>
                                            <div className="row pb-4">
                                              <div className="col-1"></div>
                                              <div className="col-10 d-flex justify-content-center align-items-center">
                                                <h1 className="text-dark d-flex  text-break mb-0  fw-medium posttime pb-2">
                                                  {todo.comment}
                                                </h1>
                                              </div>

                                              <div
                                                className="close col-1  px-0 pb-2 "
                                                onClick={closeModal}
                                              >
                                                &times;
                                              </div>
                                            </div>
                                          </>
                                        ) : todo?.type_of === "task_image" ||
                                          todo?.type_of === "task_video" ||
                                          todo?.type_of === "task_youtube" ? (
                                          <>
                                            {(() => {
                                              const parts =
                                                todo.comment.split("|&|");
                                              const isVideo =
                                                todo?.type_of === "task_video";
                                              const [mediaUrl, text] =
                                                parts?.length === 2
                                                  ? parts
                                                  : [
                                                      todo.comment,
                                                      todo.comment,
                                                    ];
                                              // Here you can use mediaUrl and text as needed
                                              return (
                                                <>
                                                  <div className="row pb-4">
                                                    <div className="col-1"></div>
                                                    <div className="col-10 d-flex justify-content-center align-items-center">
                                                      <h1 className="text-dark d-flex  text-break mb-0  fw-medium posttime pb-2">
                                                        {text}
                                                      </h1>
                                                    </div>

                                                    <div
                                                      className="close col-1  px-0 pb-2 "
                                                      onClick={closeModal}
                                                    >
                                                      &times;
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })()}
                                          </>
                                        ) : null}
                                      </div>

                                      <span className="d-flex justify-content-center pb-3">
                                        <select
                                          onChange={handleChangesSelect}
                                          className="selectbox"
                                        >
                                          <option value="" disabled selected>
                                            Select Group Or User
                                          </option>
                                          {filterData?.map((item) => (
                                            <option
                                              key={item?.id}
                                              value={JSON.stringify(item)}
                                            >
                                              {item?.name}
                                            </option>
                                          ))}
                                        </select>
                                      </span>
                                      <div className="d-flex justify-content-center pb-3">
                                        {showSecondSelect &&
                                          selectedTodoId === todo?.id && (
                                            <>
                                              <select
                                                onChange={handleChangesSelect1}
                                                className="selectbox1"
                                              >
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  Select Post
                                                </option>
                                                {todoLoader ? (
                                                  <p>loading...</p>
                                                ) : (
                                                  <>
                                                    {todoRecords?.map(
                                                      (item) => (
                                                        <option
                                                          key={item?.id}
                                                          value={JSON.stringify(
                                                            item
                                                          )}
                                                        >
                                                          {item.notes?.length >
                                                          35
                                                            ? `${item.notes.substring(
                                                                0,
                                                                35
                                                              )}...`
                                                            : item.notes}
                                                        </option>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </select>
                                            </>
                                          )}
                                      </div>
                                      <div className="d-flex justify-content-center ps-2">
                                        {showCheckbox &&
                                          selectedTodoId === todo?.id && (
                                            <>
                                              <button
                                                className="btn btn-primary"
                                                onClick={(event) => {
                                                  // Check the condition and execute the corresponding function
                                                  handleCheckboxChange(
                                                    event,
                                                    todo.comment,
                                                    todo?.type_of,
                                                    todo?.id
                                                  );
                                                }}
                                                disabled={!copyMove} // Disable button if copyMove is empty
                                              >
                                                {copyMove
                                                  ? copyMove
                                                  : "Select Action"}{" "}
                                                {/* Button label */}
                                              </button>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </span>
                              <span>
                                {showGroupList &&
                                  selectedTodoId === todo?.id && (
                                    <div className="modal copyMoveModal">
                                      <div className="modal-content modal-content1">
                                        <span className="d-flex justify-content-center">
                                          {todo?.type_of === "task" ||
                                          todo?.type_of === "text" ? (
                                            <>
                                              <div className="container ">
                                                <div className="row">
                                                  <span className="col-10 d-flex justify-content-center align-items-center">
                                                    <h5 className="text-dark text-break fw-medium  ">
                                                      {todo.comment}
                                                    </h5>
                                                  </span>
                                                  <span
                                                    className="close col-2 d-flex justify-content-center"
                                                    onClick={closeModal}
                                                  >
                                                    &times;
                                                  </span>
                                                </div>
                                                <div className="users-container pb-2">
                                                  <div
                                                    className="scrollableMain"
                                                    id="scrollableDiv"
                                                  >
                                                    <ul className="list-group list-group-flush">
                                                      {usersList.map((user) => (
                                                        <li
                                                          key={user.id}
                                                          className="list-group-item list-users"
                                                        >
                                                          <div className="d-flex row">
                                                            <p className="p-0 m-0 col-9 list-users-font">
                                                              {user.first_name ||
                                                                "KranthiAllaboina@gmail.com"}{" "}
                                                              {user.last_name ||
                                                                ""}
                                                            </p>
                                                            <span className="col-2">
                                                              <input
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                  handleUsersSelect(
                                                                    e,
                                                                    user.id
                                                                  )
                                                                }
                                                                id={user.id}
                                                                name="video_url"
                                                                className="col-3 border-0 ms-4 p-0 custm-checkbox form-check-input shadow-none"
                                                                checked={checkedCheckboxes.includes(
                                                                  user.id.toString()
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </li>
                                                      ))}
                                                    </ul>
                                                  </div>
                                                  <span className="d-flex justify-content-center">
                                                    <button
                                                      onClick={(e) =>
                                                        handleTaskAssign(e)
                                                      }
                                                      className="butn-green1 pb-2"
                                                    >
                                                      Assign
                                                    </button>
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ) : todo?.type_of === "task_image" ||
                                            todo?.type_of === "task_video" ||
                                            todo?.type_of === "task_youtube" ? (
                                            <>
                                              {(() => {
                                                const parts =
                                                  todo.comment.split("|&|");
                                                const isVideo =
                                                  todo?.type_of ===
                                                  "task_video";
                                                const [mediaUrl, text] =
                                                  parts?.length === 2
                                                    ? parts
                                                    : [
                                                        todo.comment,
                                                        todo.comment,
                                                      ];
                                                // Here you can use mediaUrl and text as needed
                                                return (
                                                  <div className="container">
                                                    <div className="row">
                                                      <span className="col-10 d-flex justify-content-center align-items-center">
                                                        <h5 className="text-dark text-break fw-medium ">
                                                          {text}
                                                        </h5>
                                                      </span>
                                                      <span
                                                        className="close col-2 d-flex justify-content-center"
                                                        onClick={closeModal}
                                                      >
                                                        &times;
                                                      </span>
                                                    </div>

                                                    <div className="users-container pb-2">
                                                      <div
                                                        className="scrollableMain"
                                                        id="scrollableDiv"
                                                      >
                                                        <ul className="list-group list-group-flush">
                                                          {usersList.map(
                                                            (user) => (
                                                              <li
                                                                key={user.id}
                                                                className="list-group-item list-users"
                                                              >
                                                                <div className="d-flex row">
                                                                  <p className="p-0 m-0 col-9 list-users-font">
                                                                    {user.first_name ||
                                                                      "KranthiAllaboina@gmail.com"}{" "}
                                                                    {user.last_name ||
                                                                      ""}
                                                                  </p>
                                                                  <span className="col-2">
                                                                    <input
                                                                      type="checkbox"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleUsersSelect(
                                                                          e,
                                                                          user.id
                                                                        )
                                                                      }
                                                                      id={
                                                                        user.id
                                                                      }
                                                                      name="video_url"
                                                                      className="col-3 border-0 ms-4 p-0 custm-checkbox form-check-input shadow-none"
                                                                      checked={checkedCheckboxes.includes(
                                                                        user.id.toString()
                                                                      )}
                                                                    />
                                                                  </span>
                                                                </div>
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                      <span className="d-flex justify-content-center">
                                                        <button
                                                          onClick={(e) =>
                                                            handleTaskAssign(e)
                                                          }
                                                          className="butn-green1"
                                                        >
                                                          Assign
                                                        </button>
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              })()}
                                            </>
                                          ) : null}
                                        </span>
                                        <span>
                                          {selectedEmployees?.map(
                                            (employee, index) => (
                                              <ul
                                                className="list-group list-group-flush border-bottom"
                                                key={index}
                                              >
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                                  <div className="d-flex align-items-center py-1 col-lg-11">
                                                    <span className="col-lg-1">
                                                      <i className="bi bi-person-fill"></i>
                                                    </span>
                                                    <span className="col-lg-10">
                                                      {employee.firstName}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-1 form-check m-0 me-5">
                                                    <input
                                                      className="form-check-input green-border"
                                                      type="checkbox"
                                                      id={`flexCheckDefault-selected-${index}`}
                                                      checked={true} // All existing employees are initially checked
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </span>
                              <div></div>
                            </div>

                            <div className="tag-font">
                              {todo?.assigned_to?.length > 0 ? (
                                <span>
                                  Tagged Users {"  "}:{"  "}
                                </span>
                              ) : (
                                ""
                              )}
                              {todo?.assigned_to &&
                                todo?.assigned_to
                                  ?.map(
                                    (person) =>
                                      `${person?.first_name} ${person?.last_name}`
                                  )
                                  .join(", ")}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-3 col-md-3">
                      {todo?.due_date&&(<>
                                <div className="text-black post-time">
                                 due date{" "}
                                  {formatDateforDue(todo?.due_date)}
                                </div>
                              </>)}
                      </div>
                    </div>
                  </div>
                  <div>
                    {todo?.id === currentid &&
                      showMiniTodos[todo?.id] &&
                      todo?.mini_todos?.map((miniTodo, index) => {
                        return (
                          <div key={index + todo?.id} className="bottom-border">
                            {miniEdit && miniTodoId === miniTodo?.id ? (
                              <div className="col-8 d-flex align-items-center edit-form position-abs">
                                <textarea
                                  type="text"
                                  className="form-control shadow-none"
                                  value={inputValue} // Use inputValue here
                                  onChange={(e) =>
                                    handleInputChange(e.target.value)
                                  }
                                ></textarea>
                                {/* </div> */}
                                {/* <div className="col-2" > */}
                                <span className="d-flex my-3">
                                  <Tippy content="save">
                                    <button
                                      className="btn d-flex align-items-center justify-content-center mx-3"
                                      onClick={() =>
                                        handleMiniUpdate(
                                          miniTodo.post_id,
                                          miniTodo.parent_id,
                                          miniTodo?.id
                                        )
                                      }
                                    >
                                      <i className="bi bi-check-lg"></i>
                                    </button>
                                  </Tippy>
                                  <button
                                    className="btn btn-secondary d-flex align-items-center justify-content-center"
                                    onClick={handleCloseEdit}
                                  >
                                    <Tippy content="delete">
                                      <i className="bi bi-x"></i>
                                    </Tippy>
                                    
                                  </button>
                                </span>
                              </div>
                            ) : (
                              <>
                                <div className="container">
                                  <div
                                    className="row d-flex flex-column
                                       bottom-border position-abs"
                                  >
                                    <div className="col-lg-5 d-flex align-items-center pt-1  ">
                                      <img
                                        src={miniTodo.creator?.profile_image}
                                        alt=""
                                        width="20px"
                                        height="20px"
                                        className="rounded-circle img-fluid "
                                      />
                                      <span className="createrid fw-medium ps-2">
                                        {miniTodo.creator?.first_name}{" "}
                                        {miniTodo.creator?.last_name}{" "}
                                        {formatDate(
                                          miniTodo?.updated_at
                                        )}
                                      </span>
                                    </div>
                                    <div className="col-lg-5 d-flex ms-4 ps-3">
                                      {(miniTodo?.type_of === "task" ||
                                        miniTodo?.type_of === "text" ||
                                        miniTodo?.type_of === "image") && (
                                        <span className="ps-4 ms-2   todo-mini ">
                                          {miniTodo.comment}
                                        </span>
                                      )}
                                      {miniTodo?.type_of === "task_image" ||
                                      miniTodo?.type_of === "task_video" ||
                                      miniTodo?.type_of === "video" ||
                                      miniTodo?.type_of === "image" ||
                                      miniTodo?.type_of === "task_youtube" ? (
                                        <>
                                          {miniTodo.comment
                                            .split("|&|")
                                            ?.map((part, partIndex) => (
                                              <span key={partIndex}>
                                                {partIndex === 0 && (
                                                  // Display a static image or video thumbnail for the first part
                                                  <Popup
                                                    url={
                                                      miniTodo.comment.split(
                                                        "|&|"
                                                      )[0]
                                                    }
                                                    isVideo={
                                                      miniTodo?.type_of ===
                                                        "task_video" ||
                                                      miniTodo?.type_of ===
                                                        "video"
                                                    }
                                                    text={
                                                      miniTodo.comment.split(
                                                        "|&|"
                                                      )[1]
                                                    }
                                                  />
                                                )}
                                                {partIndex !== 0 && (
                                                  <span className="todo-mini px-2">
                                                    {part}
                                                  </span>
                                                )}
                                              </span>
                                            ))}
                                        </>
                                      ) : // Handle other types of content here
                                      null}
                                    </div>
                                    <div className="col-lg-2  d-flex ps-4 ms-5">
                                      <span
                                        className="pe-3"
                                        onClick={() =>
                                          handleMiniEdit(
                                            miniTodo?.id,
                                            miniTodo.comment
                                          )
                                        }
                                      >
                                        <Tippy content="edit">
                                          <img
                                            src="https://beta.yesdone.com/build/assets/pencil-bc3d8d52.png"
                                            alt="pencil"
                                            width="12px"
                                            height="12px"
                                            className="cursor-point"
                                          />
                                        </Tippy>
                                      </span>
                                      <Link
                                        to={`/posts/${id}/${miniTodo?.id}/todo-comments`}
                                        onClick={handleCommentClick}
                                      >
                                        <Tippy content="comments">
                                          {miniTodo?.replies_count > 0 ? (
                                            <img
                                              src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                              alt="Green Message"
                                              width="12px"
                                              height="12px"
                                              className="cursor-point green-icon"
                                            />
                                          ) : (
                                            <img
                                              src="https://beta.yesdone.com/build/assets/greymessage-7c7383fd.png"
                                              alt="emptypen"
                                              width="12px"
                                              height="12px"
                                              className="cursor-point"
                                            />
                                          )}
                                        </Tippy>
                                      </Link>
                                      <span
                                        className="ps-3 pe-1"
                                        onClick={() =>
                                          handleOpenModal1(
                                            miniTodo.post_id,
                                            miniTodo.parent_id,
                                            miniTodo?.id
                                          )
                                        }
                                      >
                                        <Tippy content="delete">
                                          <img
                                            src="https://beta.yesdone.com/build/assets/bin-15fd3a47.png"
                                            alt="delete"
                                            width="12px"
                                            height="12px"
                                            className="cursor-point"
                                          />
                                        </Tippy>
                                      </span>

                                      {isGroup && (
                                        <Tippy content="Task_Assign">
                                          <span className="button-container cursor-point pe-1">
                                            <FaUserPlus
                                              onClick={() =>
                                                handleTaskAssignButtonClick(
                                                  miniTodo?.id,
                                                  miniTodo?.assigned_to
                                                )
                                              }
                                              className="button-icon"
                                            />
                                          </span>
                                        </Tippy>
                                      )}
                                      <span
                                        className="ps-2 pe-1"
                                        onClick={() =>
                                          handleReply(miniTodo?.id)
                                        }
                                      >
                                        <Tippy content="reply">
                                          <i
                                            className="fa fa-reply small text-black"
                                            aria-hidden="true"
                                          ></i>
                                        </Tippy>
                                      </span>
                                      <span>
                                        {showGroupList &&
                                          selectedTodoId === miniTodo?.id && (
                                            <div className="modal copyMoveModal">
                                              <div className="modal-content modal-content1">
                                                <span className="d-flex justify-content-center">
                                                  {miniTodo?.type_of ===
                                                    "task" ||
                                                  miniTodo?.type_of ===
                                                    "text" ? (
                                                    <>
                                                      <div className="container ">
                                                        <div className="row">
                                                          <span className="col-10 d-flex justify-content-center align-items-center">
                                                            <h5 className="text-dark text-break fw-medium  ">
                                                              {miniTodo.comment}
                                                            </h5>
                                                          </span>
                                                          <span
                                                            className="close col-2 d-flex justify-content-center"
                                                            onClick={closeModal}
                                                          >
                                                            &times;
                                                          </span>
                                                        </div>
                                                        <div className="users-container pb-2">
                                                          <div
                                                            className="scrollableMain"
                                                            id="scrollableDiv"
                                                          >
                                                            <ul className="list-group list-group-flush">
                                                              {usersList.map(
                                                                (user) => (
                                                                  <li
                                                                    key={
                                                                      user.id
                                                                    }
                                                                    className="list-group-item list-users"
                                                                  >
                                                                    <div className="d-flex row">
                                                                      <p className="p-0 m-0 col-9 list-users-font">
                                                                        {user.first_name ||
                                                                          "KranthiAllaboina@gmail.com"}{" "}
                                                                        {user.last_name ||
                                                                          ""}
                                                                      </p>
                                                                      <span className="col-2">
                                                                        <input
                                                                          type="checkbox"
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleUsersSelect(
                                                                              e,
                                                                              user.id
                                                                            )
                                                                          }
                                                                          id={
                                                                            user.id
                                                                          }
                                                                          name="video_url"
                                                                          className="col-3 border-0 ms-4 p-0 custm-checkbox form-check-input shadow-none"
                                                                          checked={checkedCheckboxes.includes(
                                                                            user.id.toString()
                                                                          )}
                                                                        />
                                                                      </span>
                                                                    </div>
                                                                  </li>
                                                                )
                                                              )}
                                                            </ul>
                                                          </div>
                                                          <span className="d-flex justify-content-center">
                                                            <button
                                                              onClick={(e) =>
                                                                handleTaskAssign(
                                                                  e
                                                                )
                                                              }
                                                              className="butn-green1 pb-2"
                                                            >
                                                              Assign
                                                            </button>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : miniTodo?.type_of ===
                                                      "task_image" ||
                                                    miniTodo?.type_of ===
                                                      "task_video" ||
                                                    miniTodo?.type_of ===
                                                      "video" ||
                                                    miniTodo?.type_of ===
                                                      "image" ||
                                                    miniTodo?.type_of ===
                                                      "task_youtube" ? (
                                                    <>
                                                      {(() => {
                                                        const parts =
                                                          miniTodo.comment.split(
                                                            "|&|"
                                                          );
                                                        const isVideo =
                                                          miniTodo?.type_of ===
                                                          "task_video";
                                                        const [mediaUrl, text] =
                                                          parts?.length === 2
                                                            ? parts
                                                            : [
                                                                miniTodo.comment,
                                                                miniTodo.comment,
                                                              ];
                                                        // Here you can use mediaUrl and text as needed
                                                        return (
                                                          <div className="container">
                                                            <div className="row">
                                                              <span className="col-10 d-flex justify-content-center align-items-center">
                                                                <h5 className="text-dark text-break fw-medium ">
                                                                  {text}
                                                                </h5>
                                                              </span>
                                                              <span
                                                                className="close col-2 d-flex justify-content-center"
                                                                onClick={
                                                                  closeModal
                                                                }
                                                              >
                                                                &times;
                                                              </span>
                                                            </div>

                                                            <div className="users-container pb-2">
                                                              <div
                                                                className="scrollableMain"
                                                                id="scrollableDiv"
                                                              >
                                                                <ul className="list-group list-group-flush">
                                                                  {usersList.map(
                                                                    (user) => (
                                                                      <li
                                                                        key={
                                                                          user.id
                                                                        }
                                                                        className="list-group-item list-users"
                                                                      >
                                                                        <div className="d-flex row">
                                                                          <p className="p-0 m-0 col-9 list-users-font">
                                                                            {user.first_name ||
                                                                              "KranthiAllaboina@gmail.com"}{" "}
                                                                            {user.last_name ||
                                                                              ""}
                                                                          </p>
                                                                          <span className="col-2">
                                                                            <input
                                                                              type="checkbox"
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleUsersSelect(
                                                                                  e,
                                                                                  user.id
                                                                                )
                                                                              }
                                                                              id={
                                                                                user.id
                                                                              }
                                                                              name="video_url"
                                                                              className="col-3 border-0 ms-4 p-0 custm-checkbox form-check-input shadow-none"
                                                                              checked={checkedCheckboxes.includes(
                                                                                user.id.toString()
                                                                              )}
                                                                            />
                                                                          </span>
                                                                        </div>
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>
                                                              </div>
                                                              <span className="d-flex justify-content-center">
                                                                <button
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    handleTaskAssign(
                                                                      e
                                                                    )
                                                                  }
                                                                  className="butn-green1"
                                                                >
                                                                  Assign
                                                                </button>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      })()}
                                                    </>
                                                  ) : null}
                                                </span>
                                                <span>
                                                  {selectedEmployees?.map(
                                                    (employee, index) => (
                                                      <ul
                                                        className="list-group list-group-flush border-bottom"
                                                        key={index}
                                                      >
                                                        <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                                          <div className="d-flex align-items-center py-1 col-lg-11">
                                                            <span className="col-lg-1">
                                                              <i className="bi bi-person-fill"></i>
                                                            </span>
                                                            <span className="col-lg-10">
                                                              {
                                                                employee.firstName
                                                              }
                                                            </span>
                                                          </div>
                                                          <div className="col-lg-1 form-check m-0 me-5">
                                                            <input
                                                              className="form-check-input green-border"
                                                              type="checkbox"
                                                              id={`flexCheckDefault-selected-${index}`}
                                                              checked={true} // All existing employees are initially checked
                                                            />
                                                          </div>
                                                        </li>
                                                      </ul>
                                                    )
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                      </span>
                                    </div>
                                    <div className="todo-mini">
                                      {miniTodo?.assigned_to?.length > 0 ? (
                                        <span>
                                          Tagged Users {"  "}:{"  "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {miniTodo?.assigned_to &&
                                        miniTodo?.assigned_to
                                          ?.map(
                                            (person) =>
                                              `${person?.first_name} ${person?.last_name}`
                                          )
                                          .join(", ")}
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>

                                <div className="replies">
                                  {miniTodo?.mini_todos?.map(
                                    (reply, replyIndex) => {
                                      return (
                                        <div key={replyIndex}>
                                          <div className="d-flex align-items-center pt-1 position1-abs ">
                                            <img
                                              src={reply.creator?.profile_image}
                                              alt=""
                                              width="20px"
                                              height="20px"
                                              className="rounded-circle img-fluid "
                                            />
                                            <span className="createrid fw-medium ps-1">
                                              {reply.creator?.first_name}{" "}
                                              {reply.creator?.last_name}{" "}
                                              {formatDate(
                                                reply?.updated_at
                                              )}
                                            </span>
                                          </div>
                                          <span className="miniposition-abs ps-4 todo-mini ">
                                            {reply.comment}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                  {reply && miniTodoId === miniTodo?.id ? (
                                    <div className="col-8 position1-abs edit-form ">
                                      <form
                                        className="d-flex"
                                        action=""
                                        id="AddTask"
                                        onSubmit={(e) =>
                                          handleReplySubmit(
                                            e,
                                            todo.post_id,
                                            miniTodo?.id
                                          )
                                        }
                                      >
                                        <textarea
                                          type="text"
                                          className="form-control my-auto shadow-none"
                                          value={replyComment} // Use inputValue here
                                          onChange={(e) =>
                                            setReplyComment(e.target.value)
                                          }
                                        ></textarea>
                                        {/* </div> */}
                                        {/* <div className="col-2" > */}
                                        <span className="d-flex my-3">
                                          <Tippy content="save">
                                            <button
                                              className="btn d-flex align-items-center justify-content-center mx-3"
                                              onClick={() =>
                                                handleMiniUpdate(
                                                  miniTodo.post_id,
                                                  miniTodo.parent_id,
                                                  miniTodo?.id
                                                )
                                              }
                                            >
                                              <i className="bi bi-check-lg"></i>
                                            </button>
                                          </Tippy>
                                          <button
                                            className="btn btn-secondary d-flex align-items-center justify-content-center"
                                            onClick={handleCloseReply}
                                          >
                                            <Tippy content="delete">
                                              <i className="bi bi-x"></i>
                                            </Tippy>
                                          </button>
                                        </span>
                                      </form>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    <ToastContainer />
                  </div>

                  <div className="pt-1 ps-5 ">
                    {showAddTaskPart && showAddTaskPartId === todo?.id ? (
                      <>
                        <div className="addtaskpart py-0 ">
                          <form
                            action=""
                            id="AddTask"
                            onSubmit={(e) =>
                              handleMiniSubmit(e, todo.post_id, todo?.id)
                            }
                          >
                            <div className="taskborder pt-0 px-0 pb-1 d-flex align-items-center">
                              <input
                                type="text"
                                className="form-control add-input px-0 shadow-none border-0 "
                                autoComplete="off"
                                required
                                name="title"
                                placeholder="+   sub Task"
                              />
                            </div>
                            <div className="videpart">
                              <label className="custom-file-upload">
                                <input
                                  type="file"
                                  className="custom-file-input shadow-none"
                                  id="mediaFile"
                                  name="files"
                                  accept="image/*, video/*"
                                  onChange={handleFileChange}
                                />
                                <img
                                  src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                  className="cusor-pointer"
                                  alt=""
                                  width="22px"
                                  height="18px"
                                />
                              </label>
                              <input
                                type="text"
                                className="form-control add-input shadow-none border-0"
                                autoComplete="off"
                                name="video_url"
                                placeholder="Youtube Video"
                              />
                              <button type="submit" className="submitclass btn">
                                <img
                                  src="https://yesdone.com/build/assets/send-99cff475.png"
                                  className=""
                                  alt="send img"
                                  width="35px"
                                  height="35px"
                                />
                              </button>
                            </div>
                            {(thumbnail || file) && (
                              <ImagePreview
                                file={thumbnail || file}
                                onDelete={handleFileDelete}
                              />
                            )}
                          </form>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  <div className="container">
                    <div className="row bottom-border checkbox-ml ms-4 ">
                      <div className="col-sm-1 col-2 col-md-1 justify-content-between checkbox-pl d-flex ps-0">
                        <div className="text-black  fsize d-flex  justify-content-between">
                          {index + 1}.
                        </div>
                        <div className="checkbox-pl">
                          <Tippy content="checkbox">
                            <input
                              className="form-check-input custm-checkbox1  cursor-point  pe-1  shadow-none"
                              type="checkbox"
                              name="checkboxes"
                              checked={checkdoneId === todo?.id}
                              // onChange={() => handleDoneClick(todo?.id)}
                              onChange={() => handleClickDone(todo?.id)}
                            />
                          </Tippy>
                        </div>
                      </div>
                      <div className="col-sm-7 col-7 col-md-7 ps-0">
                        {editing && editId === todo?.id ? (
                          <div className="col-10 d-flex  ms-3 ">
                            <form
                              action=""
                              id="AddTask"
                              onSubmit={(e) =>
                                handleUpdate(e, todo?.id, todo?.type_of)
                              }
                            >
                              <div className="col-12  d-flex edit-form ">
                                <>
                                  <label className="custom-file-upload  pe-2 d-flex align-items-center pb-2">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="mediaFile"
                                      name="files"
                                      accept="image/*, video/*"
                                      onChange={handleFileChange}
                                    />
                                    <img
                                      src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                      className=""
                                      alt=""
                                      width="30px"
                                      height="25px"
                                    />
                                  </label>
                                  <span className="pt-2">
                                    <textarea
                                     ref={textareaRef}
                                      type="text"
                                      className="form-control shadow-none mobile_height"
                                      value={inputValue} // Use inputValue here
                                      onChange={(e) =>
                                        handleInputChange(e.target.value)
                                      }
                                      cols="100"
                                    ></textarea>
                                  </span>

                                  <span className="d-flex my-3">
                                    <button
                                      className="btn d-flex align-items-center justify-content-center mx-3"
                                      type="submit"
                                    >
                                      <Tippy content="save">
                                        <i className="bi bi-check-lg"></i>
                                      </Tippy>
                                    </button>
                                    <button
                                      className="btn btn-secondary d-flex align-items-center justify-content-center"
                                      onClick={handleCloseEdit}
                                    >
                                      <Tippy content="delete">
                                        <i className="bi bi-x"></i>
                                      </Tippy>
                                    </button>
                                  </span>

                                  {(thumbnail || file) && (
                                    <span className="ps-3">
                                      <ImagePreview
                                        file={thumbnail || file}
                                        onDelete={handleFileDelete}
                                      />
                                    </span>
                                  )}
                                </>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div className="">
                            {todo?.type_of === "task" ||
                            todo?.type_of === "text" ? (
                              <>
                                <span className="text-dark d-flex text-break fw-medium  posttime">
                                  <img
                                    className="hidden me-2 img-visible"
                                    src="https://yesdone.com/build/assets/dummyimg-68fadc9e.png"
                                    alt="Hidden Images"
                                    // style={{ visibility: "hidden" }}
                                    width="25px"
                                    height="19px"
                                  />
                                  <Link
                                    to={`/posts/${id}/${todo?.id}/todo-comments`}
                                    onClick={handleCommentClick}
                                  >
                                    {todo.comment}
                                  </Link>
                                </span>
                                <p className="post-time mb-0 text-black ps-1 update-position ">
                                by {" "}{todo?.creator?.name} on {formatDate(todo?.created_at)}
                                </p>
                              </>
                            ) : todo?.type_of === "task_image" ||
                              todo?.type_of === "task_video" ||
                              todo?.type_of === "task_youtube" ? (
                              <>
                                {(() => {
                                  const parts = todo.comment.split("|&|");
                                  const isVideo =
                                    todo?.type_of === "task_video";
                                  const [mediaUrl, text] =
                                    parts?.length === 2
                                      ? parts
                                      : [todo.comment, todo.comment];

                                  return (
                                    <>
                                      <div className="d-flex align-items-center text-dark fw-medium cursor-point ">
                                        <Popup
                                          url={mediaUrl}
                                          isVideo={isVideo}
                                          text={text}
                                        />
                                        <div className="posttime  text-break fw-medium mb-0 ps-1 text-black">
                                          <Link
                                            to={`/posts/${id}/${todo?.id}/todo-comments`}
                                            onClick={handleCommentClick}
                                          >
                                            {text}
                                          </Link>
                                        </div>
                                      </div>
                                      <p className="post-time mb-0 text-black ps-1 d-flex flex-column  update-position ">
                                      by {" "}{todo?.creator?.name} on {formatDate(todo?.created_at)}
                                      </p>
                                    </>
                                  );
                                })()}
                              </>
                            ) : null}
                            <div className="update-position">
                              <span
                                className=" pe-2 cursor-point"
                                onClick={() =>
                                  handleOpenEdit(
                                    todo?.id,
                                    todo.comment,
                                    todo?.type_of
                                  )
                                }
                              >
                                <Tippy content="edit">
                                  <img
                                    src="https://yesdone.com/build/assets/pencil-bc3d8d52.png"
                                    alt=""
                                    width="12px"
                                    height="12px"
                                    className="pt-0 cursor-point"
                                  />
                                </Tippy>
                               
                              </span>
                              <span
                                className="cursor-point ps-2 pe-2"
                                onClick={() => handleAddMiniTask(todo?.id)}
                              >
                                <Tippy content="add sub-task">
                                  <img
                                    src="https://yesdone.com/build/assets/greyplus-2f2d8fa2.png"
                                    alt="greyplus"
                                    width="12px"
                                    height="12px"
                                    className="pt-0 cursor-point"
                                  />
                                </Tippy>
                              </span>
                              <Link
                                to={`/posts/${id}/${todo?.id}/todo-comments`}
                                onClick={handleCommentClick}
                              >
                                <span className="px-2">
                                  <Tippy content="minicomments">
                                    {todo?.mini_todos?.length > 0 ? (
                                      <img
                                        src="https://yesdone.com/build/assets/greenmessage-27adb83e.png"
                                        alt="Green Message"
                                        width="14px"
                                        height="14px"
                                        className="cursor-point"
                                      />
                                    ) : (
                                      <img
                                        src="https://beta.yesdone.com/build/assets/greymessage-7c7383fd.png"
                                        alt="emptypen"
                                        width="14px"
                                        height="14px"
                                        className="pt-0 cursor-point"
                                      />
                                    )}
                                  </Tippy>
                                </span>
                              </Link>
                              <span key={todo?.id}>
                                <Tippy content="copy">
                                  <span className="button-container cursor-point pe-2">
                                    <BiCopy
                                      onClick={() =>
                                        handleCopyButtonClick(todo?.id)
                                      }
                                      className="button-icon"
                                    />
                                  </span>
                                </Tippy>
                              </span>
                              <Tippy content="Move">
                                <span className="button-container cursor-point pe-1">
                                  <FaFileExport
                                    onClick={() =>
                                      handleMoveButtonClick(todo?.id)
                                    }
                                    className="button-icon"
                                  />
                                </span>
                              </Tippy>
                              
                              {isGroup && (
                                <Tippy content="Task_Assign">
                                  <span className="button-container cursor-point pe-1">
                                    <FaUserPlus
                                      onClick={() =>
                                        handleTaskAssignButtonClick(
                                          todo?.id,
                                          todo?.assigned_to
                                        )
                                      }
                                      className="button-icon"
                                    />
                                  </span>
                                </Tippy>
                              )}
                              <span
                                className="ps-2 pe-2"
                                onClick={() => handleOpenModal(todo?.id)}
                              >
                                <Tippy content="delete">
                                  <img
                                    src="https://yesdone.com/build/assets/bin-15fd3a47.png"
                                    alt="bin"
                                    width="14px"
                                    height="14px"
                                    className="pt-0 cursor-point"
                                  />
                                </Tippy>
                              </span>
                              <span
                                onClick={() =>
                                  handleBookmarkModal(
                                    todo?.id,
                                    todo?.is_bookmarked
                                  )
                                }
                                className="ps-1"
                              >
                                <Tippy
                                  content={
                                    todo?.is_bookmarked
                                      ? "Bookmark Checked"
                                      : "Bookmark"
                                  }
                                >
                                  {todo?.is_bookmarked ? (
                                    <img
                                      src="https://yesdone.com/build/assets/pen-3b90bcd1.png"
                                      alt="emptypen"
                                      width="11px"
                                      height="15px"
                                      className="pt-0 cursor-point"
                                    />
                                  ) : (
                                    <img
                                      src="https://yesdone.com/build/assets/emptypen-94551c55.png"
                                      alt="emptypen"
                                      width="11px"
                                      height="15px"
                                      className="pt-0 cursor-point"
                                    />
                                  )}
                                </Tippy>
                              </span>
                              <span>
                                {showSelect && selectedTodoId === todo?.id && (
                                  <div className="modal copyMoveModal">
                                    <div className="modal-content modal-content1">
                                      <span className="d-flex">
                                        {todo?.type_of === "task" ||
                                        todo?.type_of === "text" ? (
                                          <>
                                            <div className="container">
                                              <div className="row pb-4">
                                                <div className="col-1"></div>
                                                <div className="col-10 d-flex justify-content-center align-items-center">
                                                  <h1 className="text-dark d-flex  text-break mb-0  fw-medium posttime pb-2">
                                                    {todo.comment}
                                                  </h1>
                                                </div>

                                                <div
                                                  className="close col-1  px-0 pb-2 "
                                                  onClick={closeModal}
                                                >
                                                  &times;
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : todo?.type_of === "task_image" ||
                                          todo?.type_of === "task_video" ||
                                          todo?.type_of === "task_youtube" ? (
                                          <>
                                            {(() => {
                                              const parts =
                                                todo.comment.split("|&|");
                                              const isVideo =
                                                todo?.type_of === "task_video";
                                              const [mediaUrl, text] =
                                                parts?.length === 2
                                                  ? parts
                                                  : [
                                                      todo.comment,
                                                      todo.comment,
                                                    ];
                                              // Here you can use mediaUrl and text as needed
                                              return (
                                                <>
                                                  <div className="container">
                                                    <div className="row pb-4">
                                                      <div className="col-1"></div>
                                                      <div className="col-10 d-flex justify-content-center align-items-center">
                                                        <h1 className="text-dark d-flex  text-break mb-0  fw-medium posttime pb-2">
                                                          {text}
                                                        </h1>
                                                      </div>

                                                      <div
                                                        className="close col-1  px-0 pb-2 "
                                                        onClick={closeModal}
                                                      >
                                                        &times;
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {selectedEmployees?.length >
                                                    0 && (
                                                    <div>
                                                      <h2>Employees</h2>
                                                      <ul>
                                                        {employees?.map(
                                                          (employee) => (
                                                            <li
                                                              key={employee?.id}
                                                            >
                                                              {employee?.name}
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })()}
                                          </>
                                        ) : null}
                                      </span>
                                      <span className="d-flex justify-content-center pb-3">
                                        <select
                                          onChange={handleChangesSelect}
                                          className="selectbox"
                                        >
                                          <option value="" disabled selected>
                                            Select Group Or User
                                          </option>
                                          {filterData?.map((item) => (
                                            <option
                                              key={item?.id}
                                              value={JSON.stringify(item)}
                                            >
                                              {item?.name}
                                            </option>
                                          ))}
                                        </select>
                                      </span>
                                      <div className="d-flex justify-content-center pb-3">
                                        {showSecondSelect &&
                                          selectedTodoId === todo?.id && (
                                            <>
                                              <select
                                                onChange={handleChangesSelect1}
                                                className="selectbox1"
                                              >
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  Select Post
                                                </option>
                                                {todoLoader ? (
                                                  <p>loading...</p>
                                                ) : (
                                                  <>
                                                    {todoRecords?.map(
                                                      (item) => (
                                                        <option
                                                          key={item?.id}
                                                          value={JSON.stringify(
                                                            item
                                                          )}
                                                        >
                                                          {item.notes?.length >
                                                          35
                                                            ? `${item.notes.substring(
                                                                0,
                                                                35
                                                              )}...`
                                                            : item.notes}
                                                        </option>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </select>
                                            </>
                                          )}
                                      </div>
                                      <div className="d-flex justify-content-center ps-2">
                                        {showCheckbox &&
                                          selectedTodoId === todo?.id && (
                                            <>
                                              <button
                                                className="btn btn-primary"
                                                onClick={(event) => {
                                                  // Check the condition and execute the corresponding function
                                                  handleCheckboxChange(
                                                    event,
                                                    todo.comment,
                                                    todo?.type_of,
                                                    todo?.id
                                                  );
                                                }}
                                                disabled={!copyMove} // Disable button if copyMove is empty
                                              >
                                                {copyMove
                                                  ? copyMove
                                                  : "Select Action"}{" "}
                                                {/* Button label */}
                                              </button>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </span>
                              <span>
                                {showGroupList &&
                                  selectedTodoId === todo?.id && (
                                    <div className="modal copyMoveModal">
                                      <div className="modal-content modal-content1">
                                        <span className="d-flex justify-content-center">
                                          {todo?.type_of === "task" ||
                                          todo?.type_of === "text" ? (
                                            <>
                                              <div className="container ">
                                                <div className="row">
                                                  <span className="col-10 d-flex justify-content-center align-items-center">
                                                    <h5 className="text-dark text-break fw-medium  ">
                                                      {todo.comment}
                                                    </h5>
                                                  </span>
                                                  <span
                                                    className="close col-2 d-flex justify-content-center"
                                                    onClick={closeModal}
                                                  >
                                                    &times;
                                                  </span>
                                                </div>
                                                <div className="users-container pb-2">
                                                  <div
                                                    className="scrollableMain"
                                                    id="scrollableDiv"
                                                  >
                                                    <ul className="list-group list-group-flush">
                                                      {usersList.map((user) => (
                                                        <li
                                                          key={user.id}
                                                          className="list-group-item list-users"
                                                        >
                                                          <div className="d-flex row">
                                                            <p className="p-0 m-0 col-9 list-users-font">
                                                              {user.first_name ||
                                                                "KranthiAllaboina@gmail.com"}{" "}
                                                              {user.last_name ||
                                                                ""}
                                                            </p>
                                                            <span className="col-2">
                                                              <input
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                  handleUsersSelect(
                                                                    e,
                                                                    user.id
                                                                  )
                                                                }
                                                                id={user.id}
                                                                name="video_url"
                                                                className="col-3 border-0 ms-4 p-0 custm-checkbox form-check-input shadow-none"
                                                                checked={checkedCheckboxes.includes(
                                                                  user.id.toString()
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </li>
                                                      ))}
                                                    </ul>
                                                  </div>
                                                  <span className="d-flex justify-content-center">
                                                    <button
                                                      onClick={(e) =>
                                                        handleTaskAssign(e)
                                                      }
                                                      className="butn-green1 pb-2"
                                                    >
                                                      Assign
                                                    </button>
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ) : todo?.type_of === "task_image" ||
                                            todo?.type_of === "task_video" ||
                                            todo?.type_of === "task_youtube" ? (
                                            <>
                                              {(() => {
                                                const parts =
                                                  todo.comment.split("|&|");
                                                const isVideo =
                                                  todo?.type_of ===
                                                  "task_video";
                                                const [mediaUrl, text] =
                                                  parts?.length === 2
                                                    ? parts
                                                    : [
                                                        todo.comment,
                                                        todo.comment,
                                                      ];
                                                // Here you can use mediaUrl and text as needed
                                                return (
                                                  <div className="container">
                                                    <div className="row">
                                                      <span className="col-10 d-flex justify-content-center align-items-center">
                                                        <h5 className="text-dark text-break fw-medium ">
                                                          {text}
                                                        </h5>
                                                      </span>
                                                      <span
                                                        className="close col-2 d-flex justify-content-center"
                                                        onClick={closeModal}
                                                      >
                                                        &times;
                                                      </span>
                                                    </div>

                                                    <div className="users-container  pb-2">
                                                      <div
                                                        className="scrollableMain"
                                                        id="scrollableDiv"
                                                      >
                                                        <ul className="list-group list-group-flush">
                                                          {usersList.map(
                                                            (user) => (
                                                              <li
                                                                key={user.id}
                                                                className="list-group-item list-users"
                                                              >
                                                                <div className="d-flex row">
                                                                  <p className="p-0 m-0 col-9 list-users-font">
                                                                    {user.first_name ||
                                                                      "KranthiAllaboina@gmail.com"}{" "}
                                                                    {user.last_name ||
                                                                      ""}
                                                                  </p>
                                                                  <span className="col-2">
                                                                    <input
                                                                      type="checkbox"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleUsersSelect(
                                                                          e,
                                                                          user.id
                                                                        )
                                                                      }
                                                                      id={
                                                                        user.id
                                                                      }
                                                                      name="video_url"
                                                                      className="col-3 border-0 ms-4 p-0 custm-checkbox form-check-input shadow-none"
                                                                      checked={checkedCheckboxes.includes(
                                                                        user.id.toString()
                                                                      )}
                                                                    />
                                                                  </span>
                                                                </div>
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                      <span className="d-flex justify-content-center">
                                                        <button
                                                          onClick={(e) =>
                                                            handleTaskAssign(e)
                                                          }
                                                          className="butn-green1"
                                                        >
                                                          Assign
                                                        </button>
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              })()}
                                            </>
                                          ) : null}
                                        </span>
                                        <span>
                                          {selectedEmployees?.map(
                                            (employee, index) => (
                                              <ul
                                                className="list-group list-group-flush border-bottom"
                                                key={index}
                                              >
                                                <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                                                  <div className="d-flex align-items-center py-1 col-lg-11">
                                                    <span className="col-lg-1">
                                                      <i className="bi bi-person-fill"></i>
                                                    </span>
                                                    <span className="col-lg-10">
                                                      {employee.firstName}
                                                    </span>
                                                  </div>
                                                  <div className="col-lg-1 form-check m-0 me-5">
                                                    <input
                                                      className="form-check-input green-border"
                                                      type="checkbox"
                                                      id={`flexCheckDefault-selected-${index}`}
                                                      checked={true} // All existing employees are initially checked
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                              </span>
                           
                            </div>

                            <div className="tag-font">
                              {todo?.assigned_to?.length > 0 ? (
                                <span>
                                  Tagged Users {"  "}:{"  "}
                                </span>
                              ) : (
                                ""
                              )}
                              {todo?.assigned_to &&
                                todo?.assigned_to
                                  ?.map(
                                    (person) =>
                                      `${person?.first_name} ${person?.last_name}`
                                  )
                                  .join(", ")}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-3 col-md-3">
                      {todo?.due_date&&(<>
                                <div className="text-black post-time">
                                 due date{" "}
                                  {formatDateforDue(todo?.due_date)}
                                </div>
                              </>)}
                      </div>
                    </div>
                  </div>
                  <div className="pt-1">
                    {showAddTaskPart && showAddTaskPartId === todo?.id ? (
                      <>
                        <div className="addtaskpart py-0 ">
                          <form
                            action=""
                            id="AddTask"
                            onSubmit={(e) =>
                              handleMiniSubmit(e, todo.post_id, todo?.id)
                            }
                          >
                            <div
                              className="taskborder pt-0 px-0 pb-1 d-flex align-items-center"
                              // style={{
                              //   display: "flex",
                              //   alignItems: "center",
                              // }}
                            >
                              <input
                                type="text"
                                className="form-control add-input px-0  border-0 shadow-none"
                                autoComplete="off"
                                required
                                name="title"
                                placeholder="+   sub Task"
                              />
                            </div>
                            <div className="videpart">
                              <label className="custom-file-upload">
                                <input
                                  type="file"
                                  className="custom-file-input shadow-none"
                                  id="mediaFile"
                                  name="files"
                                  accept="image/*, video/*"
                                  onChange={handleFileChange}
                                />
                                <img
                                  src="https://yesdone.com/build/assets/dummyicon-4334881c.png"
                                  className="pt-0 cursor-point"
                                  alt=""
                                  width="22px"
                                  height="18px"
                                />
                              </label>
                              <input
                                type="text"
                                className="form-control add-input border-0 shadow-none"
                                autoComplete="off"
                                name="video_url"
                                placeholder="Youtube Video"
                              />
                              <button type="submit" className="submitclass btn">
                                <img
                                  src="https://yesdone.com/build/assets/send-99cff475.png"
                                  className="pt-0 cursor-point"
                                  alt="send img"
                                  width="35px"
                                  height="35px"
                                />
                              </button>
                            </div>
                            {(thumbnail || file) && (
                              <ImagePreview
                                file={thumbnail || file}
                                onDelete={handleFileDelete}
                              />
                            )}
                          </form>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
          <ToastContainer ref={toastRef} timeout={4000} />

          {loading ? (
            <p className="text-center text-muted">
              <img src={LoadingImg} width="35px" height="35px" />
            </p>
          ) : null}
        </div>
      </InfiniteScroll>
      <Modal
        show={isModalOpen}
        onHide={handleCloseModal1}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want delete this ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleDeleteConfirmation}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseModal1}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDoneModal}
        onHide={handleCloseDoneModal}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center  text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleDoneClick}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseDoneModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showBookMarkModal}
        onHide={handleCloseBookMarkModal}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center  text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want change status ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleBookmark}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseBookMarkModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="d-flex align-items-center justify-content-center"
      >
        <Modal.Header
          //  closeButton
          className="fs-1 fw-bold text-center border-0 d-flex justify-content-center"
        >
          <i
            className="bi bi-exclamation-circle text-center text-danger modal-icon"
            // style={{
            //   fontSize: "85px",
            //   color: "#f8bb86 !important",
            // }}
          ></i>
        </Modal.Header>
        <Modal.Body className="">
          Are you sure you want delete this ?
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center border-0">
          <Button variant="primary" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MiniTodo;
