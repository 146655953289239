import React, { useState, useEffect, useContext } from "react";
import { useAuth } from "../AuthContext/AuthContext";
import { fetchFilterData } from "../../Api/UsersList";
import { useTodo } from "../AuthContext/TodoContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { genNewSearchParamString } from "../../utils/index";
import { useFilterData } from "../AuthContext/SidebarContext";
import StaticSidebar from "./StaticSidebar";
import UserContext from "../AuthContext/ChatContext";
import { useLocation } from "react-router-dom";
import { useTodoData } from "../AuthContext/TodoDataContext";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SideBar.css";
import "../../App.css";
import API_BASE_URL from "../../Config";
import axios from "axios";

const SideBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isTodoCommentsPage = currentPath.includes("/todo-comments");
  const { filterData, setFilterData, loading, setLoading } = useFilterData();
  const { setGroupName } = useContext(UserContext);
  const { todoData, setTodoData } = useTodoData();
  const [error, setError] = useState(null);
  const [previousItemId, setPreviousItemId] = useState(null);
  const selectedId = localStorage.getItem("selectedId");
  const firstId = localStorage.getItem("firstId");
  const userId = Number(localStorage.getItem("user_id")) || null;
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { setSelectedItem, setFirstTodoName, setFirstId, setFirstType } =
    useTodo();
  const [openAccordion, setOpenAccordion] = useState(null);
  const [editingAccordion, setEditingAccordion] = useState(null);
  const [newAccordionName, setNewAccordionName] = useState("");
  const [editingAccordianCountry, setEditingAccordianCountry] = useState(null);

  const handleEditClick = (name, country) => {
    setEditingAccordion(name);
    setNewAccordionName(name);
    setEditingAccordianCountry(country);
    console.log(("county,name", country));
    // Set the current name in the input box
  };

  const handleCloseEdit = () => {
    setEditingAccordion();
  }

  const handleSubmit = async (event, oldName) => {
    event.preventDefault();
    const token = localStorage.getItem("token"); // Get the token from local storage

    // Prepare the request body
    const requestBody = {
      country_code: editingAccordianCountry, // Use the state variable for the country code
      name: newAccordionName, // The new name for the section
      // Optionally include user_ids if you have them available
      // user_ids: [51583, 51584], // Uncomment and populate this as needed
    };

    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/section-update`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the authorization header
          },
        }
      );

      // Axios automatically handles response data
      console.log(`Updated ${oldName} to ${newAccordionName}`, response.data); // Log the response
      setEditingAccordion(null); // Close the edit mode
      window.location.reload(); // Reload the page to reflect changes
      // Optionally, you can perform additional state updates or notifications based on the response data
    } catch (error) {
      if (error.response && error.response.data.name) {
        toast.error(
          "The name has already been taken. Please choose another name."
        ); // Show the toast message
      } else {
        toast.error(
          "The name has already been taken. Please choose another name."
        ); // Log any errors
      }
    }
  };

  // Group users by section_name, assigning null/missing section users to "US"
  const groupUsersByCountryAndSection = (users) => {
    return users.reduce((acc, user) => {
      const section = user.section_name || "US"; // Default to "US" if section_name is null or missing
      const countryName = user.country; // Get the country from user

      // Exclude users with department IDs 3, 1, or 15
      if (user.department && [3, 1, 15].includes(user.department.id)) {
        return acc; // Skip this user if they belong to the excluded departments
      }

      // Initialize the country group if it doesn't exist
      if (!acc[countryName]) {
        acc[countryName] = { sections: {} };
      }

      // Initialize the section within the country group if it doesn't exist
      if (!acc[countryName].sections[section]) {
        acc[countryName].sections[section] = [];
      }

      // Push user to the appropriate country and section
      acc[countryName].sections[section].push(user);
      return acc;
    }, {});
  };

  const filteredUsers =
    filterData?.filter((user) => user.type_of === "user") || [];
  const groupedUsers = groupUsersByCountryAndSection(filteredUsers);

  const handleAccordionToggle = (sectionName) => {
    setOpenAccordion((prev) => (prev === sectionName ? null : sectionName));
  };

  useEffect(() => {
    const fetchAndSetFilterData = async () => {
      try {
        const fetchedData = await fetchFilterData(setFilterData, setLoading);
        const storedData = JSON.parse(localStorage.getItem("filterData"));
        if (JSON.stringify(fetchedData) !== JSON.stringify(storedData)) {
          setFilterData(fetchedData || []);
          localStorage.setItem("filterData", JSON.stringify(fetchedData));
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchAndSetFilterData();
  }, [setFilterData, setLoading]);

  const shouldApplyMarginTop = () => {
    const pattern = /^\/posts\/[^/]+\/[^/]+\/todo-comments$/;
    return pattern.test(location.pathname);
  };

  const sanitizeId = (name) => {
    return name
      .toLowerCase() // Convert to lowercase
      .replace(/[\s-]+/g, "_") // Replace spaces and hyphens with underscores
      .replace(/[^\w_]+/g, ""); // Remove special characters except underscores
  };

  // Define valid department IDs
  const validDepartmentIds = [1, 3, 15];

  // Group users by department, only for valid department IDs
  const getUsersByDepartment = (data, departmentId) =>
    data
      ?.filter(
        (user) =>
          user?.department?.id === departmentId && user.type_of === "user"
      )
      ?.sort((a, b) => a.name.localeCompare(b.name));

  const getDepartmentId = (departmentUsers) =>
    departmentUsers[0]?.department?.id || null;

  // Create the departments array based on valid department IDs and their associated users
  const departments = validDepartmentIds
    .map((id) => {
      const departmentUsers = getUsersByDepartment(filterData, id);

      if (departmentUsers.length > 0) {
        const departmentName =
          departmentUsers[0]?.department?.alias_name || null;
        const departmentId = getDepartmentId(departmentUsers); // Use the utility function

        console.log("Department Name:", departmentName, "ID:", departmentId); // Debugging

        return {
          departmentName,
          departmentId,
          users: departmentUsers,
          sanitizedId: departmentName ? sanitizeId(departmentName) : null,
        };
      } else {
        return null; // Return null when no users are found for the department
      }
    })
    .filter((dept) => dept !== null); // Filter out null departments

  // Sort the array alphabetically by department name
  const sortedDepartments = departments.sort((a, b) =>
    a.departmentName.localeCompare(b.departmentName)
  );

  const Accordion = ({
    departmentName,
    departmentId,
    users,
    openAccordion,
    handleAccordionToggle,
    loading,
  }) => {
    const sanitizedId = sanitizeId(departmentName); // Sanitize the department alias_name here
    const [editingDepartment, setEditingDepartment] = useState(null);
    const [newDepartmentName, setNewDepartmentName] = useState("");
    const [editingId, setEditingId] = useState(null);

    // Update this function to correctly set the editing state
    const handleDepartmentEditClick = (id, name) => {
      setEditingDepartment(name);
      setEditingId(id); // This should now correctly hold the department ID
      setNewDepartmentName(name);
      console.log("Editing Department:", name, "ID:", id); // Debugging
    };

    const handleCloseDepartment = () =>{
      setEditingDepartment();
    }
    const handleDepartmentSubmit = async (event, oldName) => {
      event.preventDefault();

      const token = localStorage.getItem("token"); // Get the token from local storage

      const requestBody = {
        department_id: editingId, // Use the state variable for the department ID
        name: newDepartmentName, // The new name for the section
      };

      try {
        const response = await axios.put(
          `${API_BASE_URL}/api/v1/section-update`,
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the authorization header
            },
          }
        );

        // Axios automatically handles response data
        console.log(
          `Updated ${oldName} to ${newDepartmentName}`,
          response.data
        ); // Log the response

        setEditingDepartment(null); // Close the edit mode
        setEditingId(null); // Reset the editing ID

        window.location.reload(); // Reload the page to reflect changes
      } catch (error) {
        if (error.response && error.response.data.name) {
          toast.error(
            "The name has already been taken. Please choose another name."
          ); // Show the toast message
        } else {
          toast.error(
            "The name has already been taken. Please choose another name."
          ); // Log any errors
        }
      }
    };

    return (
      <>
        <div className="accordion-item mb-3 mt-0">
          <h2
            className="accordion-header"
            id={`flush-heading${sanitizedId}Mobile`}
          >
            <button
              className={`accordion-button custom-acrdn-btn collapsed pt-1 pb-0 ps-0 custom-accordian ${
                openAccordion === departmentName ? "show" : ""
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse${sanitizedId}Mobile`}
              aria-expanded={openAccordion === departmentName}
              aria-controls={`flush-collapse${sanitizedId}Mobile`}
              onClick={() => handleAccordionToggle(departmentName)}
            >
              <span className="ms-2 text-blue ps-2">{departmentName}</span>
              <i
                className="bi bi-pencil-fill text-secondary ms-2"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent toggling the accordion
                  handleDepartmentEditClick(departmentId, departmentName); // Pass the correct ID and name
                }}
              ></i>
            </button>
            {editingDepartment === departmentName ? (
              <div className="d-flex gap-2">
                <input
                  type="text"
                  value={newDepartmentName}
                  onChange={(e) => {
                    setNewDepartmentName(e.target.value);
                    console.log("Current input value:", e.target.value);
                  }}
                  className="form-control me-2"
                  required
                />
                <div className="country-edit-icon d-flex align-items-center">
                <button
                  className="btn btn-success rounded-1 d-flex align-items-center justify-content-center"
                  onClick={(e) => handleDepartmentSubmit(e, departmentName)}
                >
                  <i className="bi bi-check-lg fs-6"></i>
                </button>
                </div>
                 <div className="country-edit-icon d-flex align-items-center">
                 <button
                  className="btn btn-success rounded-1 d-flex align-items-center justify-content-center"
                  onClick={(e) => handleCloseDepartment (e)}
                >
                  <i className="bi bi-x "></i>
                </button>
                           </div>
              </div>
            ) : null}
          </h2>
          <div
            id={`flush-collapse${sanitizedId}Mobile`}
            className={`accordion-collapse collapse ${
              openAccordion === departmentName ? "show" : ""
            }`}
            aria-labelledby={`flush-heading${sanitizedId}Mobile`}
            data-bs-parent="#accordionFlushMobile"
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center my-2">
                <StaticSidebar />
              </div>
            ) : users?.length > 0 ? (
              <div className="accordion-body custom-body">
                <ul className="list-inline override ps-3 mb-0 pb-2">
                  {users.map((item) => (
                    <li
                    key={`${item.id}${item.name}`}
                    className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                    onClick={() => {
                      localStorage.setItem(
                        "selectedId",
                        item.id
                      );
                      localStorage.setItem(
                        "selectedCountry",
                        item.country
                      );
                      setGroupName(null);
                      setFirstTodoName(null);
                      setSelectedItem(
                        item.name,
                        item.id,
                        item.type_of
                      );
                      navigate(
                        "/todo-list" +
                          genNewSearchParamString(
                            searchParams,
                            "user_id",
                            item.id
                          )
                      );
                    }}
                  >
                    <div className="text-success custom-fontsize">
                      {Number(item?.id) === userId ? (
                        <i className="bi bi-brightness-high-fill text-success pe-2 mt-3"></i>
                      ) : (
                        <i className="fa fa-user pe-2"></i>
                      )}
                    </div>
                    <div className="text-blue ps-2">
                      <span>{item.name}</span>
                      <span>-</span>
                      <span>{item.count}</span>
                    </div>
                  </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="accordion-body custom-body">
                <p>No {departmentName} department employees found.</p>
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </>
    );
  };

  useEffect(() => {
    const filteredTodos =
      filterData?.filter((item) => Number(item?.id) === userId) || [];
    const sortedTodos =
      filteredTodos?.slice().sort((a, b) => {
        if (a.type_of === "user" && b.type_of === "group") {
          return -1;
        } else if (a.type_of === "group" && b.type_of === "user") {
          return 1;
        } else {
          return a.name?.localeCompare(b.name);
        }
      }) || [];

    if (sortedTodos?.length > 0) {
      const firstTodoName = sortedTodos[0].name;
      setFirstTodoName(firstTodoName);
      const firstId = sortedTodos[0].id;
      localStorage.setItem("firstId", firstId);
      const firstType = sortedTodos[0].type_of;
      localStorage.setItem("firstType", firstType);
      const fullName = sortedTodos[0].name;

      localStorage.setItem("fullName", fullName);
      const selectedItemId = localStorage.getItem("selectedItemId");
      const selectedType = localStorage.getItem("selectedType");
      if (!selectedItemId || !selectedType) {
        navigate(
          genNewSearchParamString(
            searchParams,
            firstType === "user" ? "user_id" : "group_id",
            firstId
          )
        );
      }
    }
  }, [filterData, setFirstTodoName, navigate, searchParams, userId]);

  const groups = filterData?.filter((item) => item?.type_of === "group") || [];

  // Sort groups alphabetically
  const sortedGroups = groups.sort((a, b) => a.name?.localeCompare(b.name));

  const userCountry =
    Object.keys(groupedUsers).find((country) =>
      Object.values(groupedUsers[country]?.sections).some((section) =>
        section.some((user) => user?.id === userId)
      )
    ) || "United States"; // Default to "United States" if not found

  // Get the sorted countries
  const sortedCountries = [
    userCountry,
    ...Object.keys(groupedUsers)
      ?.filter((country) => country !== userCountry)
      .sort((a, b) => a?.localeCompare(b)),
  ];

  // Render the accordion for each country and its sections
  useEffect(() => {
    if (userCountry) {
      setOpenAccordion(userCountry);
    }
  }, [userCountry]);

  const combinedList = [
    ...sortedCountries.map((country) => ({ name: country, type: "country" })),
    ...sortedDepartments.map((dept) => ({
      name: dept.departmentName,
      id: dept.departmentId, // Ensure correct ID is passed
      type: "department",
    })),
  ];

  const sortedCombinedList = combinedList.sort((a, b) => {
    const regex = /^(\d+)-/;
  
    const aMatch = a.name.match(regex);
    const bMatch = b.name.match(regex);
  
    if (aMatch && bMatch) {
      return parseInt(aMatch[1]) - parseInt(bMatch[1]);
    }
  
    if (aMatch) {
      return -1; // a comes first
    }
  
    if (bMatch) {
      return 1; // b comes first
    }
  
    return a.name.localeCompare(b.name);
  });
  

  useEffect(() => {
    if (userCountry) {
      setOpenAccordion(userCountry);
    }
  }, [userCountry]);

  return (
    <div
      className={`container-fluid scrollable2 ${
        isTodoCommentsPage ? "col-lg-2" : ""
      } ${isTodoCommentsPage ? "fixedPosition" : ""} ${
        shouldApplyMarginTop() ? "with-margin" : ""
      }`}
    >
      {isLoggedIn && (
        <div className="sidebar">
          <div className="accordion" id="accordionFlushMobile">
            {sortedCombinedList.map((item) => {
              if (item.type === "country") {
                const sections = groupedUsers[item.name]?.sections;

                // Safeguard: Check if sections is an object
                if (!sections || typeof sections !== "object") {
                  return null;
                }

                return Object.keys(sections).map((sectionName) => {
                  const users = sections[sectionName];

                  // Check if users is an array
                  if (!Array.isArray(users)) {
                    console.error(
                      `Expected users to be an array for section: ${sectionName}`,
                      users
                    );
                    return null;
                  }

                  // Optional: Sort users if needed
                  const sortedUsers = users.sort((a, b) =>
                    a.id === userId
                      ? -1
                      : b.id === userId
                      ? 1
                      : a.name?.localeCompare(b.name)
                  );

                  return (
                    <div
                      className="accordion-item mb-3 mt-0"
                      key={`${item.name}-${sectionName}`}
                    >
                      <h2
                        className="accordion-header"
                        id={`flush-heading${sectionName}Mobile`}
                      >
                        <button
                          className={`accordion-button custom-acrdn-btn collapsed pt-1 pb-0 ps-0 custom-accordian ${
                            openAccordion === sectionName ? "show" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${sectionName}Mobile`}
                          aria-expanded={openAccordion === sectionName}
                          aria-controls={`flush-collapse${sectionName}Mobile`}
                          onClick={() => handleAccordionToggle(sectionName)}
                        >
                          <span className="ms-2 text-blue ps-2">
                            {sectionName}
                          </span>
                          <i
                            className="bi bi-pencil-fill text-secondary ms-2"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent toggling the accordion
                              handleEditClick(sectionName, item.name); // Pass country name
                            }}
                          ></i>
                        </button>
                        {editingAccordion === sectionName ? (
                          <div className="d-flex gap-2 ">
                            <input
                              type="text"
                              value={newAccordionName}
                              onChange={(e) =>
                                setNewAccordionName(e.target.value)
                              }
                              className="form-control"
                              required
                            />
                            <div className="country-edit-icon  d-flex align-items-center">
                            <button
                              className="btn btn-success rounded-1 d-flex align-items-center justify-content-center"
                              onClick={(e) => handleSubmit(e, sectionName)}
                            >
                              <i className="bi bi-check-lg "></i>
                            </button>
                            </div>
                           <div className="country-edit-icon d-flex align-items-center">
                           <button
                  className="btn btn-success rounded-1 d-flex align-items-center justify-content-center"
                  onClick={(e) => handleCloseEdit(e)}
                >
                  <i className="bi bi-x "></i>
                </button>
                           </div>
                           
                          </div>
                        ) : null}
                      </h2>
                      <div
                        id={`flush-collapse${sectionName}Mobile`}
                        className={`accordion-collapse collapse ${
                          openAccordion === sectionName ? "show" : ""
                        }`}
                        aria-labelledby={`flush-heading${sectionName}Mobile`}
                        data-bs-parent="#accordionFlushMobile"
                      >
                        {loading ? (
                          <div className="d-flex align-items-center justify-content-center my-2">
                            <StaticSidebar />
                          </div>
                        ) : (
                          <div className="accordion-body custom-body">
                            <ul className="list-inline override ps-3 mb-0 pb-2">
                              {sortedUsers
                                .filter((user) => user.status === "active")
                                .map((user) => (
                                  <li
                                    key={`${user.id}${user.name}`}
                                    className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "selectedId",
                                        user.id
                                      );
                                      localStorage.setItem(
                                        "selectedCountry",
                                        user.country
                                      );
                                      setGroupName(null);
                                      setFirstTodoName(null);
                                      setSelectedItem(
                                        user.name,
                                        user.id,
                                        user.type_of
                                      );
                                      navigate(
                                        "/todo-list" +
                                          genNewSearchParamString(
                                            searchParams,
                                            "user_id",
                                            user.id
                                          )
                                      );
                                    }}
                                  >
                                    <div className="text-success custom-fontsize">
                                      {Number(user?.id) === userId ? (
                                        <i className="bi bi-brightness-high-fill text-success pe-2 mt-3"></i>
                                      ) : (
                                        <i className="fa fa-user pe-2"></i>
                                      )}
                                    </div>
                                    <div className="text-blue ps-2">
                                      <span>{user.name}</span>
                                      <span>-</span>
                                      <span>{user.count}</span>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                });
              } else if (item.type === "department") {
                const departmentUsers =
                  sortedDepartments.find(
                    (dept) => dept.departmentName === item.name
                  )?.users || [];
                const departmentId = item.id; // Safeguard for missing IDs
                return (
                  <>
                    <Accordion
                      key={item.id}
                      departmentName={item.name}
                      departmentId={departmentId} // Pass the ID here
                      users={departmentUsers}
                      openAccordion={openAccordion}
                      handleAccordionToggle={handleAccordionToggle}
                      loading={loading}
                    />
                  </>
                );
              }
              return null; // In case neither type matches
            })}
            <div className="accordion-item mb-3 mt-0">
              <h2 className="accordion-header" id="flush-headingGroupsMobile">
                <button
                  className={`accordion-button custom-acrdn-btn collapsed pt-1 pb-0 ps-0 custom-accordian ${
                    openAccordion === "Groups" ? "show" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseGroupsMobile"
                  aria-expanded={openAccordion === "Groups"}
                  aria-controls="flush-collapseGroupsMobile"
                  onClick={() => handleAccordionToggle("Groups")}
                >
                  <span className="ms-2 text-blue ps-2">Groups</span>
                </button>
              </h2>
              <div
                id="flush-collapseGroupsMobile"
                className={`accordion-collapse collapse ${
                  openAccordion === "Groups" ? "show" : ""
                }`}
                aria-labelledby="flush-headingGroupsMobile"
                data-bs-parent="#accordionFlushMobile"
              >
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center my-2">
                    <StaticSidebar />
                  </div>
                ) : (
                  <div className="accordion-body custom-body">
                    <ul className="list-inline override ps-3 mb-0 pb-2">
                      {sortedGroups.map((item) => (
                        <li
                          key={`${item.id}${item.name}`}
                          className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                          onClick={() => {
                            localStorage.setItem("selectedId", item.id);
                            setGroupName(null);
                            setFirstTodoName(null);
                            setSelectedItem(item.name, item.id, item.type_of);
                            if (
                              (firstId &&
                                selectedId &&
                                firstId === selectedId) ||
                              (!selectedId && firstId) ||
                              (selectedId && selectedId !== item.id)
                            ) {
                              // Do nothing
                            } else {
                              setTodoData({
                                todoCount: 0,
                                approvedCount: 0,
                                deleteCount: 0,
                                posts: [],
                              });
                            }
                            navigate(
                              "/todo-list" +
                                genNewSearchParamString(
                                  searchParams,
                                  "group_id",
                                  item.id
                                )
                            );
                          }}
                        >
                          <div className="text-success custom-fontsize">
                            <i className="fa fa-users pe-2"></i>
                          </div>
                          <div className="text-blue ps-2">
                            <span>{item.name}</span>
                            <span>-</span>
                            <span>{item.count}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SideBar;
