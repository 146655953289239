const initialState = {
  conversations: [],
  selectedConversation: null,
  selectedUserId: null,
  selectedUserType: null,
  showConversations: [],
  loading: false,
  pageNo:1,
  hasMore:true,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONVERSATIONS":
      return { ...state, conversations: action.payload };
    case "SET_SELECTED_CONVERSATION":
      return { ...state, selectedConversation: action.payload };
    case "SET_SELECTED_USERID":
      return { ...state, selectedUserId: action.payload };
    case "SET_SELECTED_USERTYPE":
      return { ...state, selectedUserType: action.payload };
    case "RESET_SHOW_CONVERSATIONS":
      return {
        ...state,
        showConversations: [],
      };
    case "SET_SHOW_CONVERSATIONS":
      return {
        ...state,
        showConversations: [ ...state.showConversations, ...action.payload],
      };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
      case "SET_PAGENO":
        return { ...state, pageNo: action.payload };
        case "SET_HASMORE":
          return { ...state, hasMore: action.payload };
    default:
      return state;
  }
};

export default rootReducer;

// const initialState = {
//   conversations: [],
//   selectedConversation: null,
//   selectedUserId: null,
//   selectedUserType: null,
//   showConversations:null,
//   loading:false
// };

// const rootReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "SET_CONVERSATIONS":
//       return { ...state, conversations: action.payload };
//     case "SET_SELECTED_CONVERSATION":
//       return { ...state, selectedConversation: action.payload };
//     case "SET_SELECTED_USERID":
//       return { ...state, selectedUserId: action.payload };
//     case "SET_SELECTED_USERTYPE":
//       return { ...state, selectedUserType: action.payload };
//       case "SET_SHOW_CONVERSATIONS":
//       return { ...state, showConversations: action.payload };
//       case "SET_LOADING":
//       return { ...state, loading: action.payload };
//     default:
//       return state;
//   }
// };

// export default rootReducer;