// message.js
import API_BASE_URL from '../Config';

// Function to fetch data from the API using the token
async function fetchData() {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/messages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    console.log("Data has been fetched: ", data);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
}

export { fetchData };
