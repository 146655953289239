import React, { useState, useEffect, useContext } from "react";
import { useAuth } from "../AuthContext/AuthContext";
import { fetchFilterData } from "../../Api/UsersList";
import { useTodo } from "../AuthContext/TodoContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { genNewSearchParamString } from "../../utils/index";
import { useFilterData } from "../AuthContext/SidebarContext";
import StaticSidebar from "./StaticSidebar";
import UserContext from "../AuthContext/ChatContext";
import { useLocation } from "react-router-dom";
import { useTodoData } from "../AuthContext/TodoDataContext";
import "./SideBar.css";
import "../../App.css";

// Country code to name mapping
const countryCodeToName = {
  us: "United States",
  in: "India",
  gb: "United Kingdom",
  ca: "Canada",
  cn: "China",
  au: "Australia",
  // Add other countries as needed
};

// Normalize country codes to country names
const normalizeCountry = (country) => {
  if (!country) return "United States";
  const normalized = country.trim().toLowerCase();
  return countryCodeToName[normalized] || normalized.toUpperCase(); // Default to upper case if not found
};

// Sort users alphabetically by their name
const sortUsersAlphabetically = (users) => {
  return users.sort((a, b) => a.name.localeCompare(b.name));
};

// Group users by country and sort countries and users
const sortCountriesAndUsers = (users, loggedInUserCountry) => {
  const groupedUsers = users.reduce((acc, user) => {
    const country = normalizeCountry(user.country);
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push(user);
    return acc;
  }, {});

  // Sort users within each country alphabetically
  for (const country in groupedUsers) {
    groupedUsers[country] = sortUsersAlphabetically(groupedUsers[country]);
  }

  // Create a sorted array of countries
  const sortedCountries = Object.keys(groupedUsers).sort();

  // Move logged-in user's country to the top if it exists
  if (loggedInUserCountry && sortedCountries.includes(loggedInUserCountry)) {
    sortedCountries.splice(sortedCountries.indexOf(loggedInUserCountry), 1);
    sortedCountries.unshift(loggedInUserCountry);
  }

  // Create a new object with sorted countries and users
  const sortedGroupedUsers = {};
  sortedCountries.forEach((country) => {
    sortedGroupedUsers[country] = groupedUsers[country];
  });

  return sortedGroupedUsers;
};
// Function to check if the URL matches the pattern

const SideBar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isTodoCommentsPage = currentPath.includes("/todo-comments");
  const { filterData, setFilterData, loading, setLoading } = useFilterData();
  const { setGroupName } = useContext(UserContext);
  const { todoData, setTodoData } = useTodoData();
  const [error, setError] = useState(null);
  const [previousItemId, setPreviousItemId] = useState(null);
  const selectedId = localStorage.getItem("selectedId");
  const firstId = localStorage.getItem("firstId");
  const userId = Number(localStorage.getItem("user_id")) || null;
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { setSelectedItem, setFirstTodoName, setFirstId, setFirstType } =
    useTodo();
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    const fetchAndSetFilterData = async () => {
      try {
        const fetchedData = await fetchFilterData(setFilterData, setLoading);
        const storedData = JSON.parse(localStorage.getItem("filterData"));
        if (JSON.stringify(fetchedData) !== JSON.stringify(storedData)) {
          setFilterData(fetchedData || []);
          localStorage.setItem("filterData", JSON.stringify(fetchedData));
        }
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchAndSetFilterData();
  }, [setFilterData, setLoading]);

  const shouldApplyMarginTop = () => {
    const pattern = /^\/posts\/[^/]+\/[^/]+\/todo-comments$/;
    return pattern.test(location.pathname);
  };

  const salesUsers = filterData
    ?.filter((user) => user?.department?.id === 1 && user.type_of === "user")
    ?.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const filteredTodos =
      filterData?.filter((item) => Number(item?.id) === userId) || [];
    const sortedTodos =
      filteredTodos?.slice().sort((a, b) => {
        if (a.type_of === "user" && b.type_of === "group") {
          return -1;
        } else if (a.type_of === "group" && b.type_of === "user") {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      }) || [];

    if (sortedTodos?.length > 0) {
      const firstTodoName = sortedTodos[0].name;
      setFirstTodoName(firstTodoName);
      const firstId = sortedTodos[0].id;
      localStorage.setItem("firstId", firstId);
      const firstType = sortedTodos[0].type_of;
      localStorage.setItem("firstType", firstType);
      const fullName = sortedTodos[0].name;

      localStorage.setItem("fullName", fullName);
      const selectedItemId = localStorage.getItem("selectedItemId");
      const selectedType = localStorage.getItem("selectedType");
      if (!selectedItemId || !selectedType) {
        navigate(
          genNewSearchParamString(
            searchParams,
            firstType === "user" ? "user_id" : "group_id",
            firstId
          )
        );
      }
    }
  }, [filterData, setFirstTodoName, navigate, searchParams, userId]);

  const groupedUsers = sortCountriesAndUsers(
    filterData?.filter((item) => item?.type_of === "user") || [],
    normalizeCountry(localStorage.getItem("user_country"))
  );

  const groups = filterData?.filter((item) => item?.type_of === "group") || [];

  // Sort groups alphabetically
  const sortedGroups = groups.sort((a, b) => a.name.localeCompare(b.name));
  const userCountry =
    Object.keys(groupedUsers)?.find((country) =>
      groupedUsers[country]?.some((user) => user?.id === userId)
    ) || "United States";

  const sortedCountries = [
    userCountry,
    ...Object.keys(groupedUsers)?.filter((country) => country !== userCountry),
  ];

  useEffect(() => {
    if (userCountry) {
      setOpenAccordion(userCountry);
    }
  }, [userCountry]);

  const handleAccordionToggle = (itemId) => {
    setOpenAccordion((prev) => (prev === itemId ? null : itemId));
  };

  return (
    <div
      className={`container-fluid scrollable2 ${
        isTodoCommentsPage ? "col-lg-2" : ""
      } ${isTodoCommentsPage ? "fixedPosition" : ""} ${
        shouldApplyMarginTop() ? "with-margin" : ""
      }`}
    >
      {isLoggedIn && (
        <div className="sidebar">
          <div className="accordion" id="accordionFlushMobile">
            {sortedCountries?.map((country) => {
              const users = groupedUsers[country] || [];
              const sortedUsers = users.sort((a, b) =>
                a.id === userId
                  ? -1
                  : b.id === userId
                  ? 1
                  : a.name.localeCompare(b.name)
              );

              return (
                <>
                  <div className="accordion-item mb-3 mt-0" key={country}>
                    <h2
                      className="accordion-header"
                      id={`flush-heading${country}Mobile`}
                    >
                      <button
                        className={`accordion-button custom-acrdn-btn collapsed pt-1 pb-0 ps-0 custom-accordian ${
                          openAccordion === country ? "show" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${country}Mobile`}
                        aria-expanded={openAccordion === country}
                        aria-controls={`flush-collapse${country}Mobile`}
                        onClick={() => handleAccordionToggle(country)}
                      >
                        <span className="ms-2 text-blue ps-2">{country}</span>
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${country}Mobile`}
                      className={`accordion-collapse collapse ${
                        openAccordion === country ? "show" : ""
                      }`}
                      aria-labelledby={`flush-heading${country}Mobile`}
                      data-bs-parent="#accordionFlushMobile"
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center my-2">
                          <StaticSidebar />
                        </div>
                      ) : (
                        <div className="accordion-body custom-body">
                          <ul className="list-inline override ps-3 mb-0 pb-2">
                            {sortedUsers
                              .filter((item) => item.status === "active") // Filter users based on status
                              .map((item) => (
                                <li
                                  key={`${item.id}${item.name}`}
                                  className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                                  onClick={() => {
                                    // Store both user ID and country in localStorage
                                    localStorage.setItem("selectedId", item.id);
                                    localStorage.setItem(
                                      "selectedCountry",
                                      item.country
                                    ); // Store the country name

                                    setGroupName(null);
                                    setFirstTodoName(null);
                                    setSelectedItem(
                                      item.name,
                                      item.id,
                                      item.type_of
                                    );

                                    if (
                                      (firstId &&
                                        selectedId &&
                                        firstId === selectedId) ||
                                      (!selectedId && firstId) ||
                                      (selectedId && selectedId !== item.id)
                                    ) {
                                      // Do nothing
                                    } else {
                                      setTodoData({
                                        todoCount: 0,
                                        approvedCount: 0,
                                        deleteCount: 0,
                                        posts: [],
                                      });
                                    }

                                    navigate(
                                      "/todo-list" +
                                        genNewSearchParamString(
                                          searchParams,
                                          "user_id",
                                          item.id
                                        )
                                    );
                                  }}
                                >
                                  <div className="text-success custom-fontsize">
                                    {Number(item?.id) === userId ? (
                                      <i className="bi bi-brightness-high-fill text-success pe-2 mt-3"></i>
                                    ) : (
                                      <i className="fa fa-user pe-2"></i>
                                    )}
                                  </div>
                                  <div className="text-blue ps-2">
                                    <span>{item.name}</span>
                                    <span>-</span>
                                    <span>{item.count}</span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            <div className="accordion-item mb-3 mt-0">
              <h2 className="accordion-header" id="flush-headingSalesMobile">
                <button
                  className={`accordion-button custom-acrdn-btn collapsed pt-1 pb-0 ps-0 custom-accordian ${
                    openAccordion === "Sales" ? "show" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSalesMobile"
                  aria-expanded={openAccordion === "Sales"}
                  aria-controls="flush-collapseSalesMobile"
                  onClick={() => handleAccordionToggle("Sales")}
                >
                  <span className="ms-2 text-blue ps-2">Sales</span>
                </button>
              </h2>
              <div
                id="flush-collapseSalesMobile"
                className={`accordion-collapse collapse ${
                  openAccordion === "Sales" ? "show" : ""
                }`}
                aria-labelledby="flush-headingSalesMobile"
                data-bs-parent="#accordionFlushMobile"
              >
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center my-2">
                    <StaticSidebar />
                  </div>
                ) : salesUsers?.length > 0 ? (
                  <div className="accordion-body custom-body">
                    <ul className="list-inline override ps-3 mb-0 pb-2">
                      {salesUsers.map((item) => (
                        <li
                          key={`${item.id}${item.name}`}
                          className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                          onClick={() => {
                            // Store both user ID and country in localStorage
                            localStorage.setItem("selectedId", item.id);
                            localStorage.setItem(
                              "selectedCountry",
                              item.country
                            ); // Store the country name

                            setGroupName(null);
                            setFirstTodoName(null);
                            setSelectedItem(item.name, item.id, item.type_of);

                            if (
                              (firstId &&
                                selectedId &&
                                firstId === selectedId) ||
                              (!selectedId && firstId) ||
                              (selectedId && selectedId !== item.id)
                            ) {
                              // Do nothing
                            } else {
                              setTodoData({
                                todoCount: 0,
                                approvedCount: 0,
                                deleteCount: 0,
                                posts: [],
                              });
                            }

                            navigate(
                              "/todo-list" +
                                genNewSearchParamString(
                                  searchParams,
                                  "user_id",
                                  item.id
                                )
                            );
                          }}
                        >
                          <div className="text-success custom-fontsize">
                            {Number(item?.id) === userId ? (
                              <i className="bi bi-brightness-high-fill text-success pe-2 mt-3"></i>
                            ) : (
                              <i className="fa fa-user pe-2"></i>
                            )}
                          </div>
                          <div className="text-blue ps-2">
                            <span>{item.name}</span>
                            <span>-</span>
                            <span>{item.count}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="accordion-body custom-body">
                    <p>No Sales department employees found.</p>
                  </div>
                )}
              </div>
            </div>
            <div className="accordion-item mb-3 mt-0">
              <h2 className="accordion-header" id="flush-headingGroupsMobile">
                <button
                  className={`accordion-button custom-acrdn-btn collapsed pt-1 pb-0 ps-0 custom-accordian ${
                    openAccordion === "Groups" ? "show" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseGroupsMobile"
                  aria-expanded={openAccordion === "Groups"}
                  aria-controls="flush-collapseGroupsMobile"
                  onClick={() => handleAccordionToggle("Groups")}
                >
                  <span className="ms-2 text-blue ps-2">Groups</span>
                </button>
              </h2>
              <div
                id="flush-collapseGroupsMobile"
                className={`accordion-collapse collapse ${
                  openAccordion === "Groups" ? "show" : ""
                }`}
                aria-labelledby="flush-headingGroupsMobile"
                data-bs-parent="#accordionFlushMobile"
              >
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center my-2">
                    <StaticSidebar />
                  </div>
                ) : (
                  <div className="accordion-body custom-body">
                    <ul className="list-inline override ps-3 mb-0 pb-2">
                      {sortedGroups.map((item) => (
                        <li
                          key={`${item.id}${item.name}`}
                          className="px-2 li-cursor d-flex align-items-center py-1 cursor-pointer"
                          onClick={() => {
                            localStorage.setItem("selectedId", item.id);
                            setGroupName(null);
                            setFirstTodoName(null);
                            setSelectedItem(item.name, item.id, item.type_of);
                            if (
                              (firstId &&
                                selectedId &&
                                firstId === selectedId) ||
                              (!selectedId && firstId) ||
                              (selectedId && selectedId !== item.id)
                            ) {
                              // Do nothing
                            } else {
                              setTodoData({
                                todoCount: 0,
                                approvedCount: 0,
                                deleteCount: 0,
                                posts: [],
                              });
                            }
                            navigate(
                              "/todo-list" +
                                genNewSearchParamString(
                                  searchParams,
                                  "group_id",
                                  item.id
                                )
                            );
                          }}
                        >
                          <div className="text-success custom-fontsize">
                            <i className="fa fa-users pe-2"></i>
                          </div>
                          <div className="text-blue ps-2">
                            <span>{item.name}</span>
                            <span>-</span>
                            <span>{item.count}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBar;
